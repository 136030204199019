export default {
  address: 'Address',
  amount: 'Amount',
  applicationType: 'Application Type',
  arrangement: 'Arrangement',
  creditLimit: 'Credit limit',
  dateNotified: 'Date Notified',
  dateOfBirth: 'Date Of Birth',
  defaultDate: 'Defaulted Date',
  endDate: 'End Date',
  entryNumber: 'Entry Number',
  lastUpdated: 'Last Updated',
  lengthAtAddress: 'Length at Address',
  loanedTo: 'Loaned To',
  name: 'Name',
  nocRef: 'NOC Ref',
  opened: 'Opened',
  paymentTerms: 'Payment Terms',
  settlementDate: 'Settlement Date',
  startDate: 'Start Date',
  term: 'Term',
};
