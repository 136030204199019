import React, { Fragment } from 'react';
import styled from 'styled-components';
import printf from 'printf';
import { Link, setAutomationElement, Typography } from '@experian-uk/corvetteuk-common-ui';
import alertActions from './alertActions';
import alertRisks from './alertRisks';
import alertsCapsContent from '../components/alertsCapsContent';
import { EnvConsumer } from '../components/Context/env';
import descriptionKeys from './alertsDescriptionKeys';
import indefinite from '../helpers/indefinite';
import { formatCurrency, formatName } from '../helpers/formatters';

const contactUs = contactUsConfig => (
  <EnvConsumer>
    {
      context => (
        <li key={contactUsConfig.key || 'contact-us'}>
          {contactUsConfig.text} <Link.Anchor href={`${context.REACT_APP_INS_URL}/contact`} inline {...setAutomationElement(contactUsConfig.automation || 'link-to-contact-page2')}>{contactUsConfig.linkLabel || 'contact us'}</Link.Anchor>.
        </li>
      )
    }
  </EnvConsumer>
);

const webMonitoringContactUsConfig = {
  text: 'If you are concerned about this Web Monitoring alert, or have any questions, please use our ',
  linkLabel: 'contact page',
};

const genericContactUsConfig = {
  text: 'If you are concerned and you need help, please ',
  linkLabel: 'contact us',
};

const orderedListAction = (orderedListContent = [], contactUsConfig, noLinksToIns) => (
  <ol>
    {orderedListContent.filter(item => item).map(listItem => <li key={listItem}>{listItem}</li>)}
    {(contactUsConfig && !noLinksToIns) && contactUs(contactUsConfig)}
  </ol>
);

const titledOrderedListAction = (actionConfig = {}, contactUsConfig, noLinksToIns) => (
  <Fragment key={actionConfig.title}>
    <Typography.Copy.Text>{actionConfig.title}</Typography.Copy.Text>
    {orderedListAction(actionConfig.orderedListContent, actionConfig.contactUsConfig || contactUsConfig, actionConfig.noLinksToIns || noLinksToIns)}
  </Fragment>
);

const defaultWhere = ({ whereFound }) => whereFound;

const AddressLine = styled(Typography.Copy.Text)`
  margin: 0;
`;

const experianReport = isFraud => `Experian ${isFraud ? 'Fraud' : 'Credit'} Report`;

const styledAddress = (address = '') => address.split(',').map(line => (
  <AddressLine key={line}>{line}</AddressLine>
));

const StrongText = styled(Typography.Copy.Text)`
  font-weight: 500;
`;

const appTypeQuotation = ['CREDIT CARD QUOTATION', 'INSURANCE QUOTATION', 'PERSONAL LOAN QUOTATION', 'MORTGAGE QUOTATION', 'COMMUNICATIONS OR MEDIA SERVICES QUOTATION', 'STORE CARD QUOTATION', 'TELCO QUOTATION', 'UTILITY QUOTATION', 'MULTIPLE PRODUCT QUOTATION',
  'Quotation – Credit Card Quotation', 'Quotation – Insurance Quotation', 'Quotation – Mortgage Quotation', 'Quotation – Multiple Product Quotation', 'Quotation – Storecard Quotation', 'Quotation – Telco Quotation', 'Quotation – Unsecured Personal Loan Quotation', 'Quotation – Utility Quotation'];
const appTypeIdentification = ['IDENTIFICATION: AGE AUTHENTICATION', 'IDENTIFICATION: GENERIC FINANCIAL', 'IDENTIFICATION: GENERIC CHECK', 'IDENTIFICATION: MONEY LAUNDERING PREVENTION', 'IDENTIFICATION: TRANSACTION',
  'Identification – Generic Check', 'Identification – Generic Financial', 'Identification – Prevention of Money Launderi'];

const capsIntroKey = ({ search: { applicationType, searchedBy, searchedOn } }) => {
  if ((appTypeQuotation.includes(applicationType) || appTypeIdentification.includes(applicationType))) {
    return printf('A new %s search by %s was made on your Experian Credit Report on %s.', applicationType, searchedBy, searchedOn);
  }
  return (
    printf('%s search was carried out on your Experian Credit Report on %s by %s. Please check your report to make sure you recognise the ENQUIRY/APPLICATION and don’t need to take any action.', indefinite(applicationType, true), searchedOn, searchedBy)
  );
};

const capsAction = (alert, isFraud, benefits, noLinksToIns) => {
  const { search: { applicationType } } = alert;

  if (!appTypeQuotation.includes(applicationType) && !appTypeIdentification.includes(applicationType)) {
    return orderedListAction(
      alertActions[descriptionKeys.caps](alert, benefits, noLinksToIns),
      genericContactUsConfig,
      noLinksToIns,
    );
  } return null;
};

export const introKey = 'intro';

export default {
  alertsDescriptionKeys: descriptionKeys,
  categories: {
    urgent: 'urgent',
  },
  content: {
    [descriptionKeys.activated_account]: {
      details: {
        [introKey]: ({ account: { company, type } }) => printf('Your dormant %s account with %s has been reported as active. This could indicate fraudulent use if you have not used the account.', type, company),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.payment_history_or_account_status_change](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'A dormant account has been reported as active',
        text: ({ account: { company, type } }) => printf('Your dormant %s account with %s has been reported as active.', type, company),
      },
    },
    [descriptionKeys.cais]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`%s has added or removed a %s account from your ${experianReport(isFraud)}. Please check your report to make sure you recognise the account change and don’t need to take any action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        dateUpdatedOnReport: ({ detailUpdated }) => detailUpdated,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.cais](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'You have opened or closed an account',
        emphasis: ['account', 'added or removed', 'Experian Credit Report', 'Experian Fraud Report'],
        text: ({ account: { company, type } }, isFraud) => printf(`%s has added or removed a %s account from your ${experianReport(isFraud)}.`, company, type),
      },
    },
    [descriptionKeys.cais_added]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`%s has added a %s account to your ${experianReport(isFraud)}. Please check your report to make sure you recognise the account change and don’t need to take any action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        dateAddedOnReport: ({ detailUpdated }) => detailUpdated,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.cais_added](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'You have opened an account',
        emphasis: ['account', 'added', 'Experian Credit Report', 'Experian Fraud Report'],
        text: ({ account: { company, type } }, isFraud) => printf(`%s has added a %s account to your ${experianReport(isFraud)}.`, company, type),
      },
    },
    [descriptionKeys.cais_removed]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`%s has removed a %s account from your ${experianReport(isFraud)}. Please check your report to make sure you recognise the account change and don’t need to take any action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        dateRemovedFromReport: ({ detailUpdated }) => detailUpdated,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.cais_removed](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'You have closed an account',
        emphasis: ['account', 'removed', 'Experian Credit Report', 'Experian Fraud Report'],
        text: ({ account: { company, type } }, isFraud) => printf(`%s has removed a %s account from your ${experianReport(isFraud)}.`, company, type),
      },
    },
    [descriptionKeys.caps]: {
      details: {
        [introKey]: capsIntroKey,
        content: ({ search: { applicationType } }) => alertsCapsContent(applicationType, appTypeQuotation, appTypeIdentification),
        personalDetails: <StrongText>Personal details relating to the search</StrongText>,
        nameGeneric: ({ nameOnAlert }) => nameOnAlert,
        addressGeneric: ({ address }) => styledAddress(address),
        action: capsAction,
      },
      headline: {
        detail: () => 'There has been a search recorded on your Experian Report',
        emphasis: ['account', 'added or removed', 'Experian Credit Report'],
        text: ({ search: { applicationType, searchedBy, searchedOn } }) => printf('%s search was carried out on your Experian Credit Report on %s by %s.', indefinite(applicationType, true), searchedOn, searchedBy),
      },
    },
    [descriptionKeys.ccno_asp]: {
      details: {
        what: ({ value: { ccno = '' } }) => printf('Your credit card number: %s', ccno),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.dataTheft,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.ccno_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['credit card details'],
        text: () => 'Your credit card details have been found publicly available online.',
      },
    },
    [descriptionKeys.defaulted_account]: {
      details: {
        [introKey]: ({ account: { company, type } }) => printf('Your %s %s account has been reported as defaulted. Please check your report to make sure you recognise the account change. There is further information on Defaults in the FAQs.', company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.defaulted_account](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'An account has been reported as defaulted',
        text: ({ account: { company, type } }) => printf('%s has reported your %s account as defaulted.', company, type),
      },
    },
    [descriptionKeys.driversl_asp]: {
      details: {
        what: ({ value: { driversl = '' } }) => printf('Your driving licence number: %s', driversl),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud(),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.driversl_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['driving licence number'],
        text: () => 'Your driving licence number has been found publicly available online.',
      },
    },
    [descriptionKeys.email_asp]: {
      details: {
        emailPasswordWhat: ({ value: { email = '' } }) => printf('Your email address %s', email),
        emailPassword: ({ value: { credentials = '' } }) => printf('%s', credentials),
      },
      headline: {
        text: () => 'Your personal details are being published and sold online.',
      },
    },
    [descriptionKeys.email_soc]: {
      details: {
        what: ({ value: { email = '' } }) => printf('Your email %s', email),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud('email address'),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.email_soc, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['email address'],
        text: () => 'Your email address has been found available online on a social media website.',
      },
    },
    [descriptionKeys.emailxpostcode_ins]: {
      details: {
        what: ({ value: { email = '' } }) => printf('Your email %s with your postcode', email),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud('postcode'),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.emailxpostcode_ins, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['email address', 'postcode'],
        text: () => 'Your email address and postcode have been found publicly available together online.',
      },
    },
    [descriptionKeys.missed_payment]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`A single missed payment has been reported by %s on your %s account. You may need to take action, if you haven’t already, as it could negatively impact your credit score. Please check on your ${experianReport(isFraud)} to make sure you recognise it and determine if you need to take any action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.two_missed_payments](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'A change in one of your accounts’ payment history',
        text: ({ account: { company, type } }) => printf('%s has reported a missed payment on your %s account.', company, type),
      },
    },
    [descriptionKeys.namexdob_bmd]: {
      details: {
        what: ({ value }) => printf('The name %s with your date of birth and mother’s maiden name', formatName(value)),
        maidenName: ({ whereFound }) => whereFound,
        why: () => alertRisks.gro,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.namexdob_bmd, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['name', 'date of birth', 'mother’s maiden name'],
        text: () => 'Your name, date of birth and your mother’s maiden name have been found on the online General Register Office database.',
      },
    },
    [descriptionKeys.namexpostcode_er]: {
      details: {
        what: ({ value }) => printf('The name %s with your address and postcode', formatName(value)),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.editedElectoralRoll,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.namexpostcode_er, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['name', 'full postal address'],
        text: () => 'Your name and full postal address have been found publicly available on the Edited Electoral Register.',
      },
    },
    [descriptionKeys.namexpostcodexdob_ch]: {
      details: {
        what: ({ value }) => printf('The name %s with your address, postcode and date of birth in association with a company registered at Companies House', formatName(value)),
        companyName: ({ whereFound }) => whereFound,
        why: () => alertRisks.companiesHouse,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.namexpostcodexdob_ch, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['name', 'address', 'date of birth'],
        text: () => 'Your name, address and date of birth have been found together on the Companies House Register.',
      },
    },
    [descriptionKeys.passportxsurname_asp]: {
      details: {
        what: ({ value: { passport = '' } }) => printf('The passport number: %s', passport),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.blackMarketPassport,
        action: () => alertActions.passportxsurname_asp.map(actionConfig => titledOrderedListAction(actionConfig)),
      },
      headline: {
        emphasis: ['passport number'],
        text: () => 'Your passport number is being illegally published and sold online.',
      },
    },
    [descriptionKeys.payment_history_or_account_status_change]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`There’s been a change in the payment history of your %s %s account. Please check your ${experianReport(isFraud)} to make sure you recognise it and don’t need to take any action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.payment_history_or_account_status_change](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'A change in one of your accounts’ payment history',
        text: ({ account: { company, type } }) => printf('%s has reported a change in the payment history or status of your %s account.', company, type),
      },
    },
    [descriptionKeys.payments_made_on_account]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`%s has reported payments on your %s account, which was in arrears. This may mean you are clearing, or have cleared them. Please check on your ${experianReport(isFraud)} to make sure you recognise it and determine if you need to take any further action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.payments_made_on_account](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'A change in one of your accounts’ payment history',
        text: ({ account: { company, type } }) => printf('%s has reported payments made to your %s account.', company, type),
      },
    },
    [descriptionKeys.phone_asp]: {
      details: {
        what: ({ value: { phone = '' } }) => printf('The phone number: %s', phone),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.blackMarketPhone,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.phone_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['phone number'],
        text: () => 'Your phone number is being illegally published and sold online.',
      },
    },
    [descriptionKeys.phone_soc]: {
      details: {
        what: ({ value: { phone = '' } }) => printf('The phone number: %s', phone),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud('phone number'),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.phone_soc, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['phone number'],
        text: () => 'Your phone number has been found available online on a social media website.',
      },
    },
    [descriptionKeys.phonexsurname_ins]: {
      details: {
        what: ({ value: { phone = '' } }) => printf('The phone number %s with your surname', phone),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud(),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.phonexsurname_ins, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['phone number', 'last name'],
        text: () => 'Your phone number has been found publicly available online with your last name.',
      },
    },
    [descriptionKeys.postcode_asp]: {
      details: {
        what: ({ value: { postcode = '' } }) => printf('The postcode: %s', postcode),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud(),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.postcode_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['postcode'],
        text: () => 'Your postcode has been found publicly available on the web.',
      },
    },
    [descriptionKeys.postcodexdob_asp]: {
      details: {
        what: ({ value: { postcode = '' } }) => printf('The postcode %s with your date of birth', postcode),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.blackMarket,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.postcodexdob_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['postcode', 'date of birth'],
        text: () => 'Your postcode and date of birth are being illegally published and sold online.',
      },
    },
    [descriptionKeys.postcodexphone_ins]: {
      details: {
        what: ({ value: { phone = '' } }) => printf('The phone number %s with your postcode', phone),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.identityFraud(),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.postcodexphone_ins, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['phone number', 'postcode'],
        text: () => 'Your phone number and postcode have been found publicly available together online.',
      },
    },
    [descriptionKeys.public]: {
      details: {
        [introKey]: ({ infoDate, infoType }, isFraud) => printf(`A %s was added to or removed from your ${experianReport(isFraud)} on %s. Please check your report to make sure you recognise the change and don’t need to take any action.`, infoType, infoDate),
        judgementCourt: ({ courtName }) => courtName,
        caseNumber: ({ caseNumber }) => caseNumber,
        amount: ({ amount }) => formatCurrency(amount),
        changeDate: ({ infoDate }) => infoDate,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.public](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: ({ infoType }, isFraud) => printf(`A %s has been added or removed from your ${experianReport(isFraud)}.`, infoType),
        text: ({ infoDate, infoType }, isFraud) => printf(`A %s was added to or removed from your ${experianReport(isFraud)} on %s.`, infoType, infoDate),
      },
    },
    [descriptionKeys.public_added]: {
      details: {
        [introKey]: ({ infoDate, infoType }, isFraud) => printf(`A %s was added to your ${experianReport(isFraud)} on %s. Please check your report to make sure you recognise the change and don’t need to take any action.`, infoType, infoDate),
        judgementCourt: ({ courtName }) => courtName,
        caseNumber: ({ caseNumber }) => caseNumber,
        amount: ({ amount }) => formatCurrency(amount),
        changeDate: ({ infoDate }) => infoDate,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.public_added](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: ({ infoType }, isFraud) => printf(`A %s has been added to your ${experianReport(isFraud)}.`, infoType),
        text: ({ infoDate, infoType }, isFraud) => printf(`A %s was added to your ${experianReport(isFraud)} on %s.`, infoType, infoDate),
      },
    },
    [descriptionKeys.public_removed]: {
      details: {
        [introKey]: ({ infoDate, infoType }, isFraud) => printf(`A %s was removed from your ${experianReport(isFraud)} on %s. Please check your report to make sure you recognise the change and don’t need to take any action.`, infoType, infoDate),
        judgementCourt: ({ courtName }) => courtName,
        caseNumber: ({ caseNumber }) => caseNumber,
        amount: ({ amount }) => formatCurrency(amount),
        changeDate: ({ infoDate }) => infoDate,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.public_removed](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: ({ infoType }, isFraud) => printf(`A %s has been removed from your ${experianReport(isFraud)}.`, infoType),
        text: ({ infoDate, infoType }, isFraud) => printf(`A %s was removed from your ${experianReport(isFraud)} on %s.`, infoType, infoDate),
      },
    },
    [descriptionKeys.instant_alert]: {
      details: {
        [introKey]: capsIntroKey,
        content: ({ search: { applicationType } }) => alertsCapsContent(applicationType, appTypeQuotation, appTypeIdentification),
        personalDetails: <StrongText>Personal details relating to the search</StrongText>,
        nameGeneric: ({ nameOnAlert }) => nameOnAlert,
        addressGeneric: ({ address }) => styledAddress(address),
        action: capsAction,
      },
      headline: {
        detail: () => 'There has been a search recorded on your Experian Report',
        emphasis: ['account', 'added or removed', 'Experian Credit Report'],
        text: ({
          search: {
            applicationType, isBlocked, searchedBy, searchedOn,
          },
        }) => printf(isBlocked ? (`${applicationType} application to ${(searchedBy || '').toUpperCase()} stopped by Experian CreditLock.`) : (`${indefinite(applicationType, true)} search was carried out on your Experian Credit Report on ${searchedOn} by ${searchedBy}.`)),
      },
    },
    [descriptionKeys.surnamexbankac_asp]: {
      details: {
        what: ({ value: { bankac = '' } }) => printf('The bank account number ending %s together with your last name', bankac),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.blackMarketDataTheft,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.surnamexbankac_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['bank account number', 'surname'],
        text: () => 'Your bank account number and surname are being illegally published and sold online.',
      },
    },
    [descriptionKeys.surnamexpostcode_asp]: {
      details: {
        what: ({ value: { surname = '' } }) => printf('The last name %s with your postcode', surname),
        where: ({ whereFound }) => defaultWhere({ whereFound }),
        why: () => alertRisks.blackMarket,
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions.surnamexpostcode_asp, webMonitoringContactUsConfig, noLinksToIns),
      },
      headline: {
        emphasis: ['last name', 'postcode'],
        text: () => 'Your last name and postcode are being illegally published and sold online.',
      },
    },
    [descriptionKeys.three_missed_payments]: {
      details: {
        [introKey]: ({ account: { company, type } }) => printf('3 or more missed payments have been reported by %s on your %s account. If you haven’t done so, you may need to take action. You can contact your creditor to discuss this with them. They will help and work with you to resolve it.', company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.three_missed_payments](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'A change in one of your accounts’ payment history',
        text: ({ account: { company, type } }) => printf('%s has reported 3 or more missed payments on your %s account.', company, type),
      },
    },
    [descriptionKeys.two_missed_payments]: {
      details: {
        [introKey]: ({ account: { company, type } }, isFraud) => printf(`Two missed payments have been reported by %s on your %s account. You may need to take action, if you haven’t already, as it could negatively impact your credit score. Please check on your ${experianReport(isFraud)} to make sure you recognise it and determine if you need to take any action.`, company, type),
        accountOpenedDate: ({ startDate }) => startDate,
        accountName: ({ nameOnAlert }) => nameOnAlert,
        accountAddress: ({ address }) => styledAddress(address),
        action: (alert, isFraud, benefits, noLinksToIns) => orderedListAction(alertActions[descriptionKeys.two_missed_payments](alert, benefits, noLinksToIns), genericContactUsConfig, noLinksToIns),
      },
      headline: {
        detail: () => 'A change in one of your accounts’ payment history',
        text: ({ account: { company, type } }) => printf('%s has reported 2 missed payments on your %s account.', company, type),
      },
    },
  },
  details: {
    action: 'What do I need to do now?',
    accountAddress: 'Address it is registered at:',
    accountName: 'Name on the account:',
    accountOpenedDate: 'When was the account opened:',
    addressGeneric: 'Address:',
    amount: 'What was the amount:',
    caseNumber: 'What was the case number:',
    changeDate: 'Date change happened:',
    companyName: 'Company name',
    date: 'Date found:',
    dateUpdatedOnReport: isFraud => `When was the account added to, or removed from your ${experianReport(isFraud)}:`,
    dateAddedOnReport: isFraud => `When was the account added to your ${experianReport(isFraud)}:`,
    dateRemovedFromReport: isFraud => `When was the account removed from your ${experianReport(isFraud)}:`,
    emailPasswordWhat: 'What did we find?',
    emailPassword: 'Along with a password',
    judgementCourt: 'Which court made the judgement:',
    maidenName: 'Maiden Name of',
    nameGeneric: 'Name:',
    what: 'What have we found?',
    where: 'Where have we found it?',
    why: 'Why do I need to know?',
  },
  types: {
    credit: 'credit',
    fraud: 'fraud',
  },
  typeName: {
    credit: 'Credit',
    fraud: 'Fraud',
  },
};
