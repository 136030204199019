import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FooterOOS, HeaderOOS, Oops, Link, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';

import Container from '../../components/Container';
import { EnvConsumer } from '../../components/Context';
import getProductDetails from '../../actions/product/getDetails';
import addEvent from '../../actions/ecd/addEvent';
import { referrerWhitelist } from '../../constants';
import ECD2PageInit from '../../components/ECD2PageInit';

@connect(
  state => ({
    oos: !state.auth.authenticated,
    homepageUrl: state.productDetails.homepageUrl,
    productDetailsFetched: state.productDetails.fetched,
  }),
  dispatch =>
    bindActionCreators(
      {
        addEvent,
        getProductDetails,
      },
      dispatch
    )
)
export default class ErrorPage extends React.Component {
  static propTypes = {
    getProductDetails: PropTypes.func.isRequired,
    homepageUrl: PropTypes.string,
    oos: PropTypes.bool,
    productDetailsFetched: PropTypes.bool,
    addEvent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    homepageUrl: '',
    oos: true,
    productDetailsFetched: false,
  };

  async componentDidMount() {
    if (!this.props.oos && !this.props.productDetailsFetched) {
      await this.props.getProductDetails();
    }

    const { referrer } = document;
    const onAllowedWhitelist = referrerWhitelist.some(wl => referrer.includes(wl));
    if (onAllowedWhitelist || window.Cypress) {
      Analytics.publish(
        MilestoneInputEvent.fromObject({
          login_success: {
            authenticationRoute: 'ECS Error',
          },
        })
      );
    }
  }

  render() {
    const { homepageUrl } = this.props;
    return (
      <EnvConsumer>
        {env => (
          <React.Fragment>
            <ECD2PageInit pageName="IdentityErrorPage" />
            <HeaderOOS homeUrl={homepageUrl || env.REACT_APP_C2_URL} />
            <Container as="main" id="main">
              <Oops oopsTitle="Oops… something went wrong">Please try again later.</Oops>
              <Link.Anchor href={homepageUrl || env.REACT_APP_C2_URL} {...setAutomationElement('backToHomepageLink')}>
                Go to Homepage
              </Link.Anchor>
            </Container>
            <FooterOOS />
          </React.Fragment>
        )}
      </EnvConsumer>
    );
  }
}
