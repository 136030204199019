import {
  SCORE_FETCHED,
  FETCH_SCORE,
  SCORE_NO_BENEFIT,
} from '../actions/scores/getScore';

export const defaultState = {
  scoreValue: null,
  isFetching: false,
  error: false,
  isReady: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case SCORE_FETCHED:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isReady: true,
      };
    case FETCH_SCORE:
      return {
        ...state,
        isFetching: true,
      };
    case SCORE_NO_BENEFIT:
      return {
        ...state,
        scoreValue: null,
        isReady: true,
      };
    default:
      return state;
  }
};
