const transformCase = (obj) => {
  const rawKeys = Object.keys(obj);

  return rawKeys.reduce((acc, key) => ({
    ...acc,
    [`${key.charAt(0).toUpperCase()}${key.slice(1)}`]: obj[key],
  }), {});
};

export default (state) => {
  const currentPath = state.router.location.pathname;
  const events = state.ecd.events || {};

  return (events[currentPath] || []).map(transformCase);
};
