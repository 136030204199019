import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

@withRouter
@connect(
  state => ({
    authenticated: state.auth.authenticated,
    fetching: state.auth.fetching,
  }),
)
export default class OutOfSessionRoute extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    fetching: PropTypes.bool.isRequired,
  };

  render() {
    const {
      authenticated,
      fetching,
    } = this.props;

    if (fetching) {
      return null;
    }

    return (!authenticated ? <Route {...this.props} /> : <Redirect to="/" />);
  }
}
