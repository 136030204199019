import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement, Typography } from '@experian-uk/corvetteuk-common-ui';

import { keyCodes, splashFeatures } from '../../constants';
import {
  Background,
  Container,
  Content,
  FeatureHeading,
  GetStartedButton,
  GetStartedButtonContainer,
  IdentityFeaturesList,
  Intro,
  IntroBackground,
  IntroCloseButton,
  IntroHeading,
  IntroSubHeading,
  Welcome,
} from './index.styles';

const handleKeyPress = (e, onClose) => {
  if ([keyCodes.enter, keyCodes.space].includes(e.key)) {
    e.preventDefault();
    onClose();
    // Allows us to restore the expected tab order on the page when the modal closes
    const [firstLinkOnPage] = document.getElementsByTagName('a');
    if (firstLinkOnPage) {
      firstLinkOnPage.focus();
    }
  }
};

const IdentitySplash = ({ onClose }) => (
  <Background>
    <Container>
      <Content {...setAutomationElement('identitySplash')}>
        <Intro>
          <IntroBackground aria-hidden="true" />
          <IntroCloseButton
            onClick={onClose}
            onKeyDown={e => handleKeyPress(e, onClose)}
            {...setAutomationElement('closeIDSplash')}
          >
            close
          </IntroCloseButton>
          <Welcome>
            <IntroHeading>
              Welcome to Identity Plus
            </IntroHeading>
            <IntroSubHeading>
              Time for peace of mind
            </IntroSubHeading>
          </Welcome>
        </Intro>
        <IdentityFeaturesList {...setAutomationElement('identitySplashFeatures')}>
          {splashFeatures.identity.map(feature => (
            <li key={feature.heading.props.children[0]}>
              <section>
                <FeatureHeading><span>{feature.heading}</span></FeatureHeading>
                <div>
                  {feature.paragraphs.map(paragraph => (
                    <Typography.Copy.SmallText key={paragraph}>{paragraph}</Typography.Copy.SmallText>
                  ))}
                </div>
              </section>
            </li>
          ))}
        </IdentityFeaturesList>
        <GetStartedButtonContainer>
          <GetStartedButton
            isButton
            label="Get started"
            onClick={onClose}
            onKeyDown={e => handleKeyPress(e, onClose)}
            {...setAutomationElement('getStartedIDSplash')}
          />
        </GetStartedButtonContainer>
      </Content>
    </Container>
  </Background>
);

IdentitySplash.propTypes = {
  freeTrial: PropTypes.bool,
  firesWelcomeEvent: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

IdentitySplash.defaultProps = {
  freeTrial: false,
  firesWelcomeEvent: false,
};

export default IdentitySplash;
