import { type as GET_ALL_ALERTS, fetchType as FETCHING_ALERTS } from '../actions/alerts/getAll';
import { type as UPDATE_ALERTS_COUNT } from '../actions/alerts/getCount';
import { ALERT_MARKED_AS_READ, MARK_ALERT_AS_READ } from '../actions/alerts/markAsRead';
import { alertValues } from '../constants';
import alertsHelpers from './helpers/alerts';
import { dayMonthAndYearFormat } from '../helpers';
import { ALL_ALERTS_MARKED_AS_READ, MARK_ALL_ALERTS_AS_READ } from '../actions/alerts/markAllAsRead';

const {
  evaluateAlertValue,
  getAlertType,
  getDescriptionKey,
  shouldShowReportLink,
} = alertsHelpers;

export const defaultState = () => ({
  unread: null,
  error: false,
  fetched: false,
  fetching: false,
  typeErrors: {
    [GET_ALL_ALERTS]: false,
    [UPDATE_ALERTS_COUNT]: false,
  },
  allAlerts: [],
  updateFailed: false,
  updateAllAlertsFailed: false,
  loading: false,
});

export const getAlertById = (state, alertId, benefits = {}, isFraud = false, noLinksToIns = false) => {
  if (!state.allAlerts || state.allAlerts.length < 1) {
    return null;
  }
  const alert = state.allAlerts.find(x => x.id === alertId);
  if (!alert) {
    return {
      error: true,
    };
  }

  const { details = {} } = alertValues.content[alert.descriptionKey] || {};

  let description = '';

  if (alertValues.content[alert.descriptionKey]) {
    const { detail, text } = alertValues.content[alert.descriptionKey].headline;
    description = detail ? detail(alert, isFraud) : text(alert, isFraud);
  }

  return {
    ...alert,
    details: Object.keys(details).reduce((_details, key) => {
      const alertDetails = _details;
      alertDetails[key] = typeof (details[key]) === 'function' ? details[key](alert, isFraud, benefits, noLinksToIns) : details[key];
      return alertDetails;
    }, {}),
    description,
    shouldShowReportLink: shouldShowReportLink(alert),
    typeDescription: alertValues.typeName[alert.type],
  };
};

const isError = state => Object.keys(state.typeErrors)
  .some(key => state.typeErrors[key]);

const mapAlerts = alerts => alerts
  .filter(a => alertValues.content[a.alertType.toLowerCase()])
  .map(alert => ({
    alertType: alert.alertType,
    account: {
      company: alert.company,
      type: alert.acctType,
    },
    address: alert.address,
    amount: alert.amount,
    caseNumber: alert.caseNumber,
    category: alert.alertCategory,
    code: alert.alertCode,
    courtName: alert.courtName,
    createDate: alert.createDt,
    descriptionKey: getDescriptionKey(alert),
    detailUpdated: dayMonthAndYearFormat(alert.detailUpdated),
    dob: dayMonthAndYearFormat(alert.dob),
    id: alert.alertId.toString(),
    infoDate: dayMonthAndYearFormat(alert.infoDate),
    infoType: alert.infoType,
    isUnread: !alert.viewDt,
    isUrgent: [alertValues.categories.urgent].includes(alert.alertCategory.toLowerCase()),
    lastUpdatedOn: alert.updateDt,
    name: alert.name,
    nameOnAlert: alert.name,
    search: {
      applicationType: alert.appType,
      searchedBy: alert.searchedBy,
      searchedOn: dayMonthAndYearFormat(alert.searchDt),
      searchType: alert.searchType,
      isLocked: alert.locked,
      isBlocked: alert.blocked,
      isLockExcluded: alert.lockExcluded,
    },
    startDate: dayMonthAndYearFormat(alert.startDt),
    type: getAlertType(alert),
    value: evaluateAlertValue(alert.alertValue, alert.alertType),
    whereFound: alert.url,
  }));

export default (state = defaultState(), action = {}) => {
  if (action.error) {
    if (action.type in state.typeErrors) {
      const newState = {
        ...state,
        typeErrors: {
          ...state.typeErrors,
          [action.type]: true,
        },
      };

      return {
        ...newState,
        error: isError(newState),
      };
    }
    return state;
  }

  switch (action.type) {
    case UPDATE_ALERTS_COUNT:
      return {
        ...state,
        unread: action.payload.count,
        error: isError(state),
      };
    case FETCHING_ALERTS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ALL_ALERTS:
      return {
        ...state,
        allAlerts: alertsHelpers.sortAlerts(mapAlerts(action.payload)),
        error: isError(state),
        fetching: false,
        fetched: true,
        updateAllAlertsFailed: false,
      };
    case ALERT_MARKED_AS_READ:
      return action.payload ? {
        ...state,
        allAlerts: state.allAlerts.map(alert => ({
          ...alert,
          isUnread: (alert.id === action.payload) ? false : alert.isUnread,
        })),
        unread: state.unread - 1,
        loading: false,
      } : {
        ...state,
        updateFailed: true,
        loading: false,
      };
    case MARK_ALERT_AS_READ:
      return {
        ...state,
        loading: true,
      };
    case ALL_ALERTS_MARKED_AS_READ:
      return action.payload === true ? {
        ...state,
        allAlerts: state.allAlerts.map(alert => ({
          ...alert,
          isUnread: false,
        })),
        unread: 0,
        loading: false,
        updateAllAlertsFailed: false,
      } : {
        ...state,
        loading: false,
        updateAllAlertsFailed: action.failedUpdate,
      };
    case MARK_ALL_ALERTS_AS_READ:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
