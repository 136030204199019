import { products } from '../../../constants';

export default [{
  family: products.credit_expert,
  id: 'def-123',
  terms: [{ price: 10.00 }],
  benefits: [
    { id: 'B001' },
    { id: 'B028', benefitActivated: true },
  ],
},
{
  family: products.identity,
  id: 'xyz-789',
  active: true,
  terms: [{ price: 1.00 }],
  benefits: [{ id: 'B028', benefitActivated: true }],
},
{
  family: products.basic,
  id: 'abc-123',
  terms: [{ price: 0 }],
  benefits: [],
},
{
  family: products.breach,
  id: 'abc-123',
  terms: [{ price: 0 }],
  benefits: [],
},
{
  family: products.breachPlus,
  id: 'abc-123',
  terms: [{ price: 0 }],
  benefits: [{ id: 'B028', benefitActivated: true }],
}];
