import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement, Typography } from '@experian-uk/corvetteuk-common-ui';

import {
  DecorativeToggleButton,
  ToggleButton,
  ToggleContainer,
  InnerToggle,
} from './index.styles';

const Toggle = ({
  automationKey,
  description,
  isToggled,
  setRowLayout,
  size,
  toggleConfig,
}) => {
  const { clickAction, offLabel, onLabel } = toggleConfig;
  return (
    <ToggleContainer setRowLayout={setRowLayout}>
      <InnerToggle setRowLayout={setRowLayout}>
        {offLabel && (
          typeof (offLabel) === 'string' ? <ToggleButton onClick={() => clickAction(false)}>{offLabel}</ToggleButton> : offLabel
        )}
        <DecorativeToggleButton
          isToggled={isToggled}
          onClick={clickAction}
          size={size}
          {...setAutomationElement(automationKey)}
        />
        {onLabel && (
          typeof (onLabel) === 'string' ? <ToggleButton onClick={() => clickAction(true)}>{onLabel}</ToggleButton> : onLabel
        )}
      </InnerToggle>
      {description && (
        typeof (description) === 'string' ? <Typography.Copy.SmallText>{description}</Typography.Copy.SmallText> : description
      )}
    </ToggleContainer>
  );
};

Toggle.propTypes = {
  automationKey: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isToggled: PropTypes.bool,
  setRowLayout: PropTypes.bool,
  size: PropTypes.string,
  toggleConfig: PropTypes.shape({
    clickAction: PropTypes.func.isRequired,
    offLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
};

Toggle.defaultProps = {
  automationKey: '',
  description: null,
  isToggled: false,
  setRowLayout: false,
  size: '24px',
};

export default Toggle;
