import styled from 'styled-components';

export const DetailsContainer = styled.dl`
  width: 100%;
  margin: 1.875rem 0;
  
  ${({ theme: { media } }) => media('20', `
     margin-bottom: 0;
  `)}
`;

export const Detail = styled.div`
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  font-size: ${({ theme }) => theme.typography.smallerFontSize};
  line-height: 1.3125rem;
  font-weight: 400;
  margin-bottom: 0.625rem;

  ${({ theme }) => theme.media('15', `
     flex-direction: row;
     justify-content: space-between;
     font-size: ${theme.typography.smallFontSize};
     line-height: ${theme.typography.baselineHeight};
  `)}
`;

export const DetailLabel = styled.dt`
  flex: 1 0 0;
  margin-right: 1.69492%;
`;

export const DetailValue = styled.dd`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.colors.experianDarkBlue};

  > div {
    display: block;
    clear: both;
   }
`;
