import styled from 'styled-components';
import { Button, Typography } from '@experian-uk/corvetteuk-common-ui';

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: .5rem 0;

  > div {
    margin: 0;
    padding: 0;
  }

  ${({ theme }) => theme.mediaEnd('15', `
    display: none;
  `)};
`;

export const StyledButton = styled(Button)`
  height: 3rem;
  margin-left: 2.5rem;
`;

export const StyledSmallerText = styled(Typography.Copy.SmallText)`
  display: block;
  font-size: ${({ theme }) => theme.typography.smallerFontSize};
  line-height: 1.5;
`;

export const StyledText = styled(Typography.Copy.SmallText)`
  color: ${({ theme }) => theme.colors.experianPink};
  display: block;
  line-height: 1.5;
`;
