import React from 'react';
import { NavLink } from 'react-router-dom';
import { setAutomationElement, Link } from '@experian-uk/corvetteuk-common-ui';
import styled from 'styled-components';
import { productIdentifiers } from '../../../../constants';


const UserMenuLink = styled(Link.Anchor)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.smallerFontSize};
  font-weight: 400;
  line-height: 2.5;

  padding: 10px 15px;
  text-decoration: none;

  &:hover {
    font-weight: 500;
  }

  &:hover, &:focus {
    color: ${({ theme }) => theme.colors.experianDarkBlue};
    outline: none;
    text-decoration: none;
  }
`;

const customSpaLink = (href, automationKey, title) => (
  <UserMenuLink as={NavLink} key={href} to={href} {...setAutomationElement(automationKey)}>{title}</UserMenuLink>
);

export default (env = {}) => ({
  core: [
    {
      automationKey: 'headerHome',
      href: '/',
      id: 'my-experian',
      label: 'My Experian',
    },
  ],
  sub: [
    {
      automationKey: 'headerIdentity',
      href: '/',
      id: 'id-plus',
      label: 'Identity Protection',
    },
    {
      automationKey: 'headerReport',
      href: '/report',
      id: 'fraud',
      label: 'Fraud Report',
    },
  ],
  userMenu: [
    {
      automationKey: 'menuMySubscriptions',
      href: `${env.REACT_APP_PRODMOVE_URL}`,
      title: 'My Subscriptions',
      showTo: [productIdentifiers.breach, productIdentifiers.breachPlus, productIdentifiers.credit_expert, productIdentifiers.identity],
    },
    {
      automationKey: 'menuMyExperianId',
      href: `${env.REACT_APP_INS_URL}/account/experian-id`,
      title: 'My Experian ID',
      showTo: [productIdentifiers.breach, productIdentifiers.breachPlus, productIdentifiers.credit_expert, productIdentifiers.identity],
    },
    {
      automationKey: 'menuMyExperianId',
      href: `${env.REACT_APP_CPP_URL}/profile`,
      title: 'My CPP ID',
      showTo: [productIdentifiers.cpp],
    },
    {
      render: () => customSpaLink('/alerts/settings', 'menuAlertsSettings', 'Alerts Settings'),
    },
    {
      automationKey: 'menuMessagingSettings',
      href: `${env.REACT_APP_INS_URL}/account/messaging/settings`,
      title: 'Messaging Settings',
      showTo: [productIdentifiers.breachPlus, productIdentifiers.credit_expert, productIdentifiers.identity],
    },
  ],
});
