import styled, { css } from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import info from './assets/icon-i.svg';
import warn from './assets/icon-warn.svg';
import Container from '../Container';

const icons = {
  info,
  warn,
};

export const InfoBlock = styled.div`
  align-self: flex-start;
  flex: 0 1 100%;
  margin-top: 1.5rem;
  padding: 2rem 1.875rem;
  position: relative;
  
  ${({ theme }) => theme.media('15', `
    flex: 0 1 35%;
  `)}
  
  ${props => props.withBorder && css`
     border-top: 1px solid ${({ theme }) => theme.colors.baseOutline};
     ${({ theme }) => theme.media('15', `
       border: 1px solid ${theme.colors.baseOutline};
    `)};
  `}

  ${({ theme }) => theme.mediaEnd('15', `
    margin-top: 3.125rem;
  `)}
`;

export const Content = styled(Typography.Copy.SmallText)`
  display: block;
  font-weight: 300;
  line-height: 1.75rem;
  margin: 1rem 0;

  ${({ theme }) => theme.media('10', `
    line-height: 1.75rem;
  `)};
`;

export const Heading = styled(Typography.Headings.Tertiary)`
  text-align: center;

  ${({ theme }) => theme.media('15', `
    text-align: left;
  `)};
`;

export const IconContainer = styled.div`
  padding: 2px;
  position: absolute;
  top: -0.55rem;
  left: 1.25rem;
  width: 3.562rem;
  height: 2.13rem;
  transform: translateY(-50%);
  background-color: #fff;
  text-align: center;
`;

export const Icon = styled.i`
  display: inline-block;
  width: 2.937rem;
  height: 2.937rem;
  color: ${({ theme }) => theme.colors.experianDarkBlue};
  text-align: center;
  font-size: 1.6rem;
  background-repeat: no-repeat;
  background-size: contain;

  ${props => props.name && css`
    background-image: url(${icons[props.name]});
  `}
`;


export const InfoBlockLeftContent = styled.div`
  flex: 1 0 100%;

  ${({ theme }) => theme.media('15', `
    flex: 2;
    margin-right: 8rem;
  `)}
`;

export const InfoBlockFlexContainer = styled(Container)`
  border-top: 1px solid ${({ theme }) => theme.colors.baseOutline};
  padding: 1.25rem 0;
  display: flex;
  flex-wrap: wrap;
  ${props => props.noBorderTop && `
    border-top: none;
  `}
  ${({ theme }) => theme.media('15', `
    display: flex;
    padding: 1.875rem 0;
  `)}
`;
