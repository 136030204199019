import descriptionKeys from '../../../constants/alertsDescriptionKeys';

export default {
  [descriptionKeys.ccno_asp]: 'ccno',
  [descriptionKeys.driversl_asp]: 'driversl',
  [descriptionKeys.email_asp]: 'email',
  [descriptionKeys.email_soc]: 'email',
  [descriptionKeys.emailxpostcode_ins]: 'email',
  [descriptionKeys.passportxsurname_asp]: 'passport',
  [descriptionKeys.phone_asp]: 'phone',
  [descriptionKeys.phone_soc]: 'phone',
  [descriptionKeys.phonexsurname_ins]: 'phone',
  [descriptionKeys.postcode_asp]: 'postcode',
  [descriptionKeys.postcodexdob_asp]: 'postcode',
  [descriptionKeys.postcodexphone_ins]: 'postcode',
  [descriptionKeys.surnamexbankac_asp]: 'bankac',
  [descriptionKeys.surnamexpostcode_asp]: 'surname',
};
