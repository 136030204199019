import React from 'react';

const thanksTickColor = '#0F8554'; // Green600 does not exist in nebula yet

const TickCircle = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_114_710)">
      <g clipPath="url(#clip1_114_710)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill={thanksTickColor}
        />
        <path
          d="M9.28007 13.2801L14.7801 7.78007C14.9125 7.6378 14.9846 7.44975 14.9812 7.25544C14.9779 7.06112 14.8993 6.87567 14.7621 6.73807C14.6245 6.60082 14.439 6.52227 14.2447 6.51891C14.0504 6.51555 13.8623 6.58766 13.7201 6.72007L8.75007 11.6901L6.53007 9.47007C6.3878 9.33766 6.19975 9.26555 6.00544 9.26891C5.81112 9.27227 5.62567 9.35082 5.48807 9.48807C5.35082 9.62567 5.27227 9.81112 5.26891 10.0054C5.26555 10.1998 5.33766 10.3878 5.47007 10.5301L8.22007 13.2801C8.36069 13.4205 8.55132 13.4994 8.75007 13.4994C8.94882 13.4994 9.13944 13.4205 9.28007 13.2801Z"
          fill={thanksTickColor}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_114_710">
        <rect width="20" height="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_114_710">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TickCircle;
