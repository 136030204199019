import { commonTerminology } from '../../constants';
import { getEnv} from '../Context/env';

function textMap(isBlocked, isLockExcluded, isLocked) {
  const {REACT_APP_LOCK_URL} = getEnv();

  return !isBlocked && isLockExcluded && isLocked
    ?  {
      creditLockButtonText: `Learn more about ${commonTerminology.creditLock}`,
      creditLockButtonKind: 'secondaryBlue',
      creditLockButtonHref: `${REACT_APP_LOCK_URL}/#lock-faq`,
    }
    : {
      creditLockButtonText: `Go to ${commonTerminology.creditLock}`,
      creditLockButtonKind: 'primaryBlue',
      creditLockButtonHref: REACT_APP_LOCK_URL,
    };
}

export default ({ isBlocked, isLockExcluded, isLocked }) => textMap(isBlocked, isLockExcluded, isLocked);
