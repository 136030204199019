import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, {
  InteractionInputEvent,
} from '@experian-uk/web-common-analytics';
import defaultTheme from '@nebula/theme';
import GlobalStyles from '@nebula/global-styles';

import {
  SpottedText,
  ThanksContainer,
  ThanksHeading,
  ThanksIconWrapper,
  ThanksIconWrapperLarge,
} from './index.styles';
import { FeedbackWrapper } from '../index.styles';
import TickCircle from '../assets/tickCircle';
import TickCircleLarge from '../assets/tickCircleLarge';


const NotRecognisedFeedback = ({ notRecognisedActionsRef, notRecognisedActionsId }) => {
  const sendAnalyticsEvent = (interaction) => {
    Analytics.publishOnce(
      InteractionInputEvent.fromObject({
        component: `recognise_stopped_application_${interaction}`,
        action: 'click',
        status: 'success',
      }),
    );
  };

  const scrollToNotRecognisedActions = () => {
    notRecognisedActionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleWhatNextClick = (e) => {
    e.preventDefault();
    sendAnalyticsEvent('what_next_link');
    scrollToNotRecognisedActions();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <FeedbackWrapper>
        <ThanksContainer>
          <ThanksIconWrapper>
            <TickCircle />
          </ThanksIconWrapper>
          <ThanksIconWrapperLarge>
            <TickCircleLarge />
          </ThanksIconWrapperLarge>
          <div>
            <ThanksHeading>Thanks for alerting us</ThanksHeading>
            <SpottedText>
              Now you&apos;ve spotted this, it&apos;s good to take extra care
              with a few more steps.
            </SpottedText>
            <a
              href={`#${notRecognisedActionsId}`}
              onClick={e => handleWhatNextClick(e)}
              {...setAutomationElement('what-next-link')}
            >
              What should I do next?
            </a>
          </div>
        </ThanksContainer>
      </FeedbackWrapper>
    </ThemeProvider>
  );
};

NotRecognisedFeedback.propTypes = {
  notRecognisedActionsId: PropTypes.string,
  notRecognisedActionsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) }),
  ]),
};

NotRecognisedFeedback.defaultProps = {
  notRecognisedActionsId: '',
  notRecognisedActionsRef: '',
};

export default NotRecognisedFeedback;
