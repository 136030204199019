import { ALL_OFFERS_FETCHED } from '../actions/offers/getAll';

const defaultState = {
  isLoaded: false,
  error: false,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      error: [ALL_OFFERS_FETCHED].includes(action.type),
    };
  }

  switch (action.type) {
    case ALL_OFFERS_FETCHED: {
      const { offers } = action.payload;
      return {
        ...state,
        data: offers.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id]: cur,
          }),
          {}
        ),
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};
