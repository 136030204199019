import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles, { BodyMedium500, BodyMedium700 } from '@nebula/global-styles';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '@nebula/theme';
import { PersonalDetailsContent, PersonalDetails, PersonalDetailsCard } from './index.styles';

const AlertsPersonalDetailsCard = ({ name, address, dob }) => (
  <ThemeProvider theme={defaultTheme}>
    <GlobalStyles />
    <PersonalDetailsCard cardType="elevated">
      <PersonalDetailsContent>
        <PersonalDetails>
          <BodyMedium700>Name used</BodyMedium700>
          <BodyMedium500>{name}</BodyMedium500>
        </PersonalDetails>
        <PersonalDetails>
          <BodyMedium700>Date of Birth used</BodyMedium700>
          <BodyMedium500>{dob}</BodyMedium500>
        </PersonalDetails>
        <PersonalDetails>
          <BodyMedium700>Address used</BodyMedium700>
          <BodyMedium500>{address}</BodyMedium500>
        </PersonalDetails>
      </PersonalDetailsContent>
    </PersonalDetailsCard>
  </ThemeProvider>
);

AlertsPersonalDetailsCard.propTypes = {
  name: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default AlertsPersonalDetailsCard;
