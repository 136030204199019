import { FETCH_WMDATA_FACTS, WMDATA_FACTS_FETCHED } from '../actions/monitoring/getMonitoredFacts';
import {
  WMDATA_ADD_FACT_LOADING,
  WMDATA_ADD_FACT_SUCCESSFUL,
  WMDATA_ADD_FACT_UNSUCCESSFUL,
} from '../actions/monitoring/addFact';
import monitoringFactsConst from '../constants/monitoringFacts';
import {
  WMDATA_REMOVE_FACT_SUCCESSFUL,
  WMDATA_REMOVE_FACT_LOADING,
  WMDATA_REMOVE_FACT_UNSUCCESSFUL,
} from '../actions/monitoring/removeFact';
import getItemByKey from './helpers/monitoring';

export const defaultState = {
  error: null,
  facts: [],
  loading: false,
  loadingAddFact: null,
  editingSection: [],
};

const transformFact = (facts = []) => facts.map((fact) => {
  const {
    givenname,
    middlename,
    surname,
    driversl,
    line1,
    line2,
    line3,
    line4,
    postcode,
    country,
    bankac,
    ccno,
    ...newDataItem
  } = JSON.parse(fact.dataItem);

  return {
    ...fact,
    dataItem: {
      ...newDataItem,
      firstName: givenname,
      middleName: middlename,
      lastName: surname,
      drivingLicence: driversl,
      address: {
        line1,
        line2,
        line3,
        line4,
        postcode,
        country,
      },
      bankAccountNumber: bankac,
      cardNumber: ccno,
    },
  };
});

const mergedFactMap = {
  [monitoringFactsConst.cardNumber]: 'cardNumber',
};

export const factTransform = (fact, factKey) => {
  if ([monitoringFactsConst.cardNumber, monitoringFactsConst.bankAccountNumber].includes(factKey)) {
    return (fact.indexOf('*') === -1 ? fact.replace(/.(?=.{4,}$)/g, '*') : fact);
  }

  return fact;
};

const buildDataItem = (newFact, factKey) => {
  if (factKey === monitoringFactsConst.name) {
    return ({
      ...newFact[factKey],
    });
  }
  return mergedFactMap[factKey] ? ({
    [mergedFactMap[factKey]]: factTransform(newFact[factKey], factKey),
  }) : ({
    [factKey]: factTransform(newFact[factKey], factKey),
  });
};

const mergeFacts = (factKey, factList, currentFacts) => currentFacts
  .concat(factList.map(newFact => ({
    ...newFact,
    dataItem: buildDataItem(newFact, factKey),
    datumType: factKey,
    source: 'DATA',
  })));

const removeFact = (id, currentFacts) => currentFacts
  .filter(fact => fact.id !== id);

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case WMDATA_ADD_FACT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        loadingAddFact: null,
        facts: mergeFacts(action.payload.factKey, [action.payload.fact], state.facts),
      };
    case WMDATA_ADD_FACT_LOADING:
      return {
        ...state,
        error: null,
        loadingAddFact: action.payload,
      };
    case WMDATA_REMOVE_FACT_SUCCESSFUL:
      return {
        ...state,
        loadingRemoveFact: action.payload,
        facts: removeFact(action.payload.id, state.facts),
      };
    case WMDATA_REMOVE_FACT_UNSUCCESSFUL:
    case WMDATA_ADD_FACT_UNSUCCESSFUL:
      return {
        ...state,
        error: action.payload,
        loadingAddFact: null,
      };
    case WMDATA_REMOVE_FACT_LOADING:
      return {
        ...state,
        error: null,
        loadingRemoveFact: true,
      };
    case FETCH_WMDATA_FACTS:
      return {
        ...state,
        loading: true,
      };
    case WMDATA_FACTS_FETCHED:
      return action.payload instanceof Error ? {
        ...state,
        loading: false,
      } : {
        ...state,
        loading: false,
        facts: transformFact(action.payload),
        addresses: getItemByKey(action.payload, 'address'),
        dateOfBirth: getItemByKey(action.payload, 'dob'),
        email: getItemByKey(action.payload, 'email'),
        fullName: getItemByKey(action.payload, 'name'),
        phone: getItemByKey(action.payload, 'phone'),
      };
    default:
      return state;
  }
};
