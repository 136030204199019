import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const WMDATA_REMOVE_FACT_LOADING = 'WMDATA_REMOVE_FACT_LOADING';
export const WMDATA_REMOVE_FACT_UNSUCCESSFUL = 'WMDATA_REMOVE_FACT_UNSUCCESSFUL';
export const WMDATA_REMOVE_FACT_SUCCESSFUL = 'WMDATA_REMOVE_FACT_SUCCESSFUL';

export default (id, customerId, tenantId) => async (dispatch) => {
  if (!id) {
    return Promise.reject(new Error('Fact must be supplied'));
  }

  dispatch({ type: WMDATA_REMOVE_FACT_LOADING });

  return dispatch(fetch(`/wmdata/customer/${customerId}/data/${id}?tenantId=${tenantId}`, {
    method: 'DELETE',
  }, false, null, true))
    .then((response) => {
      const { status, message } = response;
      if (status && status >= 400) {
        throw new Error(`Could not update web monitoring settings. ${message}`);
      }

      return dispatch({
        type: WMDATA_REMOVE_FACT_SUCCESSFUL,
        error: false,
        payload: {
          id,
        },
      });
    })
    .catch(() => dispatch({
      type: WMDATA_REMOVE_FACT_UNSUCCESSFUL,
      error: true,
      payload: errorMessages.updateFailed,
    }));
};
