import { BILLING_FETCHED, BILLING_FETCH_FAILED } from '../actions/billingsubscriptions';

export const defaultState = {
  endDate: null,
  error: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case BILLING_FETCHED:
      return {
        ...state,
        endDate: action.payload.endDate,
        nextBillDt: action.payload.nextBillDt,
        billingStateRecycle: !!action.payload.isPaymentMethodUpdateRequired,
        error: null,
      };
    case BILLING_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
