import { rem } from 'polished';
import styled from 'styled-components';
import { Button as CommonButton } from '@experian-uk/corvetteuk-common-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${rem(24)};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-wrap: nowrap;

  // mobile + tablet
  @media ${({ theme }) => theme.media.tabletLandscapeMax} {
    flex-wrap: wrap;
  }

  @media ${({ theme }) => theme.media.tabletLandscapeMin} {
    align-items: center;
  } 
`;

export const IconArea = styled.div`
  width: ${rem(45)};
  height: ${rem(45)};
  margin-right: ${rem(26)};
  text-align: right;
`;

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const ContentWrapper = styled.div`
  flex: 1 0 0;
`;

export const Heading = styled.h2`
  margin: 0;
  font-size: ${rem(20)};
  font-weight: 500;
  line-height: ${rem(30)};
  color: ${({ theme }) => theme.colors.experianDarkGrey};
`;

export const BodyArea = styled.div`
  margin-top: ${rem(8)};
  font-size: ${rem(18)};
  line-height: ${rem(27)};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.experianDarkGrey};

  // tablet + desktop
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  }
`;

export const Button = styled(CommonButton)`
  margin-top: ${rem(16)};
  padding: ${rem(12)} ${rem(30)};
  font-size: ${rem(16)};
  font-weight: 400;
  line-height: ${rem(24)};
  border-radius: 4px;
`;

export const SidecarContainer = styled.div`
  width: auto;
  margin-top: 0;

  // mobile + tablet
  @media ${({ theme }) => theme.media.tabletLandscapeMax} {
    width: 100%;
    margin-top: ${rem(16)};
    padding-left: ${rem(71)};
  }
`;
