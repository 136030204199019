/* istanbul ignore file */
import PropTypes from 'prop-types';
import defaultAddress from './address';

const phone = PropTypes.shape({
  phoneNumber: PropTypes.string,
  phoneType: PropTypes.string,
  isVerified: PropTypes.boolean,
});

export default {
  title: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dob: PropTypes.string,
  address: PropTypes.arrayOf(defaultAddress),
  email: PropTypes.string,
  phone: PropTypes.arrayOf(phone),
};
