import fetch from '../fetch';
import initialize from '../ecd/initialize';

export const CUSTOMER_FETCHED = 'CUSTOMER_FETCHED';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const SPLIT_KEY_FETCHED = 'SPLIT_KEY_FETCHED';

export default () => async (dispatch) => {
  dispatch({ type: FETCH_CUSTOMER });

  try {
    const customer = await dispatch(fetch('/customers'));

    dispatch({
      type: CUSTOMER_FETCHED,
      payload: {
        currentUser: customer.data[0],
      },
    });

    dispatch({
      type: SPLIT_KEY_FETCHED,
      payload: customer.data[0].customerNumber,
    });

    return dispatch(initialize());
  } catch (e) {
    return dispatch({
      type: CUSTOMER_FETCHED,
      payload: new Error('Error fetching customer data'),
      error: true,
      meta: {
        unrecoverableError: true,
      },
    });
  }
};
