import React from 'react';
import PropTypes from 'prop-types';

const TickIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12L8.29289 17.2929C8.68342 17.6834 9.31658 17.6834 9.70711 17.2929L21 6" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

TickIcon.propTypes = {
  color: PropTypes.string,
};

TickIcon.defaultProps = {
  color: 'currentColor',
};

export default TickIcon;
