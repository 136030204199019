import React from 'react';

const thanksTickColor = '#0F8554'; // Green600 does not exist in nebula yet

const TickCircleLarge = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_114_713)">
      <g clipPath="url(#clip1_114_713)">
        <path
          d="M17.7313 8.50557C17.7347 8.69987 17.6626 8.88792 17.5301 9.03009L10.7801 15.7801C10.6395 15.9205 10.4488 15.9994 10.2501 15.9994C10.0513 15.9994 9.86072 15.9205 9.72009 15.7801L6.47009 12.5301C6.33761 12.3879 6.26549 12.1999 6.26892 12.0056C6.27234 11.8113 6.35106 11.6259 6.48847 11.4885C6.62588 11.3511 6.81127 11.2723 7.00557 11.2689C7.19987 11.2655 7.38792 11.3376 7.53009 11.4701L10.2501 14.1901L16.4701 7.97009C16.6123 7.83761 16.8003 7.76549 16.9946 7.76892C17.1889 7.77234 17.3743 7.85106 17.5117 7.98847C17.6491 8.12588 17.7278 8.31127 17.7313 8.50557Z"
          fill={thanksTickColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill={thanksTickColor}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_114_713">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_114_713">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TickCircleLarge;
