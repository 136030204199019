import styled, { css } from 'styled-components';
import { Typography, Button } from '@experian-uk/corvetteuk-common-ui';

export const Copy = styled(Typography.Copy.Text)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  font-weight: 500;

  ${({ theme }) => theme.media('15', css`
    display: inline;
    margin-right: 1.25rem;
  `)}
`;

export const Container = styled.section`
  margin: 1.8125rem 0;
`;

export const SelectionButton = styled(Button).attrs({
  type: 'secondary',
  isButton: true,
})`
  min-width: 69px;
  min-height: 59px;
  border: 1px solid ${({ theme }) => theme.colors.borderBlue};

  svg {
    width: 28px;
    margin: auto;
    margin-top: 2px;
  }

  #fillElement {
    fill: ${({ theme, isSelected }) => (isSelected ? theme.colors.baseWhite : theme.colors.experianDarkBlue)};
  }

  ${({ isSelected, theme }) => (isSelected ? css`
    background: ${theme.colors.experianDarkBlue};
  ` : '')}
`;

export const Actions = styled.div`
  > * {
    display: inline;
    margin-right: 0.625rem;
  }

  ${({ theme }) => theme.media('15', css`
    display: inline;
  `)}
`;
