import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';
import defaultTheme from '@nebula/theme';
import GlobalStyles, { TitleLarge700 } from '@nebula/global-styles';

import {
  Container,
  StyledButton,
  ThanksContainer,
  ThanksHeading,
  ThanksIconWrapper,
  ThanksIconWrapperLarge,
} from './index.styles';
import { ContentHeader, FeedbackButtonWrapper, FeedbackWrapper } from '../index.styles';
import TickCircle from '../assets/tickCircle';
import TickCircleLarge from '../assets/tickCircleLarge';
import NotRecognisedFeedback from '../NotRecognisedFeedback';

const Recognised = Object.freeze({
  YES: 'yes',
  NO: 'no',
  UNSELECTED: 'unselected',
});

const Option1LockFeedback = ({ notRecognisedActionsRef, notRecognisedActionsId }) => {
  const [recognised, setRecognised] = useState(Recognised.UNSELECTED);

  const sendAnalyticsEvent = interaction => {
    Analytics.publishOnce(
      InteractionInputEvent.fromObject({
        component: `recognise_stopped_application_${interaction}`,
        action: 'click',
        status: 'success',
      })
    );
  };

  const handleRecognisedClick = buttonValue => {
    setRecognised(buttonValue);
    sendAnalyticsEvent(buttonValue);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <FeedbackWrapper>
        {recognised === Recognised.UNSELECTED && (
          <>
            <ContentHeader>
              <TitleLarge700>Do you recognise this application?</TitleLarge700>
            </ContentHeader>
            <FeedbackButtonWrapper>
              <Container>
                <div>
                  <StyledButton
                    label="Yes"
                    buttonKind="secondaryBlue"
                    onClick={() => handleRecognisedClick(Recognised.YES)}
                    {...setAutomationElement('yes-lock-feedback')}
                  />
                </div>
                <div>
                  <StyledButton
                    label="No"
                    buttonKind="secondaryBlue"
                    onClick={() => handleRecognisedClick(Recognised.NO)}
                    {...setAutomationElement('no-lock-feedback')}
                  />
                </div>
              </Container>
            </FeedbackButtonWrapper>
          </>
        )}

        {recognised === Recognised.YES && (
          <ThanksContainer {...setAutomationElement('yes-message')}>
            <ThanksIconWrapper>
              <TickCircle />
            </ThanksIconWrapper>
            <ThanksIconWrapperLarge>
              <TickCircleLarge />
            </ThanksIconWrapperLarge>
            <div>
              <ThanksHeading>Remember to unlock before continuing with your application</ThanksHeading>
              <p>Thank you for your feedback.</p>
            </div>
          </ThanksContainer>
        )}

        {recognised === Recognised.NO && (
          <NotRecognisedFeedback
            {...setAutomationElement('no-message')}
            notRecognisedActionsRef={notRecognisedActionsRef}
            notRecognisedActionsId={notRecognisedActionsId}
          />
        )}
      </FeedbackWrapper>
    </ThemeProvider>
  );
};

Option1LockFeedback.propTypes = {
  notRecognisedActionsId: PropTypes.string,
  notRecognisedActionsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) }),
  ]),
};

Option1LockFeedback.defaultProps = {
  notRecognisedActionsId: '',
  notRecognisedActionsRef: '',
};

export default Option1LockFeedback;
