import uniqBy from 'lodash/uniqBy';
import { v4 as uuidv4 } from 'uuid';
import { LOCATION_CHANGE } from 'connected-react-router';

import { sections } from '../constants/report/sections';
import { FETCH_LATEST_REPORT, LATEST_REPORT_FETCHED } from '../actions/reports/getLatest';
import { FETCH_REPORT, REPORT_FETCHED } from '../actions/reports/getById';
import transformAccounts from './helpers/report/transformAccounts';
import { missingPreviousAddresses as getPreviousAddresses } from '../transforms/missingPreviousAddresses';
import { monthAndYearFormat, parseQueryString } from '../helpers';

export const defaultState = {
  currentPath: '/', // the section of the report the user is currently viewing
  pathParams: {}, // any parameters specified in the current URL, e.g. '/report/searches?tab=1' would become { tab: 1 };
  currentReport: '', // the ID of the report the user is currently viewing
  latest: null, // the most recent report
  selected: null, // the report currently selected for viewing
  available: [], // those reports whose IDs have been discovered but not fetched
  fetching: [], // reports whose fetches are in progress,
  ready: [], // reports that are ready for use
  metadata: {}, // report metadata provided by API
  dataCache: {}, // private cache of all report data
  sections: [], // report sections available to the user
  dataFetched: false, // indicates report data has been returned
};

const reportDefaults = {
  accounts: {
    all: [],
    count: 0,
    dataFetched: false,
    tabs: [],
  },
  aliases: [],
  cifas: [],
  creditSearches: [],
  linkedAddresses: [],
  electoralRolls: [],
  financialAssociatSearches: [],
  financialAssociations: [],
  usefulAddresses: [],
  noticeOfCorrections: [],
  previousReports: [],
  previousAddresses: [],
  profile: {},
  publicRecords: [],
  rental: [],
  reportSummary: {
    reportSummaryItems: [],
  },
};

export const shouldFetchCurrentReport = state => {
  const conditions = [
    state.currentReport !== state.latestReport,
    !state.ready.includes(state.currentReport),
    !state.fetching.includes(state.currentReport),
  ];

  return conditions.every(x => x);
};

export const getReportState = state => {
  if (!state.sections.length) {
    return null;
  }

  const currentIndex = state.sections.findIndex(s => s.path && state.currentPath.includes(s.path));
  const resolvedCurrentIndex = currentIndex === -1 ? 0 : currentIndex;
  const nextIndex = resolvedCurrentIndex + 1 >= state.sections.length ? 0 : resolvedCurrentIndex + 1;
  const prevIndex = resolvedCurrentIndex - 1 < 0 ? state.sections.length - 1 : resolvedCurrentIndex - 1;

  return {
    currentSection: { ...state.sections[resolvedCurrentIndex], pageNumber: resolvedCurrentIndex + 1 },
    nextSection: { ...state.sections[nextIndex], pageNumber: nextIndex + 1 },
    prevSection: { ...state.sections[prevIndex], pageNumber: prevIndex + 1 },
    totalSections: state.sections.length,
  };
};

const registrationDates = ([startDate, endDate]) => ({
  startDate,
  endDate,
  registrationDate: monthAndYearFormat(startDate),
  registrationEndDate: endDate ? monthAndYearFormat(endDate, 'Present') : 'Present',
});

const processElectoralRolls = electoralRolls =>
  electoralRolls.map(roll => {
    const { entryNumber, address, nocRef, registrationDetails = [] } = roll;

    const registration = registrationDetails
      .reduce(
        (details, { registrationDuration }) => [
          ...details,
          ...Object.keys(registrationDuration).map(key => registrationDates(registrationDuration[key])),
        ],
        []
      )
      .sort(({ startDate: lStartDate }, { startDate: rStartDate }) => rStartDate.localeCompare(lStartDate));

    return {
      entryNumber,
      address,
      nocRef,
      registration,
    };
  });

const processFetchedReport = (state, payload, isLatest) => {
  const [report] = payload.data;
  const { previousReports = [], profile } = report.report;
  const { reportId, reportDate } = report;
  const newFetchedReports = state.fetching.filter(id => id !== reportId);
  const newAvailableReports = uniqBy([...state.available, ...previousReports], x => x.reportId);
  const newReadyReports = uniqBy([...state.ready, reportId], x => x);
  const addresses = profile.address;
  const previousAddresses = getPreviousAddresses(addresses);
  const accountSortConfig = [
    {
      property: 'ageInMonths',
      order: 'asc',
    },
    {
      property: 'categoryIndex',
      order: 'asc',
    },
  ];

  report.report.electoralRolls = processElectoralRolls(report.report.electoralRolls || []);

  return {
    ...state,
    latest: isLatest ? reportId : state.latest,
    available: newAvailableReports,
    ready: newReadyReports,
    fetching: newFetchedReports,
    metadata: isLatest ? payload.metadata : state.metadata,
    dataCache: {
      ...state.dataCache,
      [reportId]: {
        ...report.report,
        reportDate,
        previousReports,
        previousAddresses,
        accounts: transformAccounts(report.report.accounts, undefined, accountSortConfig),
        usefulAddresses: !report.report.usefulAddresses
          ? []
          : report.report.usefulAddresses.map(address => ({
              ...address,
              id: uuidv4(),
            })),
      },
    },
    sections: Object.keys(sections).map(section => sections[section]),
    dataFetched: true,
  };
};

const getPathNames = () => Object.keys(sections).map(section => sections[section].path);

const markAsFetching = (state, reportId) => ({
  ...state,
  available: state.available.filter(id => id !== reportId),
  fetching: [...state.fetching, reportId],
});

export const getLatestReport = state => (state.dataCache ? state.dataCache[state.latest] : reportDefaults);

export const getReportById = (state, reportId) => state.dataCache[reportId] || reportDefaults;

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case LATEST_REPORT_FETCHED:
      return {
        ...processFetchedReport(state, action.payload, true),
      };
    case FETCH_REPORT:
      return {
        ...markAsFetching(state, action.payload),
        dataFetched: false,
      };
    case FETCH_LATEST_REPORT:
      return {
        ...state,
        dataFetched: false,
      };
    case REPORT_FETCHED:
      return {
        ...processFetchedReport(state, action.payload),
      };
    case LOCATION_CHANGE: {
      const urlSegments = action.payload.location.pathname.split('/');
      const isPrevious = action.payload.location.pathname.includes('/report/previous');

      return {
        ...state,
        currentPath: isPrevious
          ? '/previous'
          : getPathNames().find(path => path === [...urlSegments].reverse()[0]) || '',
        currentReport: urlSegments.filter(
          segment => [...getPathNames(), 'report', 'previous'].indexOf(segment) === -1
        )[0],
        pathParams: parseQueryString(action.payload.location.search),
      };
    }
    default:
      return state;
  }
};
