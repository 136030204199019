export default {
  name: 'name',
  drivingLicence: 'driversl',
  passport: 'passport',
  email: 'email',
  phone: 'phone',
  address: 'address',
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  line4: 'line4',
  postcode: 'postcode',
  country: 'country',
  bankAccountNumber: 'bankac',
  cardNumber: 'ccno',
  data: 'DATA',
};
