import PropTypes from 'prop-types';

export default PropTypes.shape({
  REACT_APP_AUTH_URL: PropTypes.string.isRequired,
  REACT_APP_C2_URL: PropTypes.string.isRequired,
  REACT_APP_INS_URL: PropTypes.string.isRequired,
  REACT_APP_PRODMOVE_URL: PropTypes.string.isRequired,
  REACT_APP_GATEWAY_URL: PropTypes.string.isRequired,
  REACT_APP_ENV: PropTypes.oneOf(['local', 'dev', 'int', 'stg', 'prod']),
  REACT_APP_IDAAS_URL: PropTypes.string.isRequired,
  REACT_APP_IDAAS_PRN: PropTypes.string.isRequired,
  REACT_APP_SESSION_TIMEOUT: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  REACT_APP_PROFILE_URL: PropTypes.string.isRequired,
});
