export const formatAddress = ({
  flat,
  houseName,
  houseNumber = '',
  street = '',
  district,
  town,
  city,
  county,
  postCode = '',
  country,
}, includeCountry = false) => [flat, houseName, `${houseNumber} ${street}`, district, town, city, county, postCode, (includeCountry ? country : null)].filter(item => item);

export const formatCount = (num) => {
  const overrides = {
    11: 'th',
    12: 'th',
    13: 'th',
  };
  const overrideMatcher = Math.round(((num / 100) % 1) * 100, 10);
  if (overrides[overrideMatcher]) {
    return `${num}${overrides[overrideMatcher]}`;
  }
  return `${num}${({
    1: 'st',
    2: 'nd',
    3: 'rd',
  })[num % 10] || 'th'}`;
};

export const formatCurrency = (value, countryCode = 'en-GB', currencyCode = 'gbp') => value.toLocaleString(countryCode, { style: 'currency', currency: currencyCode });

const evaluateString = (string, isLast) => `${(string && typeof (string) === 'string') ? `${string.replace(/ /g, '')}${isLast ? '' : ' '}` : ''}`;

const concatenateDefinedStrings = (stringObject = {}, sortOrder) => {
  const stringKeys = Object.keys(stringObject).filter(key => sortOrder.includes(key.toLowerCase()));

  if (sortOrder) {
    stringKeys.sort((a, b) => {
      const indexes = {
        a: sortOrder.indexOf(a.toLowerCase()),
        b: sortOrder.indexOf(b.toLowerCase()),
      };
      if (indexes.a < indexes.b) {
        return -1;
      }
      if (indexes.a > indexes.b) {
        return 1;
      }
      return 0;
    });
  }

  const stringKeysLength = stringKeys.length;
  return stringKeys.map((key, index) => `${evaluateString(stringObject[key], index >= stringKeysLength - 1)}`).join().replace(/,/g, '');
};

const nameSortOrder = [
  'title',
  'firstname',
  'givenname',
  'middlename',
  'initials',
  'lastname',
  'surname',
];

export const formatName = (nameObject = {}) => concatenateDefinedStrings(nameObject, nameSortOrder);
