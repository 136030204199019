import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { notificationTypes } from '../../constants';

const colorMatcher = {
  info: {
    background: 'baseLightGrey',
    border: 'baseOutline',
  },
  warn: {
    background: 'baseWhite',
    border: 'warningOrange',
  },
};

export const Container = styled.div`
  background: ${({ notificationType, theme }) => theme.colors[colorMatcher[notificationType || notificationTypes.info].background]};
  border: 1px solid ${({ notificationType, theme }) => theme.colors[colorMatcher[notificationType || notificationTypes.info].border]};
  border-radius: .25rem;
  font-weight: 300;
  margin: .9375rem 0;
  padding: .9375rem;
  width: 100%;
`;

export const NoticeText = styled(Typography.Copy.Text)`
  margin: 0;
  padding: 0;
`;
