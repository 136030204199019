const typeSortOrder = {
  fraud: 1,
  credit: 2,
};

export default (alerts) => {
  if (!alerts || alerts.length <= 0) {
    return alerts;
  }
  return alerts.sort((a, b) => {
    if (a.createDate < b.createDate) {
      return 1;
    }
    if (a.createDate > b.createDate) {
      return -1;
    }
    return 0;
  }).sort((a, b) => {
    if (a.isUrgent < b.isUrgent) {
      return 1;
    }
    if (a.isUrgent > b.isUrgent) {
      return -1;
    }
    return 0;
  }).sort((a, b) => {
    if (typeSortOrder[a.type] < typeSortOrder[b.type]) {
      return -1;
    }
    if (typeSortOrder[a.type] > typeSortOrder[b.type]) {
      return 1;
    }
    return 0;
  });
};
