import styled, { css } from 'styled-components';
import { Typography, Button } from '@experian-uk/corvetteuk-common-ui';

export const Copy = styled(Typography.Copy.Text)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  font-weight: 500;

  ${({ theme }) => theme.media('15', css`
    display: inline;
    margin-right: 1.25rem;
  `)}
`;

export const Container = styled.section`
  margin: 1.8125rem 0;
`;

export const SelectionButton = styled(Button).attrs({
  type: 'secondary',
  isButton: true,
})`
  min-width: 113px;
  min-height: 54px;

  ${({ isSelected, theme }) => (isSelected ? css`
    background: ${theme.colors.experianDarkBlue};
    color: ${theme.colors.baseWhite};
    &:hover {
      color: ${theme.colors.baseWhite};
    }
  ` : '')}
`;

export const Actions = styled.div`
  > * {
    margin-right: 0.625rem;
  }

`;
