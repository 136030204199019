import React from 'react';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {
  FraudReportIntro,
  PersonalDetailDate,
  PersonalDetailsHeading,
  ReportContainer,
  ReportIntroduction,
  ReportPersonalDetails,
  ReportText,
  ReportTitle,
  ReportTitleContainer,
  ReportTopTitle,
} from './index.styles';
import { dateFormat } from '../../constants';
import SpaLink from '../SpaLink';

export const ReportBanner = ({
  showPersonalDetails,
  showImage,
  titleText,
  pageNumber,
  reportText,
  maxPages,
  currentUser,
  isIntro,
  basePath,
}) => (
  <FraudReportIntro withImage={showImage}>
    <ReportContainer isIntro={isIntro}>
      <ReportIntroduction>
        <ReportTitleContainer>
          <ReportTitle>Fraud report page {pageNumber}</ReportTitle>
          <ReportTitle>
            {pageNumber}/{maxPages}
          </ReportTitle>
        </ReportTitleContainer>
        <ReportTopTitle>{titleText}</ReportTopTitle>
        {reportText.map(textItem => (
          <ReportText key={textItem.id}>{textItem.text}</ReportText>
        ))}
      </ReportIntroduction>
      {showPersonalDetails && (
        <ReportPersonalDetails>
          <PersonalDetailsHeading>
            {currentUser.firstName} {currentUser.lastName}
          </PersonalDetailsHeading>
          <PersonalDetailDate>
            Date of Birth: {dayjs(currentUser.dob).format(dateFormat.defaultFormat)}
          </PersonalDetailDate>
          <SpaLink {...setAutomationElement('report-personal-details')} to={`/${basePath}/personal-details`}>
            View your personal details
          </SpaLink>
        </ReportPersonalDetails>
      )}
    </ReportContainer>
  </FraudReportIntro>
);

ReportBanner.propTypes = {
  showPersonalDetails: PropTypes.bool,
  showImage: PropTypes.bool,
  titleText: PropTypes.string,
  pageNumber: PropTypes.number,
  maxPages: PropTypes.number,
  reportText: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
  }),
  isIntro: PropTypes.bool,
  basePath: PropTypes.string,
};

ReportBanner.defaultProps = {
  showPersonalDetails: false,
  showImage: false,
  titleText: 'Welcome to your Experian Fraud Report',
  pageNumber: 1,
  maxPages: 6,
  reportText: [
    {
      id: 'report-text-item-fraud',
      text: 'A fraud report shows you the important data we hold which can help identify and protect you from any fraudulent activity in your name.',
    },
    {
      id: 'report-text-item-credit-score',
      text: 'This information also contributes towards your Experian Credit Score to show your likely credit standing with lenders and will be used by many companies to verify your identity, so try to make sure it’s accurate.',
    },
  ],
  currentUser: {
    firstName: '',
    lastName: '',
    dob: '',
  },
  isIntro: false,
  basePath: 'report',
};
