import fetch from '../fetch';
import alertsEndpoint from './baseEndpoint';

export const MARK_ALERT_AS_READ = 'MARK_ALERT_AS_READ';

export const ALERT_MARKED_AS_READ = 'ALERT_MARKED_AS_READ';

export default alertId => async (dispatch) => {
  if (!alertId) {
    return Promise.reject(new Error('Alert ID must be supplied'));
  }

  dispatch({
    type: MARK_ALERT_AS_READ,
  });

  try {
    const response = await dispatch(
      fetch(`${alertsEndpoint}/${alertId}`,
        { method: 'PUT' },
        false,
        null,
        true),
    );
    const [success] = response.data;

    return dispatch({
      type: ALERT_MARKED_AS_READ,
      payload: success ? alertId : null,
    });
  } catch (e) {
    return dispatch({
      type: ALERT_MARKED_AS_READ,
      payload: null,
    });
  }
};
