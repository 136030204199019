import { commonTerminology } from '../../constants';

const creditLockBadgeScenarios = {
  couldImpact: {
    renderBadge: true,
    badgeText: `Could impact your ${commonTerminology.experianCreditScore}`,
    badgeType: 'primary',
  },
  wontImpact: {
    renderBadge: true,
    badgeText: `Won’t impact your ${commonTerminology.experianCreditScore}`,
    badgeType: 'tertiary',
  },
  missingData: {
    renderBadge: false,
    badgeText: '',
    badgeType: '',
  },
};

function textMap(searchTypeUpperCase, isBlocked) {
  if (searchTypeUpperCase === 'H') {
    return isBlocked ? creditLockBadgeScenarios.wontImpact : creditLockBadgeScenarios.couldImpact;
  }
  return searchTypeUpperCase === 'S' ? creditLockBadgeScenarios.wontImpact : creditLockBadgeScenarios.missingData;
}


export default ({ searchType, isBlocked }) => {
  const searchTypeUpperCase = typeof searchType === 'string' ? searchType.toUpperCase() : searchType;
  return textMap(searchTypeUpperCase, isBlocked);
};
