import { CUSTOMER_FETCHED, FETCH_CUSTOMER } from '../actions/auth/getCustomer';
import { type as SET_USER } from '../actions/auth/setUser';
import { CPP_ELIGIBILITY_FETCHED } from '../actions/auth/getCppEligibility';

const initialState = {
  authenticated: false,
  currentUser: {},
  fetching: false,
  mockData: null,
  sessionId: null,
  token: null,
  csrfToken: null,
};

export default (state = initialState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case CPP_ELIGIBILITY_FETCHED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          cppPolicyNumber: action.payload.policyNumber,
        },
      };
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case CUSTOMER_FETCHED:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        fetching: false,
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        fetching: true,
      };
    default:
      return state;
  }
};
