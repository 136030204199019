import React, { Fragment } from 'react';
import { Link, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { EnvConsumer } from '../components/Context/env';
import SpaLink from '../components/SpaLink';
import descriptionKeys from './alertsDescriptionKeys';

const addWebMonitoringCardDetails = 'Add the details of your other cards to Web Monitoring so we can check you haven’t had any other cards affected.';
const addWebMonitoringDetailsDataRisk = 'Give us additional details to monitor in Web Monitoring to ensure none of your other data is at risk.';
const addWebMonitoringPersonalDetails = 'Add more of yours and your family’s personal details into Web Monitoring.';
const associatedLenders = (hasCreditReport, hasFraudReport, noLinksToIns) => {
  if (hasFraudReport || noLinksToIns) {
    return (
      <Fragment>
        If you do not recognise the lender, see if you recognise one of their&nbsp;
        <SpaLink
          to="/report/queries"
          inline="true"
          {...setAutomationElement('link-to-queries')}
        >
          associated lenders
        </SpaLink>
      </Fragment>
    );
  }
  if (hasCreditReport) {
    return (
      <EnvConsumer>
        {
          context => (
            <Fragment>
              If you do not recognise the lender, see if you recognise one of their&nbsp;
              <Link.Anchor href={`${context.REACT_APP_INS_URL}/report/queries`} inline {...setAutomationElement('link-to-queries')}>associated lenders.</Link.Anchor>
            </Fragment>
          )
        }
      </EnvConsumer>
    );
  }
  return null;
};
const bewareEmails = 'Be wary of any emails you receive, even from companies or banks you recognise, where they are asking you to provide personal info – they could be fake, or spam.';
const bewareSmartphoneUnauthorisedAppDownloads = 'Don’t download any unauthorised apps onto your smartphone.';
const bewareSuspiciousEmails = 'Keep an eye out for any suspicious emails, even from companies or banks that you recognise, as they could be fraudulent.';
const bewareSuspiciousPhoneCalls = 'If the number needs to be available publicly, consider removing your last name from the same web page.';
const bewareUnwantedCompanyPhoneContact = 'This makes you more likely to get unwanted calls and text messages. Be careful about giving your details to a company or bank if they have contacted you – even if it is one that you recognise, as it could be a scam.';
const birthMarriageCertificateCopyFraud = 'This means that someone else could get hold of your birth or marriage certificate and use it fraudulently as ID – but this is unlikely. They would need a lot of other personal information about you first to get this certificate, which you must pay to receive.';
const changeAllEmailPasswords = 'Change your passwords on all your other online accounts that contact you at this email address.';
const changeAllPasswords = 'Change all your passwords for any online accounts that are registered at this address to prevent the likelihood of fraudulent activity on your current accounts.';
const changeEmailPassword = 'Change the password for this email address immediately.';
const changeThisAndSimilarPasswords = 'Immediately change the password you use to access this account online, and if you use this password or a similar one anywhere else, you should change those too.';
const checkBankTransactions = 'Check your bank statement to ensure there isn’t anything on there that you don’t recognise.';
const checkBankTransactionsHistorical = 'Check your bank statements over the past few months for any transactions you don’t recognise and report these to your bank too.';
const checkBankTransactionsOnlineRetail = 'Check your bank statements carefully and regularly over the next few months. For example, a fraudster could go to an online retailer that you log in to and are freely able to purchase from. They could have the ‘forgotten password’ link sent to your email account, which they now have access to, and change your password for the online retailer and purchase goods in your name with your bank details.';
const checkCreditReport = 'Keep an eye on your credit report to check that no new credit accounts that you don’t recognise are opened.';
const checkCreditReportAccounts = 'Keep an eye on your credit report for any new credit accounts opened that you don’t recognise.';
const checkCreditReportThreeMonths = 'Keep an eye on your credit report over the next 3 months to ensure no new credit accounts are opened that you don’t recognise.';
const checkCreditReportAndBankTransactions = 'Keep a close eye on your credit report and your bank statements over the next few months for any activity you don’t recognise, such as transactions to companies you don’t know or new credit accounts opening.';
const checkReceivingRegularPost = 'Be extra vigilant that you are receiving all of your regular post.';
const checkReceivingRegularPostBankUtility = 'You should check that you have received all of your regular post, such as bank statements and utility bills.';
const companiesHouseAbout = 'Companies House is a UK government department responsible for incorporating and dissolving limited companies, storing company information act, and making this available to the public.';
const companiesHousePubliclyAvailableRecordRisk = 'There is a publicly available record containing your personal details, and you should be aware of this, as it makes you vulnerable to identity fraud.';
const companiesHouseRegisterTwentyYears = 'Dissolved companies and details of their officers remain on the Companies House register for 20 years. If your residential address was first recorded by Companies House on or after 1 October 2009, you can apply to Companies House to replace your address with a service address (e.g. the company registered office). If your residential address was recorded by Companies House before 1 October 2009, there is only limited scope for changing that address.';
const confirmPostSent = 'If not we recommend that you contact the provider and confirm when they were last sent.';
const contactCompaniesHouse = 'Contact Companies House on 0303 1234 500 if you want to query or apply to change a record.';
const contactRoyalMail = 'You can contact the Royal Mail on 0845 7 740 740.';
const electoralRollNameAddressRisk = 'If you are happy for this information to be available, you should bear in mind that this puts you at a higher risk of identity fraud because someone could fraudulently apply for credit with your name and address details.';
const electoralRollPublic = 'When you join the electoral roll, you are asked if you also want to join the Edited Electoral Register. This is an online, publicly available version of the Electoral Roll which allows the public and marketing agencies to access your name and address details.';
const electoralRollRegistration = 'Removing yourself from the Edited Electoral Register does not remove you from the electoral roll. If you do not want your details on the electoral register made more widely available, you should tick the box on the electoral registration form sent to every household each year. You can also opt out at any time of year by contacting your electoral registration officer.';
const foreignPassport = 'If it is a foreign passport';
const generalRegisterOffice = 'You cannot remove this information from the General Register Office that is held on you by the government.';
const groBirthMarriageCertificateCopy = 'The GRO website enables you to get a copy of your birth or marriage certificate and research your family tree.';
const hideEmailAddressAndPostcode = 'Visit the above website and remove at least either your email address or your postcode from visibility, but preferably both.';
const hideLastNameAlongsidePhoneNumber = 'If the number needs to be available publicly, consider removing your last name from the same web page.';
const hidePersonalDetailsWebMonitoring = 'Ensure your other personal details are not publicly available online – add them to Web Monitoring if you want us to search for them. They could be used together to apply for credit in your name.';
const hidePhoneNumberAndPostcode = 'Visit the above website and remove at least either your phone number or your postcode from visibility, but preferably both.';
const hidePhoneNumberIfPossible = 'Visit the above website and remove your phone number if it is not necessary for it to be publicly available.';
const ignoreSuspiciousCompanyCommunications = 'Don’t respond to any suspicious calls, emails or texts you receive from companies you don’t know.';
const mothersMaidenName = 'Some companies ask you to provide your mother’s maiden name as a security measure or to verify your identity. As your mother’s maiden name is available to anyone its important not to use it as a standard password or as one of your memorable words.';
const otherThingsToDo = 'Other things you can do';
export const pleaseNoteExternalLink = 'Please note this information is hosted outside of the Identity Plus service, so you will be exposed to Experian products or services that are not relevant to your CPP Identity Protection Policy.';
const recognisedAccount = 'If you recognise the account there is nothing to worry about.';
const recognisedJudgement = 'If you recognise the judgement there is nothing to worry about.';
const recognisedSearch = 'If you recognise the search there is nothing to worry about.';
const registerMailPreferenceService = 'Register this address with the mail preference service in case a fraudster is having your mail redirected.';
const reportStolenAccountNumber = 'Contact your bank immediately and tell them that your account number has been stolen and possibly copied.';
const reportStolenCard = 'Contact your bank or lender immediately and report the card as stolen or copied.';
const reportStolenDrivingLicence = 'Contact the DVLA immediately and let them know that your driving licence number has been lost, stolen or copied.';
const reportStolenPassport = 'Visit your local police station to file your passport number as stolen immediately.';
const reportStolenPassportAsap = 'Report your passport stolen at your local police station as soon as possible.';
const reportStolenPassportViaFormLS01 = 'Download a copy of form LS01 in order to report your passport stolen and send this form to Her Majesty’s passport office immediately.';
const requestEmergencyPassport = 'You may need to file for an emergency passport. You can do this at the issuing country’s embassy in the UK.';
const requestWebsiteOwnerRemoveDetails = 'If you cannot do this yourself, contact the website owner to request that these details be removed if possible.';
const scoreFactors = hasScoreFactorsBenefit => hasScoreFactorsBenefit && (
  <EnvConsumer>
    {
      context => (
        <Link.Anchor href={`${context.REACT_APP_INS_URL}/score#score-factors`} inline {...setAutomationElement('link-to-score')}>Take a look at how this change affects your score.</Link.Anchor>
      )
    }
  </EnvConsumer>
);
const socialMediaHideEmail = 'Make your email address not visible on social media by following their online instructions. It’s likely to be in your membership settings.';
const socialMediaHidePhoneNumber = 'Make your phone number not visible on social media by following their online instructions. It’s likely to be in your membership settings.';
const unrecognisedAccount = 'If you still don’t recognise the account, it could be a sign of fraud. You should contact the lender as soon as possible using the contact details link above.';
const unrecognisedJudgement = ({ courtName }) => `If you do not recognise the judgment, contact ${courtName}.`;
const unrecognisedSearch = 'A lender usually must tell you when they are going to look at your credit report. If you do not recognise the search, please contact us or the lender concerned.';
const ukPassport = 'If it is a UK passport';
const updateAntiVirus = 'Install or update your anti-virus software on any computers you use.';
const victimsOfFraud = (hasCreditReport, hasFraudReport, noLinkToIns) => {
  if (hasCreditReport || hasFraudReport) {
    return (
      <Fragment>
        Visit our <Link.Anchor href="https://consumer.learn.experian.co.uk/IS/s/article/What-should-I-do-if-I-think-I-ve-been-a-victim-of-fraud" inline {...setAutomationElement('link-to-vof')}>Victims of Fraud</Link.Anchor> page to see how we can help you.
        {noLinkToIns && (
          <small>
            <br />
            {pleaseNoteExternalLink}
          </small>
        )}
      </Fragment>
    );
  }
  return null;
};

export default {
  [descriptionKeys.activated_account]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport),
  ],
  [descriptionKeys.cais]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.cais_added]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.cais_removed]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.caps]: (alert, benefits = {}) => [
    recognisedSearch,
    scoreFactors(benefits.scoreFactors),
    unrecognisedSearch,
  ],
  [descriptionKeys.ccno_asp]: [
    reportStolenCard,
    checkBankTransactions,
    addWebMonitoringCardDetails,
  ],
  [descriptionKeys.defaulted_account]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.driversl_asp]: [
    reportStolenDrivingLicence,
    checkCreditReportThreeMonths,
    addWebMonitoringPersonalDetails,
  ],
  [descriptionKeys.email_asp]: [
    changeEmailPassword,
    changeAllEmailPasswords,
    checkBankTransactionsOnlineRetail,
    updateAntiVirus,
    bewareEmails,
  ],
  [descriptionKeys.email_soc]: [
    socialMediaHideEmail,
    hidePersonalDetailsWebMonitoring,
    bewareSuspiciousEmails,
  ],
  [descriptionKeys.emailxpostcode_ins]: [
    hideEmailAddressAndPostcode,
    requestWebsiteOwnerRemoveDetails,
    checkCreditReport,
  ],
  [descriptionKeys.namexdob_bmd]: [
    generalRegisterOffice,
    mothersMaidenName,
    groBirthMarriageCertificateCopy,
    birthMarriageCertificateCopyFraud,
  ],
  [descriptionKeys.namexpostcode_er]: [
    electoralRollPublic,
    electoralRollRegistration,
    electoralRollNameAddressRisk,
  ],
  [descriptionKeys.namexpostcodexdob_ch]: [
    companiesHouseAbout,
    companiesHousePubliclyAvailableRecordRisk,
    companiesHouseRegisterTwentyYears,
    contactCompaniesHouse,
  ],
  [descriptionKeys.passportxsurname_asp]: [
    {
      hideContactUs: true,
      title: `${ukPassport}:`,
      orderedListContent: [
        reportStolenPassportViaFormLS01,
        reportStolenPassportAsap,
      ],
    },
    {
      hideContactUs: true,
      title: `${foreignPassport}:`,
      orderedListContent: [
        reportStolenPassport,
        requestEmergencyPassport,
      ],
    },
    {
      title: `${otherThingsToDo}:`,
      orderedListContent: [
        checkCreditReportAccounts,
        addWebMonitoringDetailsDataRisk,
      ],
    },
  ],
  [descriptionKeys.payment_history_or_account_status_change]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.payments_made_on_account]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.phone_asp]: [
    bewareUnwantedCompanyPhoneContact,
    ignoreSuspiciousCompanyCommunications,
    bewareSmartphoneUnauthorisedAppDownloads,
    addWebMonitoringDetailsDataRisk,
  ],
  [descriptionKeys.phone_soc]: [
    socialMediaHidePhoneNumber,
    hidePersonalDetailsWebMonitoring,
    bewareSuspiciousPhoneCalls,
  ],
  [descriptionKeys.phonexsurname_ins]: [
    hidePhoneNumberIfPossible,
    hideLastNameAlongsidePhoneNumber,
    requestWebsiteOwnerRemoveDetails,
    checkCreditReport,
  ],
  [descriptionKeys.postcode_asp]: [
    contactRoyalMail,
    checkReceivingRegularPostBankUtility,
    confirmPostSent,
  ],
  [descriptionKeys.postcodexdob_asp]: [
    changeAllPasswords,
    registerMailPreferenceService,
    checkReceivingRegularPost,
  ],
  [descriptionKeys.postcodexphone_ins]: [
    hidePhoneNumberAndPostcode,
    requestWebsiteOwnerRemoveDetails,
    checkCreditReport,
  ],
  [descriptionKeys.public]: (alert, benefits = {}) => [
    recognisedJudgement,
    scoreFactors(benefits.scoreFactors),
    unrecognisedJudgement(alert),
  ],
  [descriptionKeys.public_added]: (alert, benefits = {}) => [
    recognisedJudgement,
    scoreFactors(benefits.scoreFactors),
    unrecognisedJudgement(alert),
  ],
  [descriptionKeys.public_removed]: (alert, benefits = {}) => [
    recognisedJudgement,
    scoreFactors(benefits.scoreFactors),
    unrecognisedJudgement(alert),
  ],
  [descriptionKeys.surnamexbankac_asp]: [
    reportStolenAccountNumber,
    checkBankTransactionsHistorical,
    changeThisAndSimilarPasswords,
    checkCreditReportAndBankTransactions,
  ],
  [descriptionKeys.surnamexpostcode_asp]: [
    changeAllPasswords,
    registerMailPreferenceService,
    checkReceivingRegularPost,
  ],
  [descriptionKeys.three_missed_payments]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
  [descriptionKeys.two_missed_payments]: (alert, benefits = {}, noLinksToIns) => [
    recognisedAccount,
    scoreFactors(benefits.scoreFactors),
    associatedLenders(benefits.creditReport, benefits.fraudReport, noLinksToIns),
    unrecognisedAccount,
    victimsOfFraud(benefits.creditReport, benefits.fraudReport, noLinksToIns),
  ],
};
