import fetch from '../serviceFetch';
import { services, errorMessages } from '../../constants';

export const FETCH_BOOST = 'FETCH_BOOST';
export const BOOST_FETCHED = 'BOOST_FETCHED';

const service = {
  service: services.verdusProxy,
  endpoint: '/boost',
};

export default () => async (dispatch) => {
  dispatch({ type: FETCH_BOOST });

  const fetched = {
    type: BOOST_FETCHED,
  };

  try {
    const boostData = await dispatch(fetch(service, { nonCritical: true }));

    fetched.payload = boostData;
  } catch (error) {
    const errorMessage = {
      heading: errorMessages.default,
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }

  dispatch(fetched);
};
