import updateEcd from './update';
import resolvers from './resolvers';

export default () => (dispatch, getState) => {
  const currentState = getState();

  if (!currentState.subscriptions || !currentState.billingsubscriptions) {
    return undefined;
  }

  const calculatedEcd = Object.keys(resolvers)
    .reduce((acc, cur) => ({
      ...acc,
      [cur]: resolvers[cur](currentState),
    }), {});

  delete calculatedEcd.Events;

  return dispatch(updateEcd(calculatedEcd));
};
