import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  SectionTextBold,
  SectionText,
} from './index.styles';

const ActionPoint = ({ sect }) => (
  <Fragment>
    <SectionTextBold>{sect.title}</SectionTextBold>
    <SectionText>{sect.text}</SectionText>
  </Fragment>
);

ActionPoint.propTypes = {
  sect: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default ActionPoint;
