import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Count } from '@experian-uk/corvetteuk-common-ui';

const StyledTabDescription = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: .25rem;
  }
`;

const TabDescription = ({
  descriptionText,
  count,
}) => (
  <StyledTabDescription>
    {count && (<Count automationKey="accounts-issue-count" countValue={count} />)}
    <div>{descriptionText}</div>
  </StyledTabDescription>
);

TabDescription.propTypes = {
  descriptionText: PropTypes.string,
  count: PropTypes.number,
};

TabDescription.defaultProps = {
  descriptionText: '',
  count: null,
};

export default TabDescription;
