import styled from 'styled-components';

const TextInput = styled.input.attrs({
  type: 'text',
})`
    border: .0625rem solid ${({ hasError, theme }) => (hasError ? theme.colors.alertError : theme.colors.borderBlue)};
    background: ${({ hasError, theme }) => (hasError ? theme.colors.alertErrorTint : theme.colors.baseWhite)};
    border-radius: .3125rem;
    font-size: 1rem;
    height: 55px;
    padding: 0.75rem;
    transition: .5s ease-in-out;
`;

TextInput.displayName = 'TextInput';

export { TextInput };
