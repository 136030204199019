import React from 'react';
import styled from 'styled-components';
import { Link, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { benefits, productIdentifiers, splitsList } from '../../../../constants';
import customSubNavRender from '../IdentityNavLink';
import { hasBenefit } from '../../../../reducers/subscriptions';

const boostedTreatments = [
  'high-intent-day0-a',
  'high-intent-day1plus-a',
  '999ers-day1plus-b',
  '999ers-day0-b',
  'low-intent-day1plus-b',
  'low-intent-day0-b',
  'high-intent-day1plus-b',
  'high-intent-day0-b',
  'low-intent-day1plus-c',
  'low-intent-day0-c',
  'high-intent-day1plus-c',
  'high-intent-day0-c',
];

const isBoostedTreatment = treatment => boostedTreatments.includes(treatment);

const getUnderstandScoreLink = (insUrl, splits = {}) => (splits[splitsList.scoreComponents] ? `${insUrl}/score/understand` : `${insUrl}/score`);

const StyledLink = styled(Link.Anchor)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.smallFontSize};
  font-weight: 400;
  padding: 0.9375rem 0 0.625rem;
  text-decoration: none;
  &:hover, &:focus {
    color: ${({ theme }) => theme.colors.experianDarkBlue};
    text-decoration: none;
  }
  sup {
    color: ${({ theme }) => theme.colors.experianRaspberry};
    padding-left: 0.375rem;
  }

  margin: 0 0.9375rem;

  ${({ theme }) => theme.media('20', `
    margin: 0;
  `)};

  ${({ theme }) => theme.media('30', `
    margin: 0 1.4rem 0 1.5625rem;
  `)};
`;

const boostLink = env => () => (
  <StyledLink id="headerBoost" {...setAutomationElement('headerBoost')} href={`${env.REACT_APP_HOME_URL}/boost`} title="Boost Your Score">Boost Your Score<sup>NEW</sup></StyledLink>
);

export default (env = {}, splitConfig = {}) => ({
  [productIdentifiers.credit_expert]: {
    core: [{
      automationKey: 'headerHome',
      href: env.REACT_APP_INS_URL,
      id: 'my-experian',
      label: 'My Experian',
    }],
    sub: [
      {
        automationKey: 'headerSubHome',
        href: env.REACT_APP_INS_URL,
        id: 'header-sub-home',
        label: 'Home',
        title: 'Home',
      },
      {
        automationKey: 'headerScore',
        href: getUnderstandScoreLink(env.REACT_APP_INS_URL, splitConfig),
        id: 'header-score',
        label: 'Your Credit Score',
        title: 'Your Credit Score',
      },
      {
        automationKey: 'headerScoreHistory',
        href: `${env.REACT_APP_INS_URL}/score/history`,
        id: 'header-score-history',
        label: 'Score History',
        showIf: ({ splits }) => splits[splitsList.scoreHistory],
        title: 'Score History',
      },
      {
        automationKey: 'headerCreditReport',
        href: `${env.REACT_APP_INS_URL}/report`,
        id: 'header-report',
        label: 'Credit Report',
        title: 'Credit Report',
      },
      {
        automationKey: 'headerScoreImprove',
        href: `${env.REACT_APP_INS_URL}/score/builder`,
        id: 'header-score-builder',
        label: 'Improve your Score',
        showIf: ({ subscriptionDetails }) => hasBenefit(subscriptionDetails, [benefits.scoreImprovement]),
        title: 'Improve your Score',
      },
      {
        automationKey: 'headerScoreSimulator',
        href: `${env.REACT_APP_INS_URL}/simulations`,
        id: 'header-score-simulator',
        label: 'Score Simulator',
        showIf: ({ subscriptionDetails }) => hasBenefit(subscriptionDetails, [benefits.scoreSimulations]),
        title: 'Score Simulator',
      },
      {
        automationKey: 'headerIdentity',
        href: '/',
        id: 'header-identity',
        label: 'Identity Protection',
        renderFunc: customSubNavRender,
        title: 'Identity Protection',
      },
      {
        showIf: ({ splits }) => isBoostedTreatment(splits[splitsList.boostPlacement]),
        renderFunc: boostLink(env),
      },
    ],
  },
  [productIdentifiers.identity]: {
    sub: [
      {
        automationKey: 'headerIdentity',
        href: '/',
        id: 'header-identity',
        label: 'Identity Protection',
        renderFunc: customSubNavRender,
        title: 'Identity Protection',
      },
      {
        automationKey: 'headerScore',
        href: `${env.REACT_APP_INS_URL}/score`,
        id: 'header-score',
        label: 'Your Credit Score',
        title: 'Your Credit Score',
      },
      {
        automationKey: 'headerReport',
        href: '/report',
        id: 'header-report',
        label: 'Fraud Report',
        renderFunc: customSubNavRender,
        title: 'Fraud Report',
      },
      {
        showIf: ({ splits }) => isBoostedTreatment(splits[splitsList.boostPlacement]),
        renderFunc: boostLink(env),
      },
    ],
  },
  [productIdentifiers.breachPlus]: {
    sub: [
      {
        automationKey: 'headerIdentity',
        href: '/',
        id: 'id-plus',
        label: 'Identity Protection',
      },
      {
        automationKey: 'headerScore',
        href: `${env.REACT_APP_INS_URL}/score`,
        id: 'header-score',
        label: 'Your Credit Score',
        title: 'Your Credit Score',
      },
      {
        automationKey: 'headerReport',
        href: '/report',
        id: 'fraud',
        label: 'Fraud Report',
      },
      {
        showIf: ({ splits }) => isBoostedTreatment(splits[splitsList.boostPlacement]),
        renderFunc: boostLink(env),
      },
    ],
  },
  [productIdentifiers.breach]: {
    sub: [
      {
        automationKey: 'headerIdentity',
        href: '/',
        id: 'id-plus',
        label: 'Identity Protection',
      },
      {
        automationKey: 'headerReport',
        href: '/report',
        id: 'fraud',
        label: 'Fraud Report',
      },
    ],
  },
});
