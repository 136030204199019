import { rem } from 'polished';
import styled from 'styled-components';

export const SpottedText = styled.p`
  margin-bottom: ${rem(16)};
`;

export const ThanksContainer = styled.div`
         align-items: stretch;
         color: ${({ theme }) => theme.color.ui.blueGrey900};
         column-gap: ${rem(8)};
         display: flex;
         padding: ${rem(16)} 0 ${rem(40)};

         @media ${({ theme }) => theme.media.tabletPortraitMin} {
           column-gap: ${rem(10)};
           padding: ${rem(56)} 0 0 0;
         }
       `;

export const ThanksHeading = styled.h4`
  font-size: ${rem(18)};
  font-weight: 500;
  margin-bottom: ${rem(4)};
`;

export const ThanksIconWrapper = styled.div`
  min-width: ${rem(20)};
  padding-top: ${rem(2)};

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    display: none;
  }
`;

export const ThanksIconWrapperLarge = styled.div`
  display: none;
  min-width: ${rem(24)};
  padding-top: ${rem(4)};

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    display: unset;
  }
`;
