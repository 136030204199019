export default {
  colors: {
    breachLightBlue: '#326DB6',
    breachDarkBlue: '#1D4490',
    experianLilac: '#a8b5d1',
    landingPageBlue: '#1d4f91',
    warningOrange: '#F89937',
    midPurple: '#a21882',
  },
};
