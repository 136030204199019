import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import addEcdEvent from '../../../../actions/ecd/addEvent';
import { CustomUtilLink } from './Link';

@connect(null, dispatch =>
  bindActionCreators(
    {
      addEcdEvent,
    },
    dispatch
  )
)
export default class CPPContactLink extends React.Component {
  static propTypes = {
    addEcdEvent: PropTypes.func.isRequired,
    baseUrl: PropTypes.string,
  };

  static defaultProps = {
    baseUrl: '/',
  };

  render() {
    return (
      <CustomUtilLink
        href={`${this.props.baseUrl}/support`}
        key="contact"
        onClick={() => {
          Analytics.publish(
            InteractionInputEvent.fromObject({
              component: 'id_header_contact',
              action: 'click',
              status: 'success',
            })
          );
        }}
        {...setAutomationElement('headerContact')}
      >
        Contact & FAQ
      </CustomUtilLink>
    );
  }
}
