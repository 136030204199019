import pickBy from 'lodash/pickBy';
import memoize from 'lodash/memoize';

import isServer from '../../../helpers/isServer';

const selectEnv = env => pickBy(env, (val, key) => key.startsWith('REACT_APP_'));

export default memoize(() => {
  const isJestRunner = !!process.env.JEST_WORKER_ID;

  if (isServer() || isJestRunner) {
    // If this is a server side render or we're running the unit tests
    return selectEnv(process.env);
  }

  // cypress
  if (typeof process !== 'undefined' && process && !process.browser) {
    return selectEnv(process.env);
  }

  return window._ENV_ || selectEnv(process.env) || {}; // eslint-disable-line no-underscore-dangle
});
