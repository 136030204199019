import {
  FORMAT_ADDRESS,
  FORMAT_ADDRESS_SUCCESSFUL,
  FORMAT_ADDRESS_UNSUCCESSFUL,
} from '../actions/address/format';
import {
  LOOKUP_ADDRESS,
  LOOKUP_ADDRESS_SUCCESSFUL,
  LOOKUP_ADDRESS_UNSUCCESSFUL,
} from '../actions/address/lookup';
import {
  type as ACK_API_ERROR,
} from '../actions/address/acknowledgeApiError';
import { WMDATA_ADD_FACT_SUCCESSFUL } from '../actions/monitoring/addFact';
import { errorMessages } from '../constants';

export const defaultState = {
  lookedupAddresses: [],
  fetching: false,
  formattedAddress: {},
  error: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FORMAT_ADDRESS:
      return {
        ...state,
        fetching: true,
      };
    case FORMAT_ADDRESS_SUCCESSFUL:
      return {
        ...state,
        fetching: false,
        error: null,
        formattedAddress: action.payload,
      };
    case FORMAT_ADDRESS_UNSUCCESSFUL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case LOOKUP_ADDRESS:
      return {
        ...state,
        fetching: true,
      };
    case LOOKUP_ADDRESS_SUCCESSFUL:
      return {
        ...state,
        fetching: false,
        error: action.payload.length > 0 ? null : errorMessages.addresses.noResults,
        lookedupAddresses: action.payload,
      };
    case LOOKUP_ADDRESS_UNSUCCESSFUL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case WMDATA_ADD_FACT_SUCCESSFUL:
      return {
        ...defaultState,
      };
    case ACK_API_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
