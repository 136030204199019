import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Copy,
  Container,
} from './index.styles';

import ActionPoint from '../actions';

const keepSafeSections = [
  {
    key: '1',
    title: '1. Use a unique password for every account on the web.',
    text: 'Use a password manager to help you generate unique passwords and store them safely.',
  },
  {
    key: '2',
    title: '2. Be wary of any emails, phone calls or text messages you receive.',
    text: 'This is the most common way of extracting your personal information.',
  },
  {
    key: '3',
    title: '3. If you receive an email from your bank, don\'t click on any links.',
    text: 'Instead, always login into your account the way you usually do.',
  },
];

const KeepSafePanel = ({ hasCredentials }) => (
  <Container>
    <Copy>{hasCredentials ? 'Other ways' : 'Ways'} to keep safe</Copy>
    <Fragment>
      {
        keepSafeSections.map(sect => (<ActionPoint key={sect.key} sect={sect} />))
      }
    </Fragment>
  </Container>
);

KeepSafePanel.propTypes = {
  hasCredentials: PropTypes.bool.isRequired,
};

export default KeepSafePanel;
