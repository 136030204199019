import React from 'react';
import PropTypes from 'prop-types';
import CppHeader from '../CppHeader';
import Header from '../Header';

const NavigationWrapper = ({ isCpp }) => {
  // CPP users do not get the Nebula nav, and instead get the older nav from corvetteuk-common-ui
  if (isCpp) {
    return <CppHeader />;
  }

  return <Header />;
};

NavigationWrapper.propTypes = {
  isCpp: PropTypes.bool.isRequired,
};

export default NavigationWrapper;
