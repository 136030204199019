import fetch from '../fetch';
import initialize from '../ecd/initialize';

export const CPP_ELIGIBILITY_FETCHED = 'CPP_ELIGIBILITY_FETCHED';
export const FETCH_CPP_ELIGIBILITY = 'FETCH_CPP_ELIGIBILITY';

export default () => async (dispatch) => {
  dispatch({ type: FETCH_CPP_ELIGIBILITY });

  try {
    const customer = await dispatch(fetch('/cppeligibility'));
    dispatch({
      type: CPP_ELIGIBILITY_FETCHED,
      payload: {
        policyNumber: customer.data.policyNumber,
      },
    });

    return dispatch(initialize());
  } catch (e) {
    return dispatch({
      type: CPP_ELIGIBILITY_FETCHED,
      payload: new Error('Error fetching CPP data'),
      error: true,
    });
  }
};
