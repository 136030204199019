import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement, Typography } from '@experian-uk/corvetteuk-common-ui';
import { pleaseNoteExternalLink } from '../../constants/alertActions';

const { Copy } = Typography;

const ExternalLinkWarning = ({ section }) => (
  <Copy.SmallText {...setAutomationElement(`external-link-warning-${section}`)}>
    {pleaseNoteExternalLink}
  </Copy.SmallText>
);

ExternalLinkWarning.propTypes = {
  section: PropTypes.string,
};

ExternalLinkWarning.defaultProps = {
  section: 'section',
};

export default ExternalLinkWarning;
