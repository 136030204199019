import { FETCH_BOOST, BOOST_FETCHED } from '../actions/boost/getStatus';

export const defaultState = {
  fetching: false,
  data: null,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case FETCH_BOOST:
      return {
        ...defaultState,
        fetching: true,
      };
    case BOOST_FETCHED:
      return {
        ...defaultState,
        data: action.payload,
      };
    default:
      return state;
  }
};
