import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import fraudAlertsSVG from './assets/fraud-alerts.svg';
import fraudReportSVG from './assets/fraud-report.svg';
import fraudSupportSVG from './assets/fraud-support.svg';
import webMonitoringSVG from './assets/web-monitoring.svg';
import creditLockSVG from './assets/credit-lock.svg';

const { Copy, Headings } = Typography;

const iconMap = {
  fraudAlerts: fraudAlertsSVG,
  fraudReport: fraudReportSVG,
  fraudSupport: fraudSupportSVG,
  webMonitoring: webMonitoringSVG,
  creditLock: creditLockSVG,
};

export const BenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.base.expSpacingXSmall} 0;
  text-align: center;

  ${({ theme }) => theme.media('20', `
    padding: ${theme.base.expSpacingSmall};
  `)}

  &:before {
    content: url(${({ iconKey }) => iconMap[iconKey]});

    ${({ theme }) => theme.media('20', `
      height: 60px;
    `)}
  }
`;

export const Paragraph = styled(Copy.Text)`
  font-size: ${({ theme }) => theme.typography.smallerFontSize};
  font-weight: 400;
  margin: 0;

  ${({ theme }) => theme.media('20', `
    font-size: ${theme.typography.smallFontSize};
  `)}
`;

export const Heading = styled(Headings.Secondary)`
  ${({ theme }) => `
    color: ${theme.colors.experianDarkGrey};
    font-weight: 300;
    margin: 0.625rem 0;
  `}

  ${({ theme }) => theme.media('20', `
    margin: ${theme.base.expSpacingXSmall} 0 1.5625rem;
    font-size: ${theme.typography.baselineHeight};
    line-height: 1.42;
  `)}
`;
