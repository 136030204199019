import { getEnv } from '../components/Context/env';

export const FETCH = 'FETCH';

export default function fetch(
  url,
  body = {},
  isRemote = false,
  overrideToken,
  nonCritical = false,
) {
  const env = getEnv();
  const anonymous = overrideToken === 'anonymous';
  const fetchUrl = isRemote ? url : `${env.REACT_APP_GATEWAY_URL}${url}`;

  return {
    type: FETCH,
    payload: {
      url: fetchUrl,
      body,
    },
    meta: {
      isRemote,
      overrideToken,
      nonCritical,
      anonymous,
    },
  };
}
