import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';


import {
  SecurityText,
} from './index.styles';

import Exclamation from './assets/Error.svg';
import Next from './assets/Next.svg';
import Tick from './assets/Validation.svg';

const RiskPanel = ({ selected }) => (
  <Fragment>
    {(selected === false) && (
      <Fragment>
        <SecurityText><SVG src={Tick} />You&rsquo;re at a low risk of fraud.</SecurityText>
        <SecurityText><SVG src={Next} />Remain vigilant. Look out for any strange activity in your email inbox. For example, requests to change your password that you didn&rsquo;t make yourself.</SecurityText>
      </Fragment>
    )}
    {(selected === true) && (
      <Fragment>
        <SecurityText><SVG src={Exclamation} />You&rsquo;re at a high risk of becoming a victim of fraud.</SecurityText>
        <SecurityText><SVG src={Next} />Immediately change this password in all the online accounts you use it for.</SecurityText>
      </Fragment>
    )}
    {(selected === null) && (
      <Fragment>
        <SecurityText><SVG src={Tick} />You&rsquo;re at a low risk of fraud.</SecurityText>
        <SecurityText><SVG src={Next} />There&rsquo;s nothing you have to do now.</SecurityText>
      </Fragment>
    )}
  </Fragment>
);

RiskPanel.propTypes = {
  selected: PropTypes.bool,
};

RiskPanel.defaultProps = {
  selected: null,
};

export default RiskPanel;
