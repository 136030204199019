import styled from 'styled-components';
import { Button } from '@experian-uk/corvetteuk-common-ui';
import UserIconSvg from './user.svg';

export const StyledButton = styled(Button)`
  margin: 0;
  background-color: ${props => props.theme.colors.experianDarkBlue};
  border: 1px solid ${props => props.theme.colors.experianDarkBlue};

  ${({ theme }) => theme.mediaEnd('10', `
      min-width: auto;
      border-radius: unset;
      padding: 0.85rem 1.7rem;
  `)};

  ${({ theme }) => theme.media('10', `
      height: 50px;
  `)};

  span {
    display: block;
    ${({ theme }) => theme.mediaEnd('10', `
      font-size: ${theme.typography.smallerFontSize};
      line-height: ${theme.typography.baseFontSize};
    `)};
    &:before {
      content: url(${UserIconSvg});
      display: block;
      margin: 0 auto;
      vertical-align: bottom;
      width: 24px;
      height: 30px;

      ${({ theme }) => theme.media('10', `
        display: none;
      `)}
    }
`;
