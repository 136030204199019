export default c2Url => [
  {
    id: 'header-credit-cards',
    automationKey: 'headerCreditCards',
    label: 'Credit Cards',
    href: `${c2Url}/credit-cards`,
  },
  {
    id: 'header-loans',
    automationKey: 'headerLoans',
    label: 'Loans',
    href: `${c2Url}/loans`,
  },
  {
    id: 'header-mortgages',
    automationKey: 'headerMortgages',
    label: 'Mortgages',
    href: `${c2Url}/mortgages`,
  },
];
