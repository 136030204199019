import Joi from 'joi';

import { FETCH } from './fetch';
import { services } from '../constants';

const serviceSchema = Joi.object({
  service: Joi.string().valid(...Object.values(services)).required(),
  endpoint: Joi.string().pattern(/^\/.*/).required(),
  query: Joi.object().allow(null),
});

export default (service, meta = {}) => {
  const valRes = serviceSchema.validate(service);

  if (valRes.error) {
    throw new Error(valRes.error);
  }

  return {
    type: FETCH,
    payload: {
      body: {
        service,
      },
    },
    meta,
  };
};
