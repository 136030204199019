import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import { Container } from './index.styles';

import PasswordRecognisedPage from './passwordRecognisedPage';
import PasswordNotRecognisedPage from './passwordNotRecognisedPage';
import NoPasswordPage from './noPasswordPage';
import ButtonPanel from './buttonPanel';

import addEvent from '../../actions/ecd/addEvent';

@connect(
  state => ({
    page: state.router.location.pathname,
  }),
  dispatch => bindActionCreators({ addEvent }, dispatch)
)
export default class EmailPassword extends React.Component {
  static propTypes = {
    alertValue: PropTypes.shape({
      email: PropTypes.string.isRequired,
      credentials: PropTypes.string,
    }).isRequired,
  };

  state = {
    selected: null,
    initialized: false,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    if (!this.state.initialized) {
      this.init();
    }
  }

  init = () => {
    const hasCredentials = 'credentials' in this.props.alertValue;
    const compromisedPasswordLabel = hasCredentials ? '1' : '0';

    Analytics.publish(
      InteractionInputEvent.fromObject({
        component: `id_customer_feedback_compromised_password_${compromisedPasswordLabel}`,
        action: 'click',
        status: 'success',
      })
    );

    this.setState(state => ({
      ...state,
      initialized: true,
    }));
  };

  createOnSelect = state => () => {
    const { selected } = this.state;

    if (selected) {
      return null;
    }

    Analytics.publish(
      InteractionInputEvent.fromObject({
        component: `id_customer_feedback_accurate_compromised_password_${state ? '1' : '0'}`,
        action: 'click',
        status: 'success',
      })
    );

    return this.setState({ selected: state });
  };

  render() {
    const { selected } = this.state;

    const hasCredentials = 'credentials' in this.props.alertValue;

    const noPasswordPage = [!hasCredentials];
    const passwordDecisionPage = [hasCredentials, selected === null];
    const passwordNotRecognisedPage = [hasCredentials, selected === false];
    const passwordRecognisedPage = [hasCredentials, selected === true];

    const decider = new Map([
      [noPasswordPage, () => <NoPasswordPage />],
      [passwordNotRecognisedPage, () => <PasswordNotRecognisedPage />],
      [passwordRecognisedPage, () => <PasswordRecognisedPage />],
      [passwordDecisionPage, () => <ButtonPanel onChange={this.createOnSelect} />],
    ]);
    const possibleOutcomes = decider.entries();
    let finalComponent;

    // eslint-disable-next-line no-restricted-syntax
    for (const [condition, component] of possibleOutcomes) {
      /* eslint-disable-line no-restricted-syntax */
      if (condition.every(x => x)) {
        finalComponent = component;
        break;
      }
    }

    return <Container>{finalComponent()}</Container>;
  }
}
