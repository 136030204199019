import fetch from '../fetch';
import initialize from '../ecd/initialize';

export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const SUBSCRIPTIONS_FETCHED = 'SUBSCRIPTIONS_FETCHED';
export const SUBSCRIPTIONS_FETCH_FAILED = 'SUBSCRIPTIONS_FETCH_FAILED';

export default () => async (dispatch) => {
  dispatch({ type: FETCH_SUBSCRIPTIONS });

  try {
    const subscriptions = await dispatch(fetch('/subscriptions',
      {},
      false,
      null,
      true));

    if (subscriptions.data.every(s => !s.active)) {
      throw new Error('No active subscriptions.');
    }

    const payload = subscriptions.data[0];

    dispatch({
      type: SUBSCRIPTIONS_FETCHED,
      payload,
    });
    return dispatch(initialize());
  } catch (e) {
    return dispatch({
      type: SUBSCRIPTIONS_FETCH_FAILED,
      payload: 'Error in fetch from Subscription service',
    });
  }
};
