import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SVG from 'react-inlinesvg';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import addEvent from '../../actions/ecd/addEvent';

import { Copy, SelectionButton, Actions, Container } from './index.styles';
import Tick from './tick.svg';
import Cross from './cross.svg';

@connect(
  state => ({
    page: state.router.location.pathname,
  }),
  dispatch => bindActionCreators({ addEvent }, dispatch)
)
export default class CustomerFeedback extends React.Component {
  static propTypes = {
    addEvent: PropTypes.func.isRequired,
    page: PropTypes.string.isRequired,
    alertType: PropTypes.string.isRequired,
  };

  state = {
    selected: null,
  };

  createOnSelect = state => () => {
    const { selected } = this.state;
    const { alertType } = this.props;
    const stringMap = {
      yes: '1',
      no: '0',
    };

    if (selected) {
      return null;
    }

    Analytics.publish(
      InteractionInputEvent.fromObject({
        component: `id_customer_feedback_${alertType}_${stringMap[state]}`,
        action: 'click',
        status: 'success',
      })
    );

    return this.setState({ selected: state });
  };

  render() {
    const { selected } = this.state;

    return (
      <Container>
        <Copy>Was this information helpful?</Copy>
        <Actions>
          <SelectionButton
            label=""
            isSelected={selected === 'yes'}
            onClick={this.createOnSelect('yes')}
            disabled={selected === 'no'}
            {...setAutomationElement('feedback-thumbs-up')}
          >
            <SVG src={Tick} />
          </SelectionButton>
          <SelectionButton
            label=""
            isSelected={selected === 'no'}
            onClick={this.createOnSelect('no')}
            disabled={selected === 'yes'}
            {...setAutomationElement('feedback-thumbs-down')}
          >
            <SVG src={Cross} />
          </SelectionButton>
        </Actions>
      </Container>
    );
  }
}
