import { splitsForNav } from '@nebula/navigation';

export default {
  ...splitsForNav,
  boostOnline: 'expn-boost-mstr',
  boostPlacement: 'boost-placements-tests',
  breachPlus: 'BreachPlus',
  creditLock: 'CreditLock', // TODO: Raise spike to determine whether 'creditLock' split is still required
  creditLockWeb: 'cl-access-web',
  covidResponse: 'contact_update_for_covid19',
  scoreComponents: 'CEoC_ScoreComponents',
  scoreHistory: 'CEoC_ScoreHistory',
  loginSuccessEvents: 'identity-login-sucess',
  showRecycleBanner: 'recycleProcess',
  creditLockChurnTest: 'cl-report-overview-modal-shown-web',
  showFullCreditReportIDPlus: 'ceoc-full-report-id-plus',
  showFullCreditReportBreachPlus: 'ceoc-full-report-breach-plus',
  showFullCreditReportBreach: 'ceoc-full-report-breach',
  identityStoppedApplicationAlert: 'identity_stopped_application_alert',
};
