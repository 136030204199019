export default {
  monthlyCreditScore: 'B002',
  weeklyCreditAlerts: 'B003',
  weeklyIdentityAlerts: 'B005',
  creditReport: 'B010',
  scoreFactors: 'B011',
  dailyCreditScore: 'B012',
  webMonitoring: 'B019',
  fraudReport: 'B020',
  scoreImprovement: 'B021',
  scoreSimulations: 'B026',
  creditLock: 'B028',
};
