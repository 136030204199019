import { baseTheme } from '@experian-uk/corvetteuk-common-ui';
import { customTheme } from '../constants';

const mergeableKeys = ['colors'];

export default () => {
  const mergedKeys = Object.keys(baseTheme).reduce((newObject, themeKey) => {
    const mergedKey = newObject;
    if (mergeableKeys.includes(themeKey)) {
      mergedKey[themeKey] = {
        ...baseTheme[themeKey],
        ...customTheme[themeKey],
      };
    }
    return mergedKey;
  }, {});

  return {
    ...baseTheme,
    ...customTheme,
    ...mergedKeys,
  };
};
