import fetch from '../fetch';
import scoresEndpoint from './baseEndpoint';

export const SCORE_FETCHED = 'SCORE_DATA_FETCHED';
export const FETCH_SCORE = 'FETCH_SCORE';
export const SCORE_NO_BENEFIT = 'SCORE_NO_BENEFIT';

export default () => async dispatch => {
  const handleError = () =>
    dispatch({
      type: SCORE_FETCHED,
      payload: {
        error: true,
      },
    });

  dispatch({
    type: FETCH_SCORE,
  });

  try {
    const scoreResponse = await dispatch(fetch(`${scoresEndpoint}`, {}, false));

    const scores = scoreResponse.data;
    if (!scores || scores.length === 0) {
      return handleError();
    }

    const scoreValue = scores?.[0]?.score ?? null;
    const previousScoreValue = scores?.[1]?.score ?? null;
    const boostUplift = scores?.[0]?.uplift ?? null;

    return dispatch({
      type: SCORE_FETCHED,
      payload: {
        scoreValue,
        previousScoreValue,
        boostUplift,
      },
    });
  } catch (e) {
    return handleError();
  }
};
