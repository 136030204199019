import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import {
  Container,
  CopyText,
  SecondaryHeading,
  TertiaryHeading,
} from './index.styles';
import InlineLink from '../../components/InlineLink';
import { EnvConsumer } from '../Context/env';

const HelpSupport = ({ subscriptionName }) => (
  <Container {...setAutomationElement('helpSupport')}>
    <TertiaryHeading>Still have questions?</TertiaryHeading>
    <SecondaryHeading>Get help and support</SecondaryHeading>
    <EnvConsumer>
      {
        context => (
          <CopyText>
            We want you to get the most out of your Experian {subscriptionName} subscription. Please use our <InlineLink {...setAutomationElement('contactUsCTA')} href={`${context.REACT_APP_INS_URL}/contact`}>contact us</InlineLink> page if you have any questions.
          </CopyText>
        )
      }
    </EnvConsumer>
  </Container>
);

HelpSupport.propTypes = {
  subscriptionName: PropTypes.string,
};

HelpSupport.defaultProps = {
  subscriptionName: '',
};

export default HelpSupport;
