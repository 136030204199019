import styled, { css } from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

export const Copy = styled(Typography.Copy.Text)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  font-weight: 500;

  ${({ theme }) => theme.media('15', css`
    display: inline;
    margin-right: 1.25rem;
  `)}
`;

export const SecuritySectionOuter = styled.section`
  background: ${({ theme }) => theme.colors.baseBackgroundBlue};
  padding: 1.875rem 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export const SecuritySectionInner = styled.section`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.baseWhite};
  max-width: 90%;
  margin: 0 auto;

  ${({ theme: { media } }) => media('30', `
    max-width: 1140px;
  `)}

`;
