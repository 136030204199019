import React from 'react';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { EnvConsumer } from '../Context/env';

import {
  StyledLink,
  Container,
  Content,
  Heading,
} from './index.styles';

export default () => (
  <Container {...setAutomationElement('creditLockBanner')}>
    <Heading>Is your Experian Credit Report protected?</Heading>
    <Content>
      Simply adding a 5-digit key onto your credit file can help stop fraudsters in their tracks.
    </Content>
    <EnvConsumer>
      {
        context => (
          <StyledLink {...setAutomationElement('goToPnocWebform')} href={`${context.REACT_APP_INS_URL}/contact?selectedArticle=pnoc`}>Find out more</StyledLink>
        )
      }
    </EnvConsumer>
  </Container>
);
