import styled from 'styled-components';
import { BaseCard } from '@nebula/cards';

export const PersonalDetailsCard = styled(BaseCard)`
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    width: 50%;
  }
`;

export const PersonalDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

export const PersonalDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  order: 0;
  flex-grow: 0;
  align-items: flex-start;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  word-break: normal;
  overflow-wrap: anywhere;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.color.ui.blueGrey100};
    padding-bottom: 8px;
  }

  &:not(:first-of-type) {
    margin: 8px 0px 0px 0px;
  }
`;
