import React from 'react';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { TitleMedium700 } from '@nebula/global-styles';
import Button from '@nebula/button';
import {
  CreditLockDetailWrapper,
  CreditLockIcon,
  CreditLockDetailsBox,
  CreditLockContentBox,
  ButtonContainer,
} from './index.styles';

const CreditLockCard = ({
  creditLockButtonHref,
  selectedOption2,
  renderOption2Test,
  creditLockButtonKind,
  sendFeedback,
  creditLockHeader,
  creditLockSubtext,
  creditLockButtonText,
  pushGoToCreditLockECD2Event,
}) => (
  <CreditLockDetailWrapper {...setAutomationElement('creditLock-module')}>
    <CreditLockIcon />
    <CreditLockDetailsBox>
      <TitleMedium700 {...setAutomationElement('creditLock-header')}>{creditLockHeader}</TitleMedium700>
      <CreditLockContentBox>
        <p {...setAutomationElement('creditLock-subheader')}>{creditLockSubtext}</p>
      </CreditLockContentBox>
    </CreditLockDetailsBox>
    <Button
      {...setAutomationElement('creditLock-button')}
      label={creditLockButtonText}
      onClick={pushGoToCreditLockECD2Event}
      isLink
      href={creditLockButtonHref}
      buttonKind={renderOption2Test ? 'secondaryBlue' : creditLockButtonKind}
    />
    {renderOption2Test && !selectedOption2 && (
      <ButtonContainer>
        <Button
          {...setAutomationElement('Option2Test-button')}
          label="I do not recognise this application"
          onClick={() => sendFeedback()}
          isLink
          buttonKind="secondaryBlue"
        />
      </ButtonContainer>
    )}
  </CreditLockDetailWrapper>
);

CreditLockCard.propTypes = {
  creditLockButtonHref: PropTypes.string.isRequired,
  creditLockButtonKind: PropTypes.string.isRequired,
  creditLockHeader: PropTypes.string.isRequired,
  creditLockSubtext: PropTypes.string.isRequired,
  creditLockButtonText: PropTypes.string.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  pushGoToCreditLockECD2Event: PropTypes.func.isRequired,
  selectedOption2: PropTypes.bool,
  renderOption2Test: PropTypes.bool,
};

CreditLockCard.defaultProps = {
  renderOption2Test: false,
  selectedOption2: false,
};

export default CreditLockCard;
