import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  height: 26px;
  min-width: 27px;
  width: 27px;

  .tick {
    fill: none;
    stroke: ${({ theme }) => theme.colors.experianDarkerGreen};
    stroke-width: 2;
    stroke-linecap: round;
    ${({ shouldShowAnimation }) => shouldShowAnimation && 'stroke-dasharray: 25;'}
    stroke-dashoffset: 25;
    animation: draw 2s infinite ease;
  }
`;

const SuccessAnimation = ({ ariaLabel }) => {
  const { userAgent } = (window && window.navigator) ? window.navigator : {};
  const isEdge = userAgent.indexOf('Edge') > -1;
  const isIE = (document && !!document.documentMode);
  return (
    <StyledSVG
      aria-label={ariaLabel}
      height="26"
      role="img"
      shouldShowAnimation={(!isEdge && !isIE)}
      viewBox="0 0 20 20"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="tick" d="M1.3,7.6 l3.3,6.3 l12.6,-10.3" />
    </StyledSVG>
  );
};

SuccessAnimation.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
};

export default SuccessAnimation;
