import get from 'lodash/get';
import printf from 'printf';

import { errorMessages } from '../constants';

const errorIntro = 'Could not interpolate string';

export const interpolationErrors = {
  missingArgs: `${errorIntro}, arguments missing`,
};

export default (messageKey, args = [], messages = errorMessages) => {
  let message = get(messages, messageKey, undefined);
  message = typeof message === 'string' ? message : undefined;

  const count = ((message || '').match(/%s/g) || []).length;

  if ((args || []).length < count) {
    throw new Error(interpolationErrors.missingArgs);
  }

  if (!message) return message;

  return printf(message, ...(args || []).map(argument => `${argument}`));
};
