import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import footerLinks from './links';
import { StyledFooter, SmallText } from './index.styles';
import { withEnv, envPropTypes } from '../../Context/env';
import fixedUrls from '../../../constants/fixedUrls';
import { familyName, products } from '../../../constants';
import addEvent from '../../../actions/ecd/addEvent';

const breachContent = (
  <SmallText>
    Due to the sensitive nature of the information and data within this site, please close your browser whenever you
    wish to leave Experian.
  </SmallText>
);
const CPPContent = (
  <SmallText>
    All Experian consumer services are provided by Experian Ltd (registered number 653331). Experian Ltd is authorised
    and regulated by the Financial Conduct Authority (firm reference number 738097). Experian Ltd is registered in
    England and Wales with registered office at The Sir John Peace Building, Experian Way, NG2 Business Park,
    Nottingham, NG80 1ZZ.
    <br />
    <br />
    The web monitoring feature and its alerts within Identity Plus is not Financial Conduct Authority regulated
    activity.
    <br />
    <br />
    Copyright &copy;{new Date().getFullYear()}, Experian Ltd. All rights reserved.
  </SmallText>
);

// These prices ideally wouldn't be hard coded, but we've no other reason to call /billingsubscriptions or add these to the PI Service so it seems like the lesser evil for the time being...
const prices = {
  credit_expert: 14.99,
  identity: 10.99,
};

@withEnv
@connect(
  state => ({
    family: state.subscriptions.current && state.subscriptions.current.family,
    isBreach: state.productDetails.isBreach,
    isCpp: state.productDetails.isCpp,
    isFreeTrial: state.productDetails.isFreeTrial,
  }),
  dispatch =>
    bindActionCreators(
      {
        addEvent,
      },
      dispatch
    )
)
export default class IdentityFooter extends React.Component {
  static propTypes = {
    env: envPropTypes.isRequired,
    family: PropTypes.string,
    isBreach: PropTypes.bool,
    isCpp: PropTypes.bool,
    isFreeTrial: PropTypes.bool,
  };

  static defaultProps = {
    family: '',
    isBreach: false,
    isCpp: false,
    isFreeTrial: false,
  };

  onLinkClick = (href, e) => {
    const { isCpp } = this.props;
    if (href.includes('/support') && isCpp) {
      e.preventDefault();

      Analytics.publish(
        InteractionInputEvent.fromObject({
          component: 'id_footer_contact',
          action: 'click',
          status: 'success',
        })
      );

      window.location = href;
    }
  };

  render() {
    const { family, isBreach, isCpp, isFreeTrial, env } = this.props;

    const urls = {
      contactUsUrl: isCpp ? `${env.REACT_APP_CPP_URL}/support` : `${env.REACT_APP_INS_URL}/contact`,
      cookiesUrl: isCpp ? `${env.REACT_APP_CPP_URL}/cookies` : `${fixedUrls.experian}/consumer/cookie-policy.html`,
      statementOfRightsUrl: isCpp
        ? `${env.REACT_APP_CPP_URL}/statement-of-rights`
        : `${env.REACT_APP_INS_URL}/statement-of-rights`,
      privacyUrl: isCpp
        ? `${env.REACT_APP_CPP_URL}/documents/privacypolicy.pdf`
        : `${fixedUrls.experian}/consumer/privacy.html`,
      termsConditionsUrl: isCpp
        ? `${env.REACT_APP_CPP_URL}/documents/termsandconditions.pdf`
        : `${fixedUrls.experian}/consumer/terms.html`,
      experianUrl: fixedUrls.experian,
    };

    const links = footerLinks(family, urls);

    const shouldShowBreachContent = !family || isBreach;

    return (
      <StyledFooter
        renderSplitContent={!isCpp}
        fullPanelContent={isCpp ? CPPContent : null}
        leftPanelContent={shouldShowBreachContent ? breachContent : null}
        footerLinks={links}
        homeUrl={family === products.credit_expert || isFreeTrial ? env.REACT_APP_INS_URL : '/'}
        insUrl={env.REACT_APP_INS_URL}
        experianUrl={env.REACT_APP_EXPERIAN_URL}
        product={familyName[family]}
        productPrice={prices[family]}
        trialProduct={family === products.identity ? familyName.credit_expert : ''}
        onLinkClick={this.onLinkClick}
        openLinksInNewTab
      />
    );
  }
}
