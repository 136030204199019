import React from 'react';
import PropTypes from 'prop-types';

const CrossIcon = ({ color }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93 17.657a1 1 0 101.414 1.414L12 13.414l5.656 5.657a1 1 0 001.415-1.414L13.415 12l5.657-5.657a1 1 0 10-1.415-1.414l-5.656 5.657-5.657-5.657A1 1 0 104.93 6.343L10.586 12 4.93 17.657z"
      fill={color}
    />
  </svg>
);

CrossIcon.propTypes = {
  color: PropTypes.string,
};

CrossIcon.defaultProps = {
  color: 'currentColor',
};

export default CrossIcon;
