import deepmerge from 'deepmerge';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';

import packageData from '../../package.json';
import { FETCH } from '../actions/fetch';
import setUser from '../actions/auth/setUser';
import { getEnv } from '../components/Context/env';
import { isServer } from '../helpers';
import { brandIdentifiers, fixedUrls } from '../constants';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-brand': 'creditmatcher',
  'x-api-version': '3.0.0',
  'x-clientid': 'web_identityplus',
};

export default store => next => (action) => {
  if (action.type !== FETCH) return next(action);

  const env = getEnv();
  const { url, body = {} } = action.payload;
  const { isRemote, overrideToken, anonymous } = action.meta;
  const { auth } = store.getState();

  const baseHeaders = isRemote ? {
    'Content-Type': 'application/json',
  } : defaultHeaders;
  const headers = {
    ...baseHeaders,
    ...body.headers || {},
    'CSRF-Token': auth.csrfToken,
  };

  const payload = deepmerge({}, body);
  const token = anonymous ? null : (overrideToken || auth.token);

  if (!Object.keys(headers).includes('Authorization') && !isRemote && token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const brandIdentifierCookie = Cookies.get('brandIdentifier');

  payload.headers = {
    ...headers,
    'x-brand': brandIdentifierCookie || headers['x-brand'],
  };

  const proxyUrlDisplay = body.service ? `${env.REACT_APP_GATEWAY_URL}/${body.service.service}${body.service.endpoint}` : url;

  let fetchUrl = `/internal/proxy?for=${proxyUrlDisplay}&method=${(typeof payload.method === 'undefined' ? 'GET' : payload.method)}`;

  if (auth.mockData) {
    fetchUrl = body.service ? `/internal/mock${proxyUrlDisplay}` : `/internal${proxyUrlDisplay}`;
  }

  return fetch(fetchUrl, {
    method: 'POST',
    headers: new Headers({ ...defaultHeaders, 'CSRF-Token': auth.csrfToken }),
    body: JSON.stringify({
      requestedUrl: url,
      appName: packageData.name,
      requestTime: new Date(),
      sessionId: auth.sessionId,
      anonymous,
      ...payload,
    }),
  }).then((res) => {
    next(action);

    if (!res.ok) {
      if (res.status === 403) {
        if (env.REACT_APP_ENV === 'local') {
          Cookies.remove('authId');
        }
        store.dispatch(setUser());
        if (!isServer() && window.location) {
          const authUrl = (brandIdentifierCookie === brandIdentifiers.cpp) ? fixedUrls.cppAuth : env.REACT_APP_AUTH_URL;
          window.location = authUrl;
        }

        return Promise.reject();
      }

      if (res.status >= 500 && !action.meta.nonCritical) {
        store.dispatch(push('/error'));

        return Promise.reject();
      }

      throw res;
    }

    if (res.status === 204) {
      return { status: 204 };
    }

    return res.json();
  });
};
