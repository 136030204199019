import React, { Fragment } from 'react';

export const mapImgKeys = {
  phoneSupport: 'phoneSupport',
};

export default {
  freeTrial: [
    'Daily Experian Credit Score',
    'Daily Experian Credit Report',
    'Score history',
    'Score influencer',
    'Experian Credit Report Alerts',
    'UK-based support to discuss your report and score',
  ],
  identity: [
    {
      heading: <Fragment><span>Lock</span> your Experian Credit Report</Fragment>,
      paragraphs: [
        'Lock your Experian Credit Report, and help stop fraudsters taking out credit in your name.',
      ],
    },
    {
      heading: <Fragment>Daily Experian <span>Fraud Report</span></Fragment>,
      paragraphs: [
        'Your report is updated every day and details key information from your Experian Credit Report that may help you identify fraudulent activity.',
      ],
    },
    {
      heading: <Fragment>Experian Fraud <span>alerts</span></Fragment>,
      paragraphs: [
        'Choose to get alerts by email or text message about certain changes to your Experian Fraud Report.',
        'We send weekly alerts, tell you when an account is opened or closed, or if someone searches your credit report.',
      ],
    },
    {
      heading: <Fragment>Stay safe with <span>web monitoring</span></Fragment>,
      paragraphs: [
        'We monitor the internet, dark web and more to check your personal information isn’t being misused and let you know if we find anything suspicious.',
      ],
    },
    {
      backgroundImg: mapImgKeys.phoneSupport,
      heading: <Fragment>Enhanced <span>fraud support</span></Fragment>,
      paragraphs: [
        'If you fall victim to fraud we’ll assign you a dedicated case officer to help get you back on track.',
      ],
    },
  ],
  mapImgKeys,
};
