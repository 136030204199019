import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Link } from '@experian-uk/corvetteuk-common-ui';

const styles = props => `
  color: ${props.theme.colors.baseDarkGrey};
  cursor: pointer;
  font-size: ${props.theme.typography.smallerFontSize};
  font-weight: 500;
  padding: 0 1rem;
  text-decoration: none;
  &:hover, &:focus {
    color: ${props.theme.colors.experianDarkBlue};
    text-decoration: none;
  }
`;

export const CustomUtilLink = styled(Link.Anchor)`
  ${props => styles(props)}
`;

export const CustomUtilNavLink = styled(NavLink)`
  ${props => styles(props)}
`;
