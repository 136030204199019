import React, { Fragment } from 'react';
import SecurityPanel from '../security';
import TakeActionPanel from '../takeAction';
import KeepSafePanel from '../keepSafe';

const PasswordRecognisedPage = () => (
  <Fragment>
    <SecurityPanel selected />
    <TakeActionPanel />
    <KeepSafePanel hasCredentials />
  </Fragment>
);

export default PasswordRecognisedPage;
