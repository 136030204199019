import Events from './events';
import custNum from './custNum';
import descriptors from './descriptors';
import family from './family';
import isMigratedUser from './isMigratedUser';
import isPendingCancellation from './isPendingCancellation';
import nextBillDate from './nextBillDate';
import product from './product';
import Splits from './splits';
import subscription from './subscription';

export default {
  custNum,
  descriptors,
  Events,
  family,
  isMigratedUser,
  isPendingCancellation,
  nextBillDate,
  Splits,
  product,
  subscription,
};
