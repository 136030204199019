import fetch from '../fetch';

export const FETCH_WMDATA_FACTS = 'FETCH_WMDATA_FACTS';
export const WMDATA_FACTS_FETCHED = 'WMDATA_FACTS_FETCHED';

export default customerId => async (dispatch) => {
  if (!customerId) {
    return Promise.reject(new Error('CustomerID must be supplied'));
  }

  dispatch({ type: FETCH_WMDATA_FACTS });

  try {
    const facts = await dispatch(fetch(`/wmdata/customer/${customerId}/data`));

    return dispatch({
      type: WMDATA_FACTS_FETCHED,
      error: false,
      payload: facts,
    });
  } catch (e) {
    return dispatch({
      type: WMDATA_FACTS_FETCHED,
      error: true,
      payload: new Error('Error in fetch from wmdata service.'),
    });
  }
};
