import React from 'react';
import PropTypes from 'prop-types';
import { Typography, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import {
  ArticleContainer,
  Container,
  Img,
  ArticleLink,
} from './index.styles';

const { Copy, Headings } = Typography;

const Article = ({
  copy,
  img,
  link,
  title,
  attribute,
}) => (
  <ArticleContainer>
    {img && <Img src={img.src} alt={img.alt} />}
    <Container>
      <Headings.Tertiary>{title}</Headings.Tertiary>
      {copy.map((paragraph, index) => (
        <Copy.Text key={paragraph}>
          {paragraph}&nbsp;
          {(index === (copy.length - 1) && link) && (
            <ArticleLink {...setAutomationElement(attribute)} href={link} rel="noopener noreferrer" target="_blank">Continue reading</ArticleLink>
          )}
        </Copy.Text>
      ))}
    </Container>
  </ArticleContainer>
);

Article.propTypes = {
  copy: PropTypes.arrayOf(PropTypes.string),
  img: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  link: PropTypes.string,
  title: PropTypes.string,
  attribute: PropTypes.string,
};

Article.defaultProps = {
  copy: [],
  img: null,
  link: '',
  title: '',
  attribute: 'article',
};

export default Article;
