export default {
  addresses: {
    lookup: 'Sorry we could not retrieve the list of addresses',
    noResults: 'Sorry, we couldn’t find any addresses',
  },
  default: 'Sorry, something went wrong. Please try again later.',
  forms: {
    required: 'Please enter %s',
    format: 'Please enter a valid %s',
  },
  updateFailed: "We're experiencing temporary technical issues and could not save your data. Please try again later.",
  voucherValidation: {
    invalidVoucherCode: 'We don’t recognise this voucher. Please check and try again.',
    usedVoucherCode:
      'This voucher has already been used. Vouchers are unique and should only be used by the person they are sent to.',
    expiredVoucherCode: 'Your voucher has expired.',
    technicalError: 'We’re experiencing technical issues. Please try again later.',
  },
};
