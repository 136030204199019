import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

export const AlertContainer = styled.div`
  background: ${props => props.theme.colors.alertErrorTint};
  border: 1px solid ${props => props.theme.colors.experianRed};
  color: ${props => props.theme.colors.experianDarkGrey};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-bottom: 1.625rem;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  width: 100%;

  a, small {
    font-weight: 400;
  }

  a {
    margin: 1.25rem 0 0.5rem;
  }
`;

export const AlertHeading = styled(Typography.Headings.Tertiary)`
  color: ${props => props.theme.colors.experianDarkGrey};
  font-weight: 300;
`;

export const AlertMessage = styled(Typography.Copy.SmallText)`
  display: block;
  font-size: 1rem;
  font-weight: 300;
`;

export const InlineAlert = styled(Typography.Copy.SmallText)`
  color: ${props => props.theme.colors.alertError};
  display: block;
  font-weight: 500;
  padding: 0.5rem 0;
  ${({ theme }) => theme.media('20', `
    max-width: 500px;
  `)}
`;

export const ParagraphAlert = styled(InlineAlert)`
  display: block;
  padding-bottom: 0;
`;
