import fetch from '../fetch';
import alertsEndpoint from './baseEndpoint';

export const MARK_ALL_ALERTS_AS_READ = 'MARK_ALL_ALERTS_AS_READ';

export const ALL_ALERTS_MARKED_AS_READ = 'ALL_ALERTS_MARKED_AS_READ';

export default (error, loading, unreadAlerts) => async (dispatch) => {
  dispatch({
    type: MARK_ALL_ALERTS_AS_READ,
  });

  try {
    let response = {
      data: [
        'Alerts service not called',
      ],
    };

    if (!error && !loading && unreadAlerts > 0) {
      response = await dispatch(
        fetch(`${alertsEndpoint}/readAll`,
          { method: 'PUT' },
          false,
          null,
          true),
      );
    }

    const [success] = response.data;

    return await dispatch({
      type: ALL_ALERTS_MARKED_AS_READ,
      payload: success || null,
    });
  } catch (e) {
    return dispatch({
      type: ALL_ALERTS_MARKED_AS_READ,
      payload: new Error('Error marking all alerts as read'),
      failedUpdate: true,
    });
  }
};
