import { alertValues } from '../../../constants';

export default {
  creditUrgent: {
    account: {
      type: 'loan',
      company: 'The Bank',
    },
    address: '1 Experian Way, Nottingham',
    alertType: 'key1',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Urgent',
    code: 'code1',
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key1',
    detailUpdated: '1 January 2019',
    id: 'alert1',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: true,
    isUrgent: true,
    lastUpdatedOn: '2019-01-01T00:00:00',
    nameOnAlert: 'Test User',
    search: {
      applicationType: 'loan',
      searchedBy: 'The Bank',
      searchedOn: '1 January 2019',
      isLocked: false,
      isBlocked: false,
      searchType: 'Test Search Type',
      isLockExcluded: undefined,
    },
    startDate: '1 January 2019',
    type: alertValues.types.credit,
    whereFound: 'test.example.com',
    value: {
      testValue1: 'value1',
    },
    name: 'Test User',
    dob: '1 January 2019',
  },
  fraudRead: {
    account: {
      type: undefined,
      company: undefined,
    },
    address: '1 Experian Way, Nottingham',
    alertType: 'key2',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Serious',
    code: null,
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key2',
    detailUpdated: '1 January 2019',
    id: 'alert2',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: false,
    isUrgent: false,
    lastUpdatedOn: '2019-02-01T00:00:00',
    nameOnAlert: 'Test User',
    search: {
      applicationType: undefined,
      searchedBy: undefined,
      searchedOn: '1 January 2019',
      isLocked: false,
      isBlocked: false,
      searchType: 'Test Search Type',
      isLockExcluded: undefined,
    },
    startDate: '1 January 2019',
    type: alertValues.types.fraud,
    whereFound: 'test.example.com',
    value: {
      testValue2: 'value2',
    },
    name: 'Test User',
    dob: '1 January 2019',
  },
  creditNonUrgent: {
    account: {
      type: 'loan',
      company: 'The Bank',
    },
    address: '1 Experian Way, Nottingham',
    alertType: 'key3',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Serious',
    code: 'code2',
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key3',
    detailUpdated: '1 January 2019',
    id: 'alert3',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: true,
    isUrgent: false,
    lastUpdatedOn: '2019-01-01T00:00:00',
    nameOnAlert: 'Test User',
    search: {
      applicationType: 'loan',
      searchedBy: 'The Bank',
      searchedOn: '1 January 2019',
      isLocked: false,
      isBlocked: false,
      searchType: 'Test Search Type',
      isLockExcluded: undefined,
    },
    startDate: '1 January 2019',
    type: alertValues.types.credit,
    whereFound: 'test.example.com',
    value: {
      testValue3: 'value3',
    },
    name: 'Test User',
    dob: '1 January 2019',
  },
  fraudUrgent: {
    address: '1 Experian Way, Nottingham',
    alertType: 'key4',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Urgent',
    code: null,
    courtName: 'Test Court',
    createDate: '2022-11-08T00:00:00',
    descriptionKey: 'key4',
    detailUpdated: '8 November 2022',
    id: 'alert4',
    infoDate: '8 November 2022',
    infoType: 'testInfo',
    isUnread: true,
    isUrgent: true,
    lastUpdatedOn: '2022-11-08T12:00:00',
    nameOnAlert: 'Test User',
    startDate: '8 November 2022',
    type: alertValues.types.fraud,
    whereFound: 'test.example.com',
    value: {
      testValue4: 'value4',
    },
  },
  fraudNonUrgent: {
    address: '1 Experian Way, Nottingham',
    alertType: 'key5',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Serious',
    code: null,
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key5',
    detailUpdated: '1 January 2019',
    id: 'alert5',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: true,
    isUrgent: false,
    lastUpdatedOn: '2019-01-01T12:00:00',
    nameOnAlert: 'Test User',
    startDate: '1 January 2019',
    type: alertValues.types.fraud,
    whereFound: 'test.example.com',
    value: {
      testValue5: 'value5',
    },
  },
  fraudUrgent2: {
    address: '1 Experian Way, Nottingham',
    alertType: 'key6',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Urgent',
    code: null,
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key6',
    detailUpdated: '1 January 2019',
    id: 'alert6',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: true,
    isUrgent: true,
    lastUpdatedOn: '2019-01-02T00:00:00',
    nameOnAlert: 'Test User',
    startDate: '1 January 2019',
    type: alertValues.types.fraud,
    whereFound: 'test.example.com',
    value: {
      testValue6: 'value6',
    },
  },
  creditRead: {
    address: '1 Experian Way, Nottingham',
    alertType: 'key7',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Serious',
    code: 'code3',
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key7',
    detailUpdated: '1 January 2019',
    id: 'alert7',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: false,
    isUrgent: false,
    lastUpdatedOn: '2019-01-01T00:00:00',
    nameOnAlert: 'Test User',
    type: alertValues.types.credit,
    startDate: '1 January 2019',
    whereFound: 'test.example.com',
    value: {
      testValue7: 'value7',
    },
  },
  creditUrgent2: {
    address: '1 Experian Way, Nottingham',
    alertType: 'key8',
    amount: 0.00,
    caseNumber: 12345678,
    category: 'Urgent',
    code: 'code4',
    courtName: 'Test Court',
    createDate: '2019-01-01T00:00:00',
    descriptionKey: 'key8',
    detailUpdated: '1 January 2019',
    id: 'alert8',
    infoDate: '1 January 2019',
    infoType: 'testInfo',
    isUnread: true,
    isUrgent: true,
    lastUpdatedOn: '2019-01-02T12:00:00',
    nameOnAlert: 'Test User',
    type: alertValues.types.credit,
    startDate: '1 January 2019',
    whereFound: 'test.example.com',
    value: {
      testValue8: 'value8',
    },
  },
  instantAlertUnread: {
    alertType: 'CAPS',
    account: {
      company: null,
      type: null,
    },
    address:
      'BuildingName1, HouseNumber1, Flat1, HouseName1, Street1, Town1, District1, City1, County1 PostCode1',
    amount: null,
    caseNumber: null,
    category: 'Urgent',
    code: '100',
    courtName: null,
    createDate: '2022-10-17T13:31:56Z',
    descriptionKey: 'instant_alert',
    detailUpdated: 'Invalid date',
    dob: '01 Jan 2010',
    id: '1738284',
    infoDate: 'Invalid date',
    infoType: null,
    isUnread: true,
    isUrgent: true,
    lastUpdatedOn: '2022-10-17T13:31:56Z',
    name: 'MR FIRST FL LAST',
    nameOnAlert: 'MR FIRST FL LAST',
    search: {
      applicationType: '**Y2XZBN9Jy9MdVzX',
      searchedBy: '**EXPERIAN, FORMERLY CASTLIGHT',
      searchedOn: '14 July 2021',
      searchType: 'H',
      isLocked: true,
      isBlocked: true,
      isLockExcluded: false,
    },
    startDate: 'Invalid date',
    type: alertValues.types.fraud,
    value: {},
    whereFound: null,
  },
  instantAlertUnread2: {
    alertType: 'CAPS',
    account: {
      company: null,
      type: null,
    },
    address:
      'BuildingName1, HouseNumber1, Flat1, HouseName1, Street1, Town1, District1, City1, County1 PostCode1',
    amount: null,
    caseNumber: null,
    category: 'Urgent',
    code: '100',
    courtName: null,
    createDate: '2022-10-11T08:52:46Z',
    descriptionKey: 'instant_alert',
    detailUpdated: 'Invalid date',
    dob: '01 Jan 2010',
    id: '1733472',
    infoDate: 'Invalid date',
    infoType: null,
    isUnread: true,
    isUrgent: true,
    lastUpdatedOn: '2022-10-11T08:52:46Z',
    name: 'MR FIRST FL LAST',
    nameOnAlert: 'MR FIRST FL LAST',
    search: {
      applicationType: '**Credit Card Quotation',
      searchedBy: '**EXPERIAN, FORMERLY CASTLIGHT',
      searchedOn: '31 December 0000',
      searchType: 'S',
      isLocked: true,
      isBlocked: false,
      isLockExcluded: false,
    },
    startDate: 'Invalid date',
    type: alertValues.types.fraud,
    value: {},
    whereFound: null,
  },
  instantAlertRead: {
    alertType: 'CAPS',
    account: {
      company: null,
      type: null,
    },
    address:
      'BuildingName1, HouseNumber1, Flat1, HouseName1, Street1, Town1, District1, City1, County1 PostCode1',
    amount: null,
    caseNumber: null,
    category: 'Urgent',
    code: '100',
    courtName: null,
    createDate: '2022-10-14T09:44:14Z',
    descriptionKey: 'instant_alert',
    detailUpdated: 'Invalid date',
    dob: '15 May 2010',
    id: '1736942',
    infoDate: 'Invalid date',
    infoType: null,
    isUnread: false,
    isUrgent: true,
    lastUpdatedOn: '2022-10-14T09:44:30Z',
    name: 'MR FIRST FL LAST',
    nameOnAlert: 'MR FIRST FL LAST',
    search: {
      applicationType: '**2B6MNTOdAabtSTw',
      searchedBy: '**SearchedBy4KB9XJWJ12CrYK3',
      searchedOn: '14 July 2021',
      searchType: 'A',
      isLocked: false,
      isBlocked: true,
      isLockExcluded: false,
    },
    startDate: 'Invalid date',
    type: alertValues.types.fraud,
    value: {},
    whereFound: null,
  },
};
