import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { TimeoutWarning } from '@experian-uk/corvetteuk-common-ui';

import isServer from '../../helpers/isServer';
import { getEnv } from '../Context/env';
import { brandIdentifiers, fixedUrls } from '../../constants';
import cppLogout from '../../helpers/cppLogout';

const onLogout = async (env = getEnv(), storageInterface = Cookies, authKey = 'authId') => {
  storageInterface.remove(authKey);

  if (!isServer() && window.location) {
    const brandIdentifierCookie = storageInterface.get('brandIdentifier');
    const isCpp = brandIdentifierCookie === brandIdentifiers.cpp;
    const authUrl = isCpp ? fixedUrls.cppAuth : `${env.REACT_APP_C2_URL}/logout`;

    if (isCpp) {
      await cppLogout(authUrl);
      return;
    }

    window.location = authUrl;
  }
};

@connect(
  state => ({
    lastAction: state.sessionTimeout.lastActionDt,
  }),
)
export default class SessionTimeoutWarning extends Component {
  static propTypes = {
    durationOfWarning: PropTypes.number,
    logoutAction: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    durationOfWarning: 30,
    logoutAction: onLogout,
  };


  state = {
    show: false,
  };

  componentDidMount() {
    this.env = getEnv();
    this.startTimer();
  }

  componentDidUpdate() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  startTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.setState({ show: true });
      this.countdown = setTimeout(this.props.logoutAction, this.props.durationOfWarning * 1000);
    }, ((this.env.REACT_APP_SESSION_TIMEOUT - this.props.durationOfWarning) * 1000));
  }

  render() {
    const { logoutAction, durationOfWarning } = this.props;
    const { show } = this.state;

    const onClickStayLoggedIn = () => {
      this.setState({ show: false });
      this.startTimer();
      this.props.dispatch({ type: 'STAY_LOGGED_IN' });
      if (this.countdown) {
        clearTimeout(this.countdown);
      }
    };

    const onClickLogout = () => {
      logoutAction(this.env);
      this.setState({ show: false });
      if (this.countdown) {
        clearTimeout(this.countdown);
      }
    };

    const timeoutProps = {
      durationOfWarning,
      logout: {
        onClickAction: onClickLogout,
      },
      stayLoggedIn: {
        onClickAction: onClickStayLoggedIn,
      },
    };

    return show ? <TimeoutWarning {...timeoutProps} /> : null;
  }
}
