import {
  CREDITLOCK_NO_BENEFIT,
  CREDITLOCK_STATUS_FETCHED,
  FETCH_CREDITLOCK_STATUS,
} from '../actions/creditLock/getCreditLockStatus';
import { creditLock } from '../constants';

export const defaultState = {
  status: creditLock.off,
  isFetching: false,
  isReady: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case CREDITLOCK_STATUS_FETCHED:
      return {
        ...state,
        status: action.payload.status,
        isFetching: false,
        isReady: true,
      };
    case FETCH_CREDITLOCK_STATUS:
      return {
        ...state,
        isFetching: true,
      };
    case CREDITLOCK_NO_BENEFIT:
      return {
        ...state,
        status: creditLock.off,
        isReady: true,
      };
    default:
      return state;
  }
};
