import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import {
  Container,
  StyledButton,
  StyledSmallerText,
  StyledText,
} from './index.styles';

const CustomerRefDetails = ({ customerNumber, buttonConfig }) => (
  <Container {...setAutomationElement('customerRefDetails')}>
    {customerNumber && (
      <div>
        <StyledSmallerText>Customer Number</StyledSmallerText>
        <StyledText>{customerNumber}</StyledText>
      </div>
    )}
    {buttonConfig && (
      <StyledButton
        {...setAutomationElement(buttonConfig.automationKey || 'contactUs')}
        href={buttonConfig.href}
        label={buttonConfig.label}
        type={buttonConfig.type}
      />
    )
  }
  </Container>
);

CustomerRefDetails.propTypes = {
  buttonConfig: PropTypes.shape({
    automationKey: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
  }),
  customerNumber: PropTypes.string,
};

CustomerRefDetails.defaultProps = {
  buttonConfig: null,
  customerNumber: '',
};

export default CustomerRefDetails;
