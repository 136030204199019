import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export default Component => withRouter(
  class ScrollToTop extends React.Component {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }),
    };

    static defaultProps = {
      location: {
        pathname: '',
      },
    };

    componentDidMount = function componentDidMount() {
      window.scrollTo(0, 0);
    };

    componentDidUpdate = function componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        setImmediate(() => {
          window.scrollTo(0, 0);
        });
      }
    };

    render() {
      return (
        <Component />
      );
    }
  },
);
