import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '@nebula/global-styles';
import defaultTheme from '@nebula/theme';
import { Container, IconArea, Icon, BodyArea, Heading, ContentWrapper, Button, SidecarContainer } from './Card.styles';

const Card = ({
  heading,
  children,
  iconPath,
  automationKey,
  button,
  className,
  sidecar = null, // used to pass a component to the right of the content
  altText = '',
}) => {
  let headingElement = null;

  if (!heading && !children) {
    return null;
  }

  if (typeof heading === 'string') {
    headingElement = <Heading>{heading}</Heading>;
  } else if (heading) {
    headingElement = heading;
  }

  return (
    /**
     * TODO: Move ThemeProvider & GlobalStyles to the base of the app
     * -
     * The Nebula theme (v1.9.0) changes the root font size to various values based on browser width.
     * This breaks a lot of styles in Identity. The components are placed here to isolate them from the rest of the app.
     * When possible, the components should be moved and wrap the entire app instead, then all styles in Identity tested and fixed where applicable.
     */
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <Container {...setAutomationElement(automationKey)} className={className}>
        {iconPath && (
          <IconArea>
            <Icon alt={altText} src={iconPath} />
          </IconArea>
        )}
        <ContentWrapper>
          {headingElement}
          <BodyArea>{children}</BodyArea>
          {button && button.label && (button.onClick || button.href) && (
            <Button
              onClick={button.onClick}
              type={button.type}
              label={button.label}
              isButton={button.href !== null}
              href={button.href}
            />
          )}
        </ContentWrapper>
        {sidecar && <SidecarContainer>{sidecar}</SidecarContainer>}
      </Container>
    </ThemeProvider>
  );
};

Card.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  iconPath: PropTypes.string,
  automationKey: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'upgrade']),
    onClick: PropTypes.func,
    href: PropTypes.string,
  }),
  className: PropTypes.string,
  sidecar: PropTypes.node,
  altText: PropTypes.string,
};

Card.defaultProps = {
  heading: null,
  children: null,
  iconPath: null,
  automationKey: null,
  button: {
    label: null,
    type: 'primary',
    onClick: null,
    href: null,
  },
  className: '',
  sidecar: null,
  altText: '',
};

export default Card;
