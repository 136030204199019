export const LOADING = 'app/LOADING';
export const APP_STARTED = 'app/STARTED';
export const SHOULD_SHOW_CUSTOMER_REF_DETAILS = 'SHOULD_SHOW_CUSTOMER_REF_DETAILS';

export const applicationStarted = () => dispatch => (
  dispatch({
    type: APP_STARTED,
    hasStarted: true,
  })
);

export const setApplicationLoading = isLoading => dispatch => (
  dispatch({
    type: LOADING,
    isLoading,
  })
);

export const shouldShowCustomerRefDetails = () => dispatch => (
  dispatch({
    type: SHOULD_SHOW_CUSTOMER_REF_DETAILS,
  })
);
