import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';

import { isServer } from '../../helpers';
import { products, splitsList, referrerWhitelist } from '../../constants';

const familyWhitelist = [products.credit_expert, products.breach, products.breachPlus, products.identity];

export const LOGIN_SUCCESS_SENT = 'loginSuccessSent';

export default isSignup => (dispatch, getState) => {
  const { loginSuccess, subscriptions, split, router } = getState();

  if (!split.treatments[splitsList.loginSuccessEvents]) {
    return;
  }

  if (
    isServer() ||
    !subscriptions.current ||
    !familyWhitelist.includes(subscriptions.current.family) ||
    loginSuccess.sent
  ) {
    return;
  }

  const { referrer } = document;
  const onAllowedWhitelist = referrerWhitelist.some(wl => referrer.includes(wl));

  if (!onAllowedWhitelist && !window.Cypress && !isSignup) {
    return;
  }

  const successPageType = router.location.pathname === '/' ? 'HP' : 'Deeplink';

  // We only fire for CE on deeplinks
  if (successPageType === 'HP' && subscriptions.current.family === products.credit_expert) {
    return;
  }

  const authLabelMap = {
    [products.credit_expert]: 'Credit Expert',
    [products.breach]: 'Breach',
    [products.breachPlus]: 'Breach+',
    [products.identity]: 'Identity',
  };

  const acqLabelMap = {
    [products.breach]: 'Breach',
    [products.breachPlus]: 'Breach Plus',
  };

  if (isSignup) {
    Analytics.publish(
      MilestoneInputEvent.fromObject({
        login_success: {
          authenticationRoute: `${acqLabelMap[subscriptions.current.family]}`,
        },
      })
    );
  } else {
    Analytics.publish(
      MilestoneInputEvent.fromObject({
        login_success: {
          authenticationRoute: `${authLabelMap[subscriptions.current.family]} ${successPageType}`,
        },
      })
    );
  }

  dispatch({
    type: LOGIN_SUCCESS_SENT,
  });
};
