import styled, { css } from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import Container from '../../components/Container';
import frbanner from './assets/frbanner.jpg';
import frbannermobile from './assets/frbannermobile.jpg';

export const { Headings } = Typography;

export const FraudReportIntro = styled.section`
  ${({ theme }) => `
    background-color: ${theme.colors.baseWhite};
  `}

  ${({ theme, withImage }) => (withImage ? css`
    background-image: url('${frbanner}');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    ${theme.mediaEnd('15', `
      background-image: url('${frbannermobile}');
      background-position-y: 90%;
    `)}
  ` : '')}
`;

export const ReportTitle = styled(Typography.Headings.Tertiary)`
  color: ${props => props.theme.colors.experianPink};
  font-weight: 200;
  display: inline;
  ${({ theme }) => theme.mediaEnd('15', `
    text-align: center;
    display: block;
    width: 100%;
  `)}

  &:last-of-type {
    float: right;
    ${({ theme }) => theme.mediaEnd('15', `
      display: none;
    `)}
  }
`;

export const ReportText = styled(Typography.Copy.Text)`
  ${({ theme }) => `
    line-height: 1.75rem;
    color: ${theme.colors.experianDarkGrey};
  `}
  margin-bottom: 0;
  font-weight: 300;
`;

export const ReportTopTitle = styled(Headings.Primary)`
  margin-bottom: 1.5625rem;

  ${({ theme }) => theme.mediaEnd('15', `
    text-align: center;
    width: 100%;
    display: block;
  `)}
`;

export const ReportTitleContainer = styled.div`
  margin-bottom: .3125rem;
`;

export const ReportPersonalDetails = styled.aside`
  display: inline-block;
  padding: 1.875rem;
  border-radius: .25rem;
  background-color: ${({ theme }) => theme.colors.baseWhite};
`;

export const ReportIntroduction = styled.div`
  margin-bottom: 1.875rem
`;


export const ReportContainer = styled(Container)`
  padding: ${({ isIntro }) => (isIntro ? '4.375rem 0' : '1.25rem 0')};

  ${({ theme, isIntro }) => !isIntro && theme.media('20', `
    padding: 1.875rem 0;
  `)}
`;

export const PersonalDetailDate = styled(Typography.Copy.Text)`
  font-size: ${({ theme }) => theme.typography.smallFontSize};
  margin-top: 0;
`;

export const PersonalDetailsHeading = styled(Headings.Tertiary)`
  margin-bottom: 0;
  ${({ theme }) => theme.mediaEnd('15', `
    text-align: center;
  `)};
`;
