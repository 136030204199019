import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { brandIdentifiers } from '../../constants';

export const type = 'SET_USER';

export default (token, mockData, sessionId = uuidv4()) => {
  const authenticated = !!token;

  const payload = { authenticated, token, sessionId };

  if (mockData) {
    payload.mockData = mockData;

    if (mockData.subscriptionType === 'cpp') {
      Cookies.set('brandIdentifier', brandIdentifiers.cpp);
    }
  }

  return {
    type,
    payload,
  };
};
