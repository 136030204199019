import { LOGIN_SUCCESS_SENT } from '../actions/loginSuccess/send';

export const defaultState = {
  sent: false,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case LOGIN_SUCCESS_SENT:
      return {
        sent: true,
      };
    default:
      return state;
  }
};
