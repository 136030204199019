import styled from 'styled-components';

export const SecurityText = styled.div`
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 0.5rem 0;
  vertical-align: middle;
  display: flex;

  svg {
    margin: 0 1.125rem 0 0;
    vertical-align: middle;
    min-width: 26px;
  }

`;
