import fetch from '../fetch';
import validFilters from './validFilters';
import baseEndpoint from './baseEndpoint';

export const type = 'UPDATE_ALERTS_COUNT';

export const alertsEndpoint = `${baseEndpoint}/count`;

export const countDefaultFilter = 'unread';

export default (filter = countDefaultFilter) => async (dispatch) => {
  const searchFilter = validFilters.includes(filter) ? filter : countDefaultFilter;

  try {
    const alertCountResponse = await dispatch(
      fetch(`${alertsEndpoint}?filter='search=${searchFilter}'`,
        {},
        false,
        null,
        true),
    );
    const [res] = alertCountResponse.data;
    const { count } = res;

    return dispatch({
      type,
      payload: {
        count,
      },
    });
  } catch (e) {
    return dispatch({
      type,
      payload: new Error('Error fetching alerts count'),
      error: true,
      meta: { nonCritical: true },
    });
  }
};
