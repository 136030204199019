import { commonTerminology } from '../../constants';

const creditLockTextScenarios = {
  applicationBlocked: {
    renderCreditLockModule: true,
    creditLockHeader: `Application stopped by ${commonTerminology.creditLock}`,
    creditLockSubtext: `If you made this application, you need to unlock your ${commonTerminology.experianCreditReport} and contact the lender to continue or restart.`,
  },
  missingData: {
    renderCreditLockModule: true,
    creditLockHeader: 'Applying for credit?',
    creditLockSubtext: `Review your ${commonTerminology.creditLock} settings.`,
  },
  applicationCannotBeStopped: {
    renderCreditLockModule: true,
    creditLockHeader: `This application was not stopped by ${commonTerminology.creditLock}`,
    creditLockSubtext: 'As these types of application typically relate to your home or personal property they have a lower risk of identity fraud.',
  },
  hardSearchNotBlocked: {
    renderCreditLockModule: true,
    creditLockHeader: 'Finished applying for credit?',
    creditLockSubtext: `It’s important to lock your ${commonTerminology.experianCreditReport} to help protect yourself from fraud.`,
  },
  softSearchLockEnabled: {
    renderCreditLockModule: true,
    creditLockHeader: 'Thinking of applying for credit?',
    creditLockSubtext: `Make sure your ${commonTerminology.experianCreditReport} is unlocked first.`,
  },
  softSearchLockDisabled: {
    renderCreditLockModule: true,
    creditLockHeader: 'Not applying for credit?',
    creditLockSubtext: `It’s important to lock your ${commonTerminology.experianCreditReport} to help protect yourself from fraud.`,
  },
  ModuleNotRendered: {
    renderCreditLockModule: false,
    creditLockHeader: '',
    creditLockSubText: '',
  },
};

function textMap(isBlocked, isLockExcluded, searchTypeUpperCase, isLocked) {
  if (typeof isBlocked !== 'boolean') {
    return creditLockTextScenarios.missingData;
  }
  if (isBlocked) {
    return creditLockTextScenarios.applicationBlocked;
  }

  if (typeof isLockExcluded !== 'boolean') {
    return creditLockTextScenarios.missingData;
  }
  if (isLockExcluded) {
    if (typeof isLocked !== 'boolean') {
      return creditLockTextScenarios.missingData;
    }
    return isLocked ? creditLockTextScenarios.applicationCannotBeStopped : creditLockTextScenarios.ModuleNotRendered;
  }

  if (searchTypeUpperCase === 'H') {
    return creditLockTextScenarios.hardSearchNotBlocked;
  }
  if (searchTypeUpperCase === 'S') {
    if (typeof isLocked !== 'boolean') {
      return creditLockTextScenarios.missingData;
    }
    return isLocked ? creditLockTextScenarios.softSearchLockEnabled : creditLockTextScenarios.softSearchLockDisabled;
  }

  return creditLockTextScenarios.missingData;
}

export default ({
  isBlocked, isLockExcluded, searchType, isLocked,
}) => {
  const searchTypeUpperCase = typeof searchType === 'string' ? searchType.toUpperCase() : searchType;
  return textMap(isBlocked, isLockExcluded, searchTypeUpperCase, isLocked);
};
