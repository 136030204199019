import React from 'react';
import { getEnv } from '../../components/Context/env';

export const sectionsMap = {
  accounts: 'creditaccounts',
  connections: 'connections',
  personaldetails: 'personaldetails',
  queries: 'disputesandqueries',
  searches: 'recentactivity',
  intro: 'intro',
};

export const sectionPath = {
  introduction: '',
  searches: 'searches',
  personalDetails: 'personal-details',
  accounts: 'accounts',
  connections: 'connections',
  queries: 'queries',
};

export const sections = {
  [sectionsMap.intro]: {
    automationKey: 'report-nav-fraud-report',
    path: sectionPath.introduction,
    title: 'Introduction',
    bannerConfig: {
      showImage: true,
      showPersonalDetails: true,
      reportText: [
        'A fraud report shows you the important data we hold which can help identify and protect you from any fraudulent activity in your name.',
        'This information also contributes towards your Experian Credit Score to show your likely credit standing with lenders and will be used by many companies to verify your identity, so try to make sure it’s accurate.',
      ],
    },
  },
  [sectionsMap.searches]: {
    automationKey: 'report-nav-fraud-recent',
    path: sectionPath.searches,
    title: 'Searches',
    bannerConfig: {
      pageNumber: 2,
      titleText: 'Your searches',
      reportText: [
        'Here you can see a summary of any recent searches in your name including any applications for credit.',
      ],
    },
  },
  [sectionsMap.personaldetails]: {
    automationKey: 'report-nav-fraud-personal',
    path: sectionPath.personalDetails,
    title: 'Your details',
    bannerConfig: {
      pageNumber: 3,
      titleText: 'Your details',
      reportText: [
        'This is the information we use to create your Experian Fraud Report and to help confirm your identity. This includes your name, current and previous addresses, and electoral roll status.',
      ],
    },
  },
  [sectionsMap.accounts]: {
    automationKey: 'report-nav-fraud-accounts',
    path: sectionPath.accounts,
    title: 'Credit accounts',
    bannerConfig: {
      pageNumber: 4,
      titleText: 'Your credit accounts',
      reportText: [
        'Here you can check the details and status for any active or defaulted accounts we hold for you.',
        'Lenders give us the information we have about your accounts and we can’t change it without their permission. But if you think something isn’t right we can dispute it with them on your behalf.',
      ],
    },
  },
  [sectionsMap.connections]: {
    automationKey: 'report-nav-fraud-connections',
    path: sectionPath.connections,
    title: 'Connections',
    bannerConfig: {
      pageNumber: 5,
      titleText: 'Your connections',
      reportText: ['This is a list of addresses you’re showing as having been connected to.'],
    },
  },
  [sectionsMap.queries]: {
    automationKey: 'report-nav-fraud-queries',
    path: sectionPath.queries,
    title: 'Queries & disputes',
    bannerConfig: (env = getEnv(), isCpp) => {
      let reportText = [
        'Here you can find contact details for the organisations that have supplied information that appears on your Experian Credit Report.',
        'You can also see any statements added to your Experian Credit Report if you are currently disputing something or have previously requested that a Notice of Correction be shown.',
        <>
          You can contact us{' '}
          <a data-automation-test-element="contact-link" href={`${env.REACT_APP_INS_URL}/contact`}>
            here
          </a>{' '}
          about any information we hold, including where Experian is shown as being the source of the information.
        </>,
      ];
      if (isCpp) {
        reportText = [
          'Here you can find contact details for the organisations that have supplied information that appears on your Experian Credit Report.',
          'You can also see any statements added to your Experian Credit Report if you are currently disputing something or have previously requested that a Notice of Correction be shown.',
          <>
            You can contact us{' '}
            <a data-automation-test-element="contact-link" href={`${env.REACT_APP_CPP_URL}/support`}>
              here
            </a>{' '}
            about any information we hold, including where Experian is shown as being the source of the information.
          </>,
        ];
      }
      return {
        pageNumber: 6,
        titleText: 'Your queries & disputes',
        reportText,
      };
    },
  },
};
