import React from 'react';
import PropTypes from 'prop-types';

import {
  Content,
  IconContainer,
  Icon,
  InfoBlock,
  Heading,
} from './index.styles';

export { InfoBlockFlexContainer, InfoBlockLeftContent } from './index.styles';

export const InfoBlockContainer = ({
  withBorder,
  content,
  heading,
  iconName,
  children,
}) => (
  <InfoBlock withBorder={withBorder}>
    {iconName && (
      <IconContainer>
        <Icon name={iconName} />
      </IconContainer>
    )}
    <Heading>{heading}</Heading>
    <Content>{content || children}</Content>
  </InfoBlock>
);


InfoBlockContainer.propTypes = {
  withBorder: PropTypes.bool,
  heading: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node,
  iconName: PropTypes.string,
};

InfoBlockContainer.defaultProps = {
  withBorder: true,
  heading: '',
  content: '',
  iconName: undefined,
  children: null,
};
