/* istanbul ignore file */
import PropTypes from 'prop-types';
import { addressType } from '../constants/report/index';

export default PropTypes.shape({
  flat: PropTypes.string,
  houseNumber: PropTypes.string,
  postCode: PropTypes.string,
  street: PropTypes.string,
  town: PropTypes.string,
  city: PropTypes.string,
  addressType: PropTypes.oneOf([addressType.current, addressType.previous, addressType.supplemental]),
  fromDt: PropTypes.string,
  toDt: PropTypes.string,
});
