import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const COULD_NOT_VALIDATE_VOUCHER = 'COULD_NOT_VALIDATE_VOUCHER';
export const INVALID_VOUCHER = 'INVALID_VOUCHER';
export const VALIDATE_VOUCHER = 'VALIDATE_VOUCHER';
export const VALID_VOUCHER = 'VALID_VOUCHER';

export const endpoint = 'billingvouchers/vouchers';

export const reasonMap = {
  expired: errorMessages.voucherValidation.expiredVoucherCode,
  invalid: errorMessages.voucherValidation.invalidVoucherCode,
  other: errorMessages.voucherValidation.technicalError,
  used: errorMessages.voucherValidation.usedVoucherCode,
};

const consumeResReturnReasonString = async errorResponse => {
  try {
    const {
      fieldErrors: { voucher },
    } = await errorResponse.json();
    return reasonMap[voucher] || reasonMap.other;
  } catch (err) {
    return reasonMap.other;
  }
};

export default code => async dispatch => {
  if (!code || typeof code !== 'string') {
    return Promise.reject(new Error('Voucher Code must be supplied'));
  }

  dispatch({ type: VALIDATE_VOUCHER });

  try {
    await dispatch(fetch(`/${endpoint}/${code}`, {}, false, 'anonymous', true));

    return dispatch({
      type: VALID_VOUCHER,
      payload: code,
    });
  } catch (e) {
    if (e.status && e.status < 500) {
      const reason = await consumeResReturnReasonString(e);
      return dispatch({
        type: INVALID_VOUCHER,
        payload: reason,
      });
    }

    return dispatch({
      type: COULD_NOT_VALIDATE_VOUCHER,
      payload: errorMessages.voucherValidation.technicalError,
    });
  }
};
