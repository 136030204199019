import printf from 'printf';
import getTabDescription from './getTabDescription';
import reportConstants from '../../../constants/report';

const { typeStatuses } = reportConstants;

const defaultGroupFilters = [
  {
    description: typeStatuses.active,
    test: ({ typeStatus }) => typeStatus.toLowerCase() === typeStatuses.active.toLowerCase(),
  },
  {
    description: typeStatuses.default,
    test: ({ typeStatus }) => typeStatus.toLowerCase() === typeStatuses.default.toLowerCase(),
  },
];

const categoriesInOrder = [
  'mortgageandsecuredloans',
  'instalments',
  'cards',
  'tvphoneandinternet',
  'utilities',
  'otherrevolvingcredit',
  'othernonrevolvingcredit',
  'insurance',
  'othersecuredcredit',
  'mailorder',
  'nonrevolvingcredit',
];

const filterAccounts = (
  accounts,
  filterTest = () => true,
) => accounts.filter(
  account => filterTest(account),
);

const sortAccounts = (
  accounts,
  sortByProperty = 'startDate',
  isAscending = false,
) => accounts.sort((a, b) => {
  if (a[sortByProperty] < b[sortByProperty]) {
    return isAscending ? -1 : 1;
  }
  if (a[sortByProperty] > b[sortByProperty]) {
    return isAscending ? 1 : -1;
  }
  return 0;
});

const getCategoryIndexes = (accounts, orderedCategories = categoriesInOrder) => accounts.map((account) => {
  // sanitising the aategory should help us get a match as often as possible, given that the mock data from which we are working doesn't
  // seem to match the values currently being used to do this in Production, i.e.:
  // https://git.gbl.experiancs.com/ecs-uk/corvetteuk-home/blob/develop/src/services/transforms/report/accounts/transforms.js#L14
  // Far from ideal - but IMHO we shouldn't really be doing this on the client anyway.
  // Will talk with Service Developers to see if we can improve this.
  const sanitisedCategory = account.category.replace(/[^a-z0-9\s]/ig, '')
    .trim()
    .replace(/\s+/g, '')
    .toLowerCase();
  return {
    ...account,
    categoryIndex: orderedCategories.indexOf(sanitisedCategory),
  };
});

const defaultIssueCondition = ({ latePaymentMonthsAgo }, maxMonths = 12) => (latePaymentMonthsAgo && latePaymentMonthsAgo <= maxMonths);

export default (
  accounts = [],
  groupFilters = defaultGroupFilters,
  sortConfigs = [],
  issueCondition = defaultIssueCondition,
  orderedCategories = categoriesInOrder,
) => {
  let sortedAccounts = getCategoryIndexes(accounts, orderedCategories);
  sortConfigs.map((config, index) => {
    if (index < sortConfigs.length) {
      sortedAccounts = sortAccounts(sortedAccounts, config.property, config.order === 'asc');
    }
    return sortedAccounts;
  });
  const filteredAccounts = accounts.length > 0 ? groupFilters.map((groupFilter, index) => {
    const accountDetails = filterAccounts(sortedAccounts, groupFilter.test);
    const descriptionText = `${printf('%s Accounts (%d)', groupFilter.description, accountDetails.length)}`;
    const issues = accountDetails.filter(account => issueCondition(account));

    return {
      ariaLabel: `show ${groupFilter.description} accounts`,
      description: getTabDescription(issues, descriptionText),
      id: `accounts-tab-${index + 1}`,
      accountDetails,
    };
  }) : [{
    description: 'Accounts',
    id: 'accounts-tab-1',
    accountDetails: accounts,
  }];

  return {
    all: accounts,
    count: accounts.length,
    dataFetched: true,
    tabs: filteredAccounts,
  };
};
