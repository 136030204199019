import { STORED_DATA_FETCHED } from '../actions/mapData';
import { STORED_DATA_UPDATED } from '../actions/updateStoredData';

const initialState = {
  fetched: false,
  identitySplash: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case STORED_DATA_FETCHED:
      return {
        ...state,
        ...action.payload,
        fetched: true,
      };
    case STORED_DATA_UPDATED:
      return {
        ...state,
        ...action.payload,
        fetched: true,
      };
    default:
      return state;
  }
};
