export { default as alertValues } from './alerts';
export { default as benefits } from './benefits';
export { default as brandIdentifiers } from './brandIdentifiers';
export { default as creditLock } from './creditLock';
export { default as customTheme } from './customTheme';
export { default as commonTerminology } from './commonTerminology';
export { default as dateFormat } from './dateFormat';
export { default as descriptors } from './descriptors';
export { default as errorMessages } from './errorMessages';
export { default as familyName } from './familyName';
export { default as fixedUrls } from './fixedUrls';
export { default as idaas } from './idaas';
export { default as keyCodes } from './keyCodes';
export { default as notificationTypes } from './notificationTypes';
export { default as products } from './products';
export { default as productIdentifiers } from './productIdentifiers';
export { default as report } from './report/index';
export { default as verdusStatuses } from './verdusStatus';
export { default as services } from './services';
export { default as splashFeatures } from './splashFeatures';
export { default as splitsList } from './splits';
export { default as splitTreatments } from './splitTreatments';
export { default as subscriptionActions } from './subscriptionActions';

export const identitySplashPageCookie = 'identitySplash';

export const referrerWhitelist = [
  'experianidentityservice.co.uk',
  'account.experian.co.uk',
  'idp1.int.idaas.uk.experian.com',
  'localhost',
];
