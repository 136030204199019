import { type as UPDATE_ECD } from '../actions/ecd/update';
import { type as DELETE_ECD } from '../actions/ecd/delete';
import { type as ADD_EVENT } from '../actions/ecd/addEvent';

export default globalStorageAccessor => store => next => (action) => {
  const ecdActions = [UPDATE_ECD, DELETE_ECD, ADD_EVENT];
  const globalStorage = globalStorageAccessor();

  if (!ecdActions.includes(action.type)) {
    next(action);
    return;
  }

  next(action);

  const { ecd, router } = store.getState();

  globalStorage.$ECD = {
    ...ecd,
    Events: ecd.events[router.location.pathname] || [],
  };

  delete globalStorage.$ECD.events;
};
