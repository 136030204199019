import styled from 'styled-components';
import { Button, Typography } from '@experian-uk/corvetteuk-common-ui';

import BaseContainer from '../Container';
import CloseModal from './assets/close-modal.svg';
import HeroBackgroundDesktop from './assets/hand-splash-desktop.png';
import HeroBackgroundMobile from './assets/hand-splash-mobile.png';
import HeroBackgroundTablet from './assets/hand-splash-tablet.png';
import Laptop from './assets/laptop.png';
import Phone from './assets/phone.png';
import PhoneSupportTaller from './assets/phone-support-taller.png';
import PhoneSupportMobile from './assets/phone-support-mobile.png';
import Tablet from './assets/tablet.png';
import Unlocked from './assets/unlocked-handset-splash.png';

export const Background = styled.div`
  background-color: rgba(255,255,255,0.7);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
`;

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const Content = styled(BaseContainer)`
  background-color: ${({ theme }) => theme.colors.baseWhite};
  border-radius: 10px;
  box-shadow:  0 0.1rem 1.7rem 0.6rem ${({ theme }) => theme.colors.shadeGrey};
  display: block;
  font-weight: 300;
  opacity: 1;
  margin: auto;
  max-width: 1000px;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  z-index: 100;
  width: 90%;

  ${({ theme: { media } }) => media('15', `
    padding: 0;
  `)}
`;


export const FeatureHeading = styled(Typography.Headings.Tertiary)`
  color: ${({ theme }) => theme.colors.experianDarkGrey};
  display: flex;
  font-weight: 300;
  margin: 0 0 .5rem;

  span {
    span {
      font-weight: 700;
    }
  }
`;

export const GetStartedButton = styled(Button).attrs({
  role: 'button',
  tabIndex: '2',
})`
  ${({ theme }) => theme.mediaEnd('10', `
    width: 100%;
  `)};

  ${({ theme }) => theme.media('10', `
    float: right;
    margin: 0 1.5rem 1.5rem 0;
    width: 250px;
  `)};
`;

export const GetStartedButtonContainer = styled(BaseContainer)`
  ${({ theme }) => `
    bottom: 0;
    position: absolute;
    right: 1.5rem;
    width: 100%;

    ${theme.media('10', `
      bottom: -1.5rem;
    `)};
  `};
`;

export const IdentityFeaturesList = styled.ol`
  counter-reset: features-counter;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    align-items: center;
    counter-increment: features-counter;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    section {
      margin: 24px;

      ${({ theme }) => theme.media('15', `
        display: flex;
        flex-wrap: wrap;
        margin-left: 4.5rem;
      `)};

      ${({ theme }) => theme.media('17', `
        margin: 1.875rem 1.5rem 1.875rem 4.5rem;
      `)};

      ${({ theme }) => theme.media('20', `
        margin: 32px;
        margin-left: 6rem;
      `)};
    }

    h3 {
      line-height: 28px;
      margin-bottom: 1rem;
      padding: .2rem 0;
      font-size: 20px;
      font-weight: 400;

      ${({ theme }) => theme.media('15', `
        margin-bottom: .75rem;
      `)};

      &:before {
        align-self: flex-start;
        content: counter(features-counter);
        font-size: 50px;
        font-weight: 300;
        line-height: 30px;
        margin: 1rem 12px 0 11px;

        ${({ theme }) => theme.media('15', `
          font-size: 70px;
          left: 1rem;
          position: absolute;
        `)};

        ${({ theme }) => theme.media('20', `
          left: 2rem;
        `)};
      }
    }

    small {
      display: block;
      flex-basis: 100%;

      &:first-of-type {
        margin-bottom: 10px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:first-child {
      background-color: ${({ theme }) => theme.colors.baseLightGrey};

      section {
        &:before {
          background: url(${Unlocked});
          background-repeat: no-repeat;
          background-size: 83px 90px;
          content: '';
          height: 90px;
          width: 83px;
          position: absolute;
          margin-left: 0px;


          ${({ theme }) => theme.media('15', `
           left: 20px;
          `)}

          ${({ theme }) => theme.media('17', `
            background-size: 112px 123px;
            height: 123px;
            width: 112px;
            bottom: 0;
          `)}

          ${({ theme }) => theme.media('20', `
           left: 88px;
          `)}
        }

        ${({ theme }) => theme.media('15', `
          margin-left: 210px;
       `)}

        ${({ theme }) => theme.media('17', `
          margin-left: 290px;
        `)}

        ${({ theme }) => theme.media('20', `
          margin-left: 320px;
        `)}
      }

      h3 {
        margin-left: 80px;
        margin-bottom: 16px;
        ${({ theme }) => theme.media('15', `
          margin-left: 0px;
        `)};


        &:before {
          left: 150px;

          ${({ theme }) => theme.media('17', `
            left: 225px;
          `)};

          ${({ theme }) => theme.media('20', `
            left: 255px;
          `)};
        }

        span {
          min-width: 121px;
          ${({ theme }) => theme.mediaEnd('15', `
            max-width: 150px;
          `)};
        }
      }

      div {
        ${({ theme }) => theme.media('15', `
          margin-top: 0;
        `)};

        ${({ theme }) => theme.media('30', `
          max-width: 491px;
        `)};
      }
    }

    &:nth-child(2) {
      h3 {
        ${({ theme }) => theme.mediaEnd('15', `
          max-width: 200px;
        `)};
      }

      div {
        @media(min-width: 500px) {
          max-width: 87.5%;
        }

        ${({ theme }) => theme.media('17', `
          max-width: 80%;
        `)};

        ${({ theme }) => theme.media('20', `
          max-width: auto;
        `)};

        ${({ theme }) => theme.media('30', `
          max-width: 77.5%;
        `)};
      }

      &:after {
        background: url(${Phone});
        background-size: 50px 102px;
        content: '';
        height: 102px;
        position: absolute;
        right: 1rem;
        top: -12px;
        width: 50px;
        z-index: 110;

        ${({ theme }) => theme.media('17', `
          background-size: 104px 211px;
          height: 211px;
          right: 1rem;
          top: -28px;
          width: 104px;
        `)};

        ${({ theme }) => theme.media('20', `
          right: 3rem;
        `)};
      }
    }

    &:nth-child(3) {
      background-color: ${({ theme }) => theme.colors.baseLightGrey};

      section {
        &:before {
          background: url(${Laptop});
          background-size: 125px 75px;
          content: '';
          height: 75px;
          margin-right: 5px;
          position: absolute;
          left: 1rem;
          top: 1.5rem;
          width: 125px;

          ${({ theme }) => theme.media('15', `
            top: 33%;
          `)};

          ${({ theme }) => theme.media('17', `
            background-size: 196px 120px;
            height: 120px;
            left: 1rem;
            top: 22.5%;
            width: 196px;
          `)}

          ${({ theme }) => theme.media('20', `
            left: 3rem;
          `)}
        }

        ${({ theme }) => theme.media('15', `
          margin-left: 210px;
        `)}

        ${({ theme }) => theme.media('17', `
          margin-left: 290px;
        `)}

        ${({ theme }) => theme.media('20', `
          margin-left: 320px;
        `)}
      }

      h3 {
        margin-left: 130px;

        ${({ theme }) => theme.media('15', `
          margin-left: 0;
        `)};

        &:before {
          left: 150px;

          ${({ theme }) => theme.media('17', `
            left: 225px;
          `)};

          ${({ theme }) => theme.media('20', `
            left: 255px;
          `)};
        }

        span {
          ${({ theme }) => theme.mediaEnd('15', `
            max-width: 150px;
          `)};
        }
      }

      div {
        margin-top: 32px;

        ${({ theme }) => theme.media('15', `
          margin-top: 0;
        `)};

        ${({ theme }) => theme.media('30', `
          max-width: 95%;
        `)};
      }
    }

    &:nth-child(4) {
      h3 {
        margin-bottom: 27px;
        max-width: 200px;

        ${({ theme }) => theme.media('10', `
          max-width: 220px;
        `)};

        ${({ theme }) => theme.media('15', `
          margin-bottom: .75rem;
          max-width: 100%;
        `)};
      }

      div {
        @media(min-width: 500px) {
          max-width: 85%;
        }

        ${({ theme }) => theme.media('17', `
          max-width: 75%;
        `)};
      }

      &:after {
        background: url(${Tablet});
        background-size: 78px 109px;
        content: '';
        height: 109px;
        position: absolute;
        right: 1rem;
        top: -12px;
        width: 78px;
        z-index: 200;

        ${({ theme }) => theme.media('17', `
          background-size: 145px 202px;
          height: 202px;
          right: 1rem;
          width: 145px;
        `)}

        ${({ theme }) => theme.media('20', `
          right: 3rem;
        `)}
      }
    }

    &:nth-child(5) {
      ${({ theme }) => `
        align-items: flex-start;
        background-image: url(${PhoneSupportTaller});
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0 0 10px 10px;
        height: 228px;


        ${theme.media('5', `
          background-position: 22% 0;
        `)}

        ${theme.media('10', `
          background-position: 20% 0;
        `)}

        ${theme.mediaEnd('10', `
          background-image: url(${PhoneSupportMobile});
          background-position: center;
        `)}

        ${theme.media('17', `
          background-position: 0 0;
        `)}

        ${theme.mediaEnd('17', `
          height: 282px;
        `)}
      `}

      color: ${({ theme }) => theme.colors.baseWhite};

      section {
        left: 25%;
        max-width: 55%;
        position: absolute;
        top: 0;

        div {
          ${({ theme }) => theme.mediaEnd('5', `
          width: 186px;
          margin-left: 20px;
        `)}
        }

        ${({ theme }) => theme.media('5', `
          left: 35%;
        `)}

        ${({ theme }) => theme.media('17', `
          left: 30%;
          margin-top: .75rem;
        `)}

        ${({ theme }) => theme.media('20', `
          left: 30%;
          margin-top: 1.875rem;
        `)}
      }

      h3 {
        color: ${({ theme }) => theme.colors.baseWhite};

        ${({ theme }) => theme.mediaEnd('15', `
          max-width: 220px;
        `)}

        &:before {
          ${({ theme }) => theme.media('15', `
            left: -60px;
          `)}
        }
      }
    }
  }
`;

export const Intro = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.baseWhite};
  display: flex;
  flex-direction: column;
  height: 145px;
  justify-content: center;
  position: relative;

  * {
    z-index: 110;

    &:first-child: {
      z-index: 100;
    }
  }
`;

export const IntroBackground = styled.img.attrs({
  src: `${HeroBackgroundDesktop}`,
  srcSet: `${HeroBackgroundMobile} 345w, ${HeroBackgroundTablet} 690w, ${HeroBackgroundDesktop} 1000w`,
  sizes: '(max-width: 500px) 345px, (max-width: 900px) 690px, 1000px',
})`
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
`;

export const IntroCloseButton = styled(Typography.Copy.SmallText).attrs({
  role: 'button',
  tabIndex: '1',
})`
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.baseWhite};
  cursor: pointer;
  display: flex;
  line-height: 1rem;
  margin: 0 1rem 0 0;

  &:after {
    content: url(${CloseModal});
    margin-left: .5rem;
  }

  ${({ theme }) => theme.media('20', `
    margin: 0;
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  `)};
`;

export const IntroHeading = styled(Typography.Headings.Primary)`
  color: ${({ theme }) => theme.colors.baseWhite};
  font-weight: 700;
  margin: 0;
  text-align: center;
  font-size: 32px;

  ${({ theme }) => theme.media('15', `
  font-size: 32px;
`)};

  ${({ theme }) => theme.mediaEnd('15', `
    line-height: 38px;
  `)};
`;

export const IntroSubHeading = styled(Typography.Headings.Tertiary)`
  color: ${({ theme }) => theme.colors.baseWhite};
  font-weight: 700;
  font-size: 24px;
  margin: 0;

  ${({ theme }) => theme.mediaEnd('15', `
    font-size: 20px;
    line-height: 28px;
  `)};
`;

export const Welcome = styled.div`
  text-align: center;

  ${({ theme }) => theme.mediaEnd('15', `
    width: 65%;
  `)};
`;
