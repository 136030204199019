import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

const { Copy, Headings } = Typography;

export const Container = styled.div`
  padding: 0 1rem;
  text-align: center;

  ${({ theme: { media } }) => media('15', `
     text-align: left;
  `)}
`;

export const CopyText = styled(Copy.Text)`
  font-size: 1.25rem;
  line-height 1.5;
`;

export const SecondaryHeading = styled(Headings.Secondary)`
  padding-bottom: 0.25rem;
`;

export const TertiaryHeading = styled(Headings.Tertiary)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  font-size: 1.5rem;
  font-weight: 300;
  padding-bottom: 0.25rem;
`;
