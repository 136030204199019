import React, { Fragment } from 'react';
import {
  Copy,
  Container,
} from './index.styles';

import ActionPoint from '../actions';

const takeActionSections = [
  {
    key: 'money',
    title: 'You could lose money',
    text: 'A fraudster could change your password for online retail accounts such as Amazon. Then, they could buy products in your name, using your stored bank details.',
  },
  {
    key: 'debt',
    title: 'You could go into debt',
    text: 'A fraudster could get access to your credit card or banking details. If they succeed, they could use your money and get you into debt, and in turn impact your credit report and score.',
  },
  {
    key: 'crime',
    title: 'Your identity could be used for criminal activity',
    text: 'A fraudster could try to impersonate you online. They could set up fake social accounts in your name to defraud other users.',
  },
];
const TakeActionPanel = () => (
  <Container>
    <Copy>What could happen if I don&rsquo;t take action</Copy>
    <Fragment>
      {
        takeActionSections.map(sect => (<ActionPoint key={sect.key} sect={sect} />))
      }
    </Fragment>
  </Container>
);

export default TakeActionPanel;
