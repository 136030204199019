import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HeaderOOS, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { StyledButton } from './index.styles';
import { EnvConsumer } from '../../Context/env';

const LoginCTA = () => (
  <EnvConsumer>
    {
      context => (
        <StyledButton
          {...setAutomationElement('loginCTA')}
          label="Log in"
          href={context.REACT_APP_AUTH_URL}
        />
      )
    }
  </EnvConsumer>
);

@connect(
  state => ({
    loggedIn: state.auth.authenticated,
  }),
)
export default class LandingHeader extends Component {
  render() {
    const loginButton = this.props.loggedIn ? null : <LoginCTA />;

    return <HeaderOOS content={loginButton} />;
  }
}

LandingHeader.propTypes = {
  loggedIn: PropTypes.bool,
};

LandingHeader.defaultProps = {
  loggedIn: false,
};
