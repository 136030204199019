import dayjs from 'dayjs';
import intersection from 'lodash/intersection';
import { SUBSCRIPTIONS_FETCHED, SUBSCRIPTIONS_FETCH_FAILED } from '../actions/subscriptions/getSubscription';
import { PRODUCT_DETAILS_FETCHED } from '../actions/product/getDetails';
import benefitsConsts from '../constants/benefits';

const defaultState = {
  error: null,
};

export const hasBenefit = (state, requiredBenefits) => {
  if (!(state.current && state.current.benefits)) {
    return null;
  }

  const currentBenefits = state.current.benefits
    .map(b => {
      if (b.id && b.benefitActivated) {
        return b.id;
      }
      return null;
    })
    .filter(benefitId => benefitId);

  const matches = intersection(currentBenefits, requiredBenefits);

  return !!matches.length;
};

export const getBenefits = (state, benefitKeys) => {
  if (!(state.current && state.current.benefits)) {
    return {};
  }

  return benefitKeys.reduce((benefitsObj, key) => {
    const benefits = benefitsObj;
    benefits[key] = !!state.current.benefits.find(benefit => benefit.id === benefitsConsts[key]);
    return benefits;
  }, {});
};

const injectBenefits = (state, requiredBenefits) => {
  const currentBenefits = state.current.benefits;
  const newBenefits = [
    ...currentBenefits,
    ...requiredBenefits.map(id => ({
      id,
      benefitActivated: true,
    })),
  ];

  return {
    ...state,
    current: {
      ...state.current,
      benefits: newBenefits,
    },
  };
};

export default (state = defaultState, action = {}, dateHelper = dayjs) => {
  switch (action.type) {
    case SUBSCRIPTIONS_FETCHED:
      return {
        ...state,
        current: {
          ...action.payload,
          daysSinceStart: action.payload && Math.abs(dateHelper(action.payload.startDt).diff(dateHelper(), 'days')),
        },
      };
    case SUBSCRIPTIONS_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case PRODUCT_DETAILS_FETCHED: {
      if (action.payload.isCpp && state.current) {
        return injectBenefits(state, [benefitsConsts.fraudReport]);
      }
      return state;
    }
    default:
      return state;
  }
};
