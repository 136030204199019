import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics from '@experian-uk/web-common-analytics';

import useEcd2Data from '../../hooks/useEcd2Data';
import isServer from '../../helpers/isServer';
import { alertSchema } from '../../schemas';

const ECD2PageInit = ({
  alerts,
  auth,
  creditLockData,
  scores,
  subscriptions,
  pageName,
  callback,
  offers,
  shouldFetchAlerts,
}) => {
  const ecd2Data = useEcd2Data(auth, subscriptions, scores, creditLockData, alerts, offers, shouldFetchAlerts);
  const initialEcd = useRef(false);

  if (ecd2Data !== null && !initialEcd.current) {
    // eslint-disable-next-line func-names
    (async function () {
      initialEcd.current = true;

      if (!isServer()) {
        await Analytics.init(ecd2Data, pageName);
        callback?.();
      }
    })();
  }

  return null;
};

ECD2PageInit.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape(alertSchema)),
  auth: PropTypes.shape({
    fetching: PropTypes.bool,
    currentUser: PropTypes.shape({
      customerNumber: PropTypes.string,
    }),
  }),
  creditLockData: PropTypes.shape({
    status: PropTypes.string,
    isFetching: PropTypes.bool,
    isReady: PropTypes.bool,
  }),
  scores: PropTypes.shape({
    scoreValue: PropTypes.number,
    previousScoreValue: PropTypes.number,
    boostUplift: PropTypes.number,
    isFetching: PropTypes.bool,
    isReady: PropTypes.bool,
  }),
  subscriptions: PropTypes.shape({
    current: PropTypes.shape({
      family: PropTypes.string,
    }),
    error: PropTypes.string,
  }),
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  pageName: PropTypes.string.isRequired,
  callback: PropTypes.func,
  shouldFetchAlerts: PropTypes.bool,
};

ECD2PageInit.defaultProps = {
  auth: {},
  creditLockData: {},
  subscriptions: {},
  scores: {},
  alerts: {},
  offers: {},
  callback: undefined,
  shouldFetchAlerts: true,
};

const mapReduxStateToProps = store => ({
  alerts: store.alerts,
  auth: store.auth,
  creditLockData: store.creditLock,
  scores: store.scores,
  subscriptions: store.subscriptions,
  offers: store.offers,
});

export default connect(mapReduxStateToProps, undefined)(ECD2PageInit);
