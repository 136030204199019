import React from 'react';
import { NavLink } from 'react-router-dom';
import { HomeLink, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

export const breadcrumbMap = {
  'monitoring-settings': {
    automationKey: 'breadcrumb-monitoring-settings',
    render: () => (
      <NavLink to="/monitoring/settings" {...setAutomationElement('breadcrumb-monitoring-settings')}>Monitoring Settings</NavLink>
    ),
  },
  'report': {
    automationKey: 'breadcrumb-report',
    render: () => (
      <NavLink to="/report" {...setAutomationElement('breadcrumb-report')}>Fraud Report</NavLink>
    ),
  },
  'alerts': {
    automationKey: 'breadcrumb-alerts',
    render: () => (
      <NavLink to="/alerts" {...setAutomationElement('breadcrumb-alerts')}>Alerts</NavLink>
    ),
  },
  'alerts-settings': {
    automationKey: 'breadcrumb-alerts-settings',
    render: () => (
      <NavLink to="/alerts/settings" {...setAutomationElement('breadcrumb-alerts-settings')}>Settings</NavLink>
    ),
  },
};

const overridePathRegex = /alerts\/[0-9]/;

export const evaluatePath = (path = '', overridePathStructure) => {
  if (overridePathStructure.test(path)) {
    return [path];
  }
  let pathSegments = path.split('/');
  if (pathSegments.length < 2) {
    return pathSegments;
  }
  if (!pathSegments[0]) {
    pathSegments = pathSegments.slice(1);
  }
  let currentSegment = '';
  return pathSegments.map((pathSegment) => {
    const segmentToReturn = `${currentSegment}${pathSegment}`;
    currentSegment = `${pathSegment}-`;
    return segmentToReturn;
  });
};

export const homeBreadcrumb = {
  render: () => (
    <NavLink to="/" {...setAutomationElement('breadcrumb-home')} aria-label="Home">
      <HomeLink />
    </NavLink>
  ),
};

export default (currentPath, map = breadcrumbMap, overridePathStructure = overridePathRegex) => evaluatePath(currentPath, overridePathStructure).map(path => (map[path] || map[`${path}/`])).filter(breadcrumb => breadcrumb);
