import channels from '../../../constants/channels';

export default (state) => {
  if (!state.subscriptions || !state.subscriptions.current) {
    return undefined;
  }

  const { channel } = state.subscriptions.current;

  return channel === channels.migration;
};
