import React from 'react';
import headings from './headings';

export default {
  A: {
    title: headings.arrangement,
    description: 'The lender has agreed to vary your payments for a time',
    dateDescription: headings.startDate,
  },
  D: {
    title: 'Deceased',
    description: 'We have been told that the account holder has passed away',
    dateDescription: headings.dateNotified,
  },
  G: {
    title: 'Gone Away',
    description: 'The lender has reported that you no longer live at the address you gave and have not given them a new address',
    dateDescription: headings.dateNotified,
  },
  I: {
    title: 'Paid by third party',
    description: 'Paid by a third party',
    dateDescription: headings.dateNotified,
  },
  M: {
    title: 'Debt Management Programme',
    description: 'The lender has reported that the account has been included in a debt-management programme (for example, one organised by a citizens advice bureau, National Debtline or the Consumer Credit Counselling Service)',
    dateDescription: headings.startDate,
  },
  Q: {
    title: 'Account Query',
    description: 'The lender has reported that you have questioned how accurate the information is',
    dateDescription: headings.dateNotified,
  },
  R: {
    title: 'Recourse',
    description: 'The lender has reported that the account has been transferred to the dealer or retailer who introduced you to the credit agreement',
    dateDescription: headings.dateNotified,
  },
  V: {
    title: 'Voluntary Termination',
    description: 'The lender has reported that the account has been closed under Section 99 of the Consumer Credit Act 1974. This relates to hire-purchase and condition-sale agreements (credit used to buy a car or other vehicle) and allows you to return the car and owe nothing once you have repaid half of the total price',
    dateDescription: headings.dateNotified,
  },
  P: {
    title: 'Partial Settlement',
    description: (
      <React.Fragment>
        <div>The lender has reported one of the following:</div>
        <ul>
          <li> The debt was included in a voluntary arrangement which has now ended</li>
          <li> The debt was included in a bankruptcy order which has now been discharged</li>
          <li> The lender has accepted a smaller amount in full and final settlement</li>
          <li> The lender has repossessed the asset and is no longer chasing the debt</li>
        </ul>
      </React.Fragment>
    ),
    dateDescription: headings.dateNotified,
  },
  C: {
    title: 'Debt Assigned',
    description: 'The lender has reported that they sold the debt to an organisation which is not a member of our information-sharing scheme. We will keep the record in the original lender’s name and show it as closed',
    dateDescription: headings.dateNotified,
  },
  S: {
    title: 'Debt Assigned to CAIS Member',
    description: 'The lender has sold the debt to another member of our information-sharing scheme. The old account will have been closed and a new active account will show in the name of the lender who has bought the debt',
    dateDescription: headings.dateNotified,
  },
};
