import dayjs from 'dayjs';
import { addressType as addressTypeConst } from '../constants/report/index';
import { dateFormat } from '../constants';

const reduceAddressesToIncludeMissing = (accumulator, current, index, array) => {
  if (index === 0) {
    accumulator.push(current);
    return accumulator;
  }
  const { fromDt } = array[index - 1];
  const { toDt } = current;

  const gapInMonths = dayjs(fromDt).diff(dayjs(toDt), 'months');

  if (gapInMonths > 0) {
    accumulator.push({
      missing: true,
      fromDt: dayjs(toDt).format(dateFormat.monthYear),
      toDt: dayjs(fromDt).format(dateFormat.monthYear),
    });
  }

  accumulator.push(current);

  return accumulator;
};

export const missingPreviousAddresses = addresses =>
  addresses
    .reduce(reduceAddressesToIncludeMissing, [])
    .filter(
      ({ addressType, missing }) =>
        addressType === addressTypeConst.previous || addressType === addressTypeConst.supplemental || missing
    );
