import styled from 'styled-components';

export default styled.section`
  font-size: ${props => props.theme.typography.baseFontSize};
  padding: 1.25rem 0;
  max-width: 90%;
  margin: 0 auto;
  color: ${props => props.theme.colors.baseDarkGrey};
  font-weight: 300;

  ${({ theme: { media } }) => media('15', `
    padding: 1.875rem 0;
  `)}

  ${({ theme: { media } }) => media('30', `
    max-width: 1140px;
  `)}
`;

export const PagePlaceholder = styled.div`
  align-items: center;
  display: flex;
  height: 50vh;
  overflow: hidden;
  justify-content: center;
  width: 100vw;
`;

export const FullPageContainer = styled(PagePlaceholder)`
  height: 100vh;
`;
