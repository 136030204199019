const dataTheft = 'This data might be stolen or copied illegally, so you are at high risk of being a victim of fraud';
const higherThanAverageFraudRisk = 'This puts you at higher than average risk of becoming a victim of fraud';
const highFraudRisk = 'This puts you at high risk of becoming a victim of fraud';

export default {
  blackMarket: `It’s important because they are being sold online by illegal black market communities. For example, your details could be used as ID to apply for credit in your name.  ${higherThanAverageFraudRisk}.`,
  blackMarketDataTheft: `They are being sold on an illegal online black market. ${dataTheft}.`,
  blackMarketHighRisk: `They are being sold together online by illegal black market communities. ${highFraudRisk}.`,
  blackMarketPassport: 'It’s important because it is being sold online by illegal black market communities. For example, your passport number could be used as  ID to apply for credit in your name. It might be stolen or copied illegally, so you are at risk of being a victim of fraud.',
  blackMarketPhone: `It’s being sold online by illegal black market communities. This makes you vulnerable to ‘Phishing’ and fraudulent over the phone scams. ${higherThanAverageFraudRisk}.`,
  companiesHouse: `Your details are publicly available together on the Companies House Register and they could be used as ID to apply for credit in your name, for example. ${higherThanAverageFraudRisk}.`,
  dataTheft: `${dataTheft}.`,
  editedElectoralRoll: `Your details have been found on the Edited Electoral Roll meaning your details are freely available online.  You can remove yourself from this but remain on the Electoral Roll. ${higherThanAverageFraudRisk}.`,
  gro: 'We’ve found your details together on the UK government’s General Register Office database. This is nothing to worry about, but it is important you are aware.',
  identityFraud: (detailsDescription = 'details') => `It’s important because you could become a victim of identity fraud. For example, your ${detailsDescription} could be used as ID to apply for credit in your name. ${higherThanAverageFraudRisk}.`,
};
