import styled from 'styled-components';
import { Button, Typography } from '@experian-uk/corvetteuk-common-ui';
import tick from './assets/tick.svg';

export const Benefit = styled.li`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  font-size: 1.125rem;
  padding: 0 0 0.6rem;
  text-align: left;

  ${({ theme: { media } }) => media('15', `
     flex: 1 1 50%;
     font-size: 1.25rem;
     padding: 0 0 1.25rem;

     span {
       max-width: 80%;
     }
  `)}

  ${({ theme: { media } }) => media('30', `
     flex: 1 1 50%;
     font-size: 1.25rem;
     padding: 0 0 1.25rem;

     span {
       max-width: 100%;
     }
  `)}

  &:before {
    content: url('${tick}');
    flex: 1 1 auto;
    height: 16px;
    max-width: 16px;
    width: 16px;
  }

  span {
    flex: 10;
    margin-left: 0.75rem;
  }
`;

export const BenefitsList = styled.ul`
  display: flex;
  flex: 2 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: 1rem 0 0.4rem;
  padding: 0;

  ${({ theme: { media } }) => media('15', `
     flex-direction: row;
     margin: 2.5rem 0 1.25rem 0.5rem;
  `)}

  ${({ theme: { media } }) => media('20', `
     flex-basis: 75%;
  `)}
`;

export const ButtonContainer = styled.div`
  align-self: center;
  flex: 1 1 auto;
  margin-bottom: 2.1rem;
  min-width: 32%;
  text-align: right;
  width: 100%;

  ${({ theme: { media } }) => media('20', `
     flex-basis: 25%;
  `)}

  ${({ theme: { media } }) => media('30', `
     margin-bottom: 0;
     min-width: auto;
  `)}
`;

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.baseMediumGrey};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;

  ${({ theme: { media } }) => media('20', `
     flex-direction: row;
     padding: 0 2.5rem 0 1.5625rem;
  `)}
`;

export const Header = styled.div`
  ${({ theme: { colors } }) => `
    background: linear-gradient(to right, ${colors.breachLightBlue}, ${colors.breachDarkBlue});
    color: ${colors.baseWhite};
  `}

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem 1.5625rem;

  ${({ theme: { media } }) => media('15', `
     flex-direction: row;
     padding: 1.25rem 2.5rem 1.25rem 2rem;
  `)}
`;

export const HeaderCopy = styled.div`
  text-align: center;

  ${({ theme: { media } }) => media('15', `
     text-align: left;
  `)}
`;

export const HeaderDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0 0 0;
  text-align: center;

  ${({ theme: { media } }) => media('15', `
     align-items: flex-end;
     margin: 0;
     text-align: right;
  `)}
`;

export const ExpiryDate = styled(Typography.Copy.Text)`
  font-size: 1rem;
  line-height: 1.75;
  margin: 0;
  padding: 0;
`;

export const HeaderIntro = styled(Typography.Copy.Text)`
  font-size: 1.25rem;
  margin: 0 0 0.25rem;
  padding: 0;

  ${({ theme: { media } }) => media('15', `
     font-size: 1.25rem;
  `)}
`;

export const HeaderProduct = styled(Typography.Headings.Secondary)`
  color: ${({ theme: { colors } }) => `${colors.baseWhite};`}
  font-size: 1.9375rem;
  margin: 0;
  padding: 0;

  ${({ theme: { media } }) => media('15', `
    font-size: 1.9375rem;
`)}
`;

export const Description = styled(Typography.Headings.Tertiary)`
  color: ${({ theme: { colors } }) => `${colors.baseWhite};`}
  line-height: 1.42;
  margin: 0 0 0.8125rem;
  padding: 0;
`;

const ViewSubscriptionButton = styled(Button)`
  align-self: stretch;
  width: 100%;

  ${({ theme: { media } }) => media('10', `
    align-self: flex-start;
    width: auto;
  `)}
`;
ViewSubscriptionButton.displayName = 'ViewSubscriptionButton';
export { ViewSubscriptionButton };
