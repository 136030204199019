import styled from 'styled-components';
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';

import ovalSVG from './assets/oval.svg';
import shieldSVG from './assets/shield.svg';

const getBackgroundGradient = colors => `linear-gradient(to right, ${colors.experianRaspberry}, ${colors.midPurple} 20%, ${colors.experianDarkPurple})`;

export const Container = styled.div`
  text-align: center;
  border-radius: 0.25rem;
  background-image: ${({ theme }) => getBackgroundGradient(theme.colors)};
  position: relative;
  padding: 0.625rem 0.875rem;
  
  &:after {
    content: url(${ovalSVG});
    height: 76px;
    width: 76px;
    position: absolute;
    right: 8px;
    top: 10px;
    
    ${({ theme: { media } }) => media('20', `
      height: 116px;
      width: 116px;
      right: 30px;
      top: 30px;
    `)}
  }
  
  &:before {
    content: url(${shieldSVG});
    height: 40px;
    width: 36px;
    position: absolute;
    right: 28px;
    top: 29px;
    z-index: 1;
    
    ${({ theme: { media } }) => media('20', `
      height: 59px;
      width: 52px;
      right: 62px;
      top: 59px;
    `)}
  }
  
  flex-basis: auto;
  max-width: 100%;

  ${({ theme: { media } }) => media('15', `
    width: 90%;
    padding: 1.875rem;
    text-align: left;
    margin: 1.875rem auto 0.625rem;
  `)}

  ${({ theme: { media } }) => media('30', `
    max-width: 1140px;
    width: 100%;
  `)}
 
`;

export const Content = styled(Typography.Copy.Text)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.baseWhite};
  
  width: 80%;
  text-align: left;
  
  ${({ theme: { media } }) => media('15', `
     width: 100%;
     font-size: 1rem;
  `)}
`;

export const Heading = styled(Typography.Headings.Tertiary)`
  font-size: 1.25rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.baseWhite};
  
  width: 70%;
  text-align: left;
  margin-top: 0;
  
  ${({ theme: { media } }) => media('15', `
     width: 100%;
     font-size: 1.5rem;
     margin-bottom: 0;
     line-height: 1.5rem;
  `)}
`;

export const StyledLink = styled(Link.Anchor)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.baseWhite};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.baseWhite};
  font-size: 1rem;
  padding: 0.9375rem 0;
  height: 50px;
  width: 100%;
  vertical-align: middle;
  line-height: 1rem;
  margin: auto;
  text-align: center;
  display: inline-block;
  
  &:hover, &:focus, &:active {
    color: ${({ theme }) => theme.colors.baseWhite};
    box-shadow: 0 0 0 0.25rem rgba(255,255,255,0.4);
    text-decoration: none;
  }
  
  ${({ theme: { media } }) => media('15', `
     width: 140px;
     padding: 0.5625rem 0;
     height: 37px;
  `)}
`;
