import { alertValues } from '../../../constants';

const { alertsDescriptionKeys } = alertValues;

export default {
  56: {
    descriptionKey: alertsDescriptionKeys.missed_payment,
  },
  57: {
    descriptionKey: alertsDescriptionKeys.two_missed_payments,
  },
  58: {
    descriptionKey: alertsDescriptionKeys.three_missed_payments,
  },
  59: {
    descriptionKey: alertsDescriptionKeys.defaulted_account,
  },
  60: {
    descriptionKey: alertsDescriptionKeys.activated_account,
  },
  61: {
    descriptionKey: alertsDescriptionKeys.payments_made_on_account,
  },
  65: {
    descriptionKey: alertsDescriptionKeys.payment_history_or_account_status_change,
  },
  75: {
    descriptionKey: alertsDescriptionKeys.caps,
    shouldShowReportLink: true,
    type: alertValues.types.fraud,
  },
  80: {
    descriptionKey: alertsDescriptionKeys.cais,
    type: alertValues.types.fraud,
  },
  81: {
    descriptionKey: alertsDescriptionKeys.cais_added,
    type: alertValues.types.fraud,
  },
  82: {
    descriptionKey: alertsDescriptionKeys.cais_removed,
    type: alertValues.types.fraud,
  },
  90: {
    descriptionKey: alertsDescriptionKeys.public,
  },
  91: {
    descriptionKey: alertsDescriptionKeys.public_added,
  },
  92: {
    descriptionKey: alertsDescriptionKeys.public_removed,
  },
  100: {
    descriptionKey: alertsDescriptionKeys.instant_alert,
    type: alertValues.types.fraud,
  },
};
