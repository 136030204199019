import fetch from '../fetch';

export const FETCH_LATEST_REPORT = 'FETCH_LATEST_REPORT';
export const LATEST_REPORT_FETCHED = 'LATEST_REPORT_FETCHED';

export default () => async (dispatch) => {
  dispatch({ type: FETCH_LATEST_REPORT });

  try {
    const report = await dispatch(fetch('/reports'));
    return dispatch({
      type: LATEST_REPORT_FETCHED,
      error: false,
      payload: report,
    });
  } catch (e) {
    return dispatch({
      type: LATEST_REPORT_FETCHED,
      error: true,
      payload: new Error('Error in fetch from report service.'),
    });
  }
};
