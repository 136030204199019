import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter, Route } from 'react-router';
import PropTypes from 'prop-types';
import { AnimatedLogo } from '@experian-uk/corvetteuk-common-ui';
import { FullPageContainer } from './components/Container';
import Routes from './pages';
import { applicationStarted as applicationStartedAction } from './actions/app';
import establishCurrentUserAction from './actions/auth/establishCurrentUser';

const App = ({
  applicationStarted,
  establishCurrentUser,
  hasAppStarted,
}) => {
  useEffect(() => {
    window.$ECD2 = window.$ECD2 || [];

    applicationStarted();
    establishCurrentUser();
  }, []);

  return (
    <div id="app">
      {
        hasAppStarted
          ? <Routes />
          : <Route component={() => <FullPageContainer><AnimatedLogo /></FullPageContainer>} />
      }
    </div>
  );
};

App.propTypes = {
  applicationStarted: PropTypes.func.isRequired,
  establishCurrentUser: PropTypes.func.isRequired,
  hasAppStarted: PropTypes.bool.isRequired,
};

const mapReduxStateToProps = store => ({
  hasAppStarted: store.app.hasStarted,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  applicationStarted: applicationStartedAction,
  establishCurrentUser: establishCurrentUserAction,
}, dispatch);

export default compose(
  withRouter,
  connect(mapReduxStateToProps, mapDispatchToProps),
)(App);
