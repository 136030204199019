import { productIdentifiers } from '../constants';
import { getEnv } from '../components/Context/env';

export default (product, isFreeTrial = false, env = getEnv()) => {
  if (product === productIdentifiers.credit_expert || isFreeTrial) {
    return env.REACT_APP_INS_URL;
  }
  if (product === productIdentifiers.identity) {
    return '/';
  }
  return env.REACT_APP_C2_URL;
};
