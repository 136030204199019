/* istanbul ignore file */
import PropTypes from 'prop-types';
import { alertValues } from '../constants';

export default {
  account: PropTypes.shape({
    type: PropTypes.string,
    company: PropTypes.string,
  }),
  category: PropTypes.string,
  code: PropTypes.string,
  descriptionKey: PropTypes.string,
  id: PropTypes.string,
  isUnread: PropTypes.bool,
  isUrgent: PropTypes.bool,
  lastUpdatedOn: PropTypes.string,
  search: PropTypes.shape({
    applicationType: PropTypes.string,
    searchedBy: PropTypes.string,
    searchedOn: PropTypes.string,
  }),
  type: PropTypes.oneOf(Object.keys(alertValues.types)),
  value: PropTypes.shape({}),
};
