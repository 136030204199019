import Cookies from 'js-cookie';

import isServer from './isServer';

export default async (logoutUrl) => {
  if (!isServer()) {
    try {
      await fetch('/graphql', {
        body: JSON.stringify({ query: '{ logout }' }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        credentials: 'same-origin',
      });
    } catch (e) {
      // throw away error, confirmed by partnerships to logout anyway.
    }

    Cookies.remove('brandIdentifier'); // Cleans up local-only cookie, has no effect in environment
    window.location = logoutUrl;
  }
};
