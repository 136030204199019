import fetch from '../fetch';

export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const endpoint = 'communicationprofile/profiles';
export const argumentValidationError = 'For each object, Medium must be supplied, e.g. SMS or Email, and the value of optedIn must be "true" or "false"';
const validCategories = ['Alerts', 'Marketing'];
const validMedia = ['Email', 'SMS'];

export default profileUpdates => async (dispatch, getState) => {
  if (!profileUpdates || profileUpdates.length < 1) {
    return Promise.reject(new Error(argumentValidationError));
  }

  const validatedUpdates = profileUpdates.filter(profileUpdate => (
    validCategories.includes(profileUpdate.category)
    && validMedia.includes(profileUpdate.medium)
    && (typeof (profileUpdate.optedIn) === 'boolean')
  ));

  if (profileUpdates.length < 1) {
    return Promise.reject(new Error(argumentValidationError));
  }

  const { brand } = getState();

  const requestBody = validatedUpdates.map(validatedUpdate => ({
    brand,
    ...validatedUpdate,
  }));

  dispatch({ type: UPDATE_PROFILE });

  try {
    const response = await dispatch(fetch(`/${endpoint}`, {
      method: 'PUT',
      body: requestBody,
    }, false, null, true));

    const type = (response.data[0] === true) ? PROFILE_UPDATED : PROFILE_UPDATE_FAILED;

    return dispatch({
      type,
    });
  } catch (e) {
    return dispatch({
      type: PROFILE_UPDATE_FAILED,
      payload: e,
    });
  }
};
