import React, { Fragment } from 'react';
import SecurityPanel from '../security';
import KeepSafePanel from '../keepSafe';

const PasswordNotRecognisedPage = () => (
  <Fragment>
    <SecurityPanel selected={false} />
    <KeepSafePanel hasCredentials />
  </Fragment>
);

export default PasswordNotRecognisedPage;
