export const type = 'UIChangeState';

export default (page, payload, meta) => ({
  type,
  payload,
  meta: {
    ...meta,
    page,
  },
});
