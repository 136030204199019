import dayjs from 'dayjs';

import { dateFormat } from '../constants';
import pluralise from './pluralise';

const format = (date, fmt, dflt) => (date ? dayjs(date).format(fmt) : dflt);

export const defaultFormat = (date, dflt) => format(date, dateFormat.defaultFormat, dflt);
export const dayMonthAndYearFormat = (date, dflt) => format(date, dateFormat.dayMonthYearFormat, dflt);
export const monthAndYearFormat = (date, dflt) => format(date, dateFormat.monthYear, dflt);
export const countDown = date => dayjs(date).endOf('day').diff(dayjs().startOf('day'), 'days');
export const monthsToYearAndMonths = months => {
  const year = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return `${year} ${pluralise(year, 'year', 'years')} ${remainingMonths} ${pluralise(
    remainingMonths,
    'month',
    'months'
  )}`;
};
