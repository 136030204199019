import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'connected-react-router';
import {
  APP_STARTED,
  LOADING,
  SHOULD_SHOW_CUSTOMER_REF_DETAILS,
} from '../actions/app';

const matchPath = pathName => (pathName.search('/report/') !== -1 ? '/report' : pathName);

export const initialState = {
  currentPath: '/',
  showCustomerRefDetails: false,
  isLoading: false,
  hasStarted: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_STARTED:
      return {
        ...state,
        hasStarted: true,
      };
    case CALL_HISTORY_METHOD:
    case LOCATION_CHANGE:
      return {
        ...state,
        currentPath: matchPath(action.payload.location.pathname),
        showCustomerRefDetails: false,
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SHOULD_SHOW_CUSTOMER_REF_DETAILS:
      return {
        ...state,
        showCustomerRefDetails: true,
      };
    default:
      return state;
  }
};

export const applicationStarted = () => dispatch => (
  dispatch({
    type: APP_STARTED,
    hasStarted: true,
  })
);

export const setApplicationLoading = isLoading => dispatch => (
  dispatch({
    type: LOADING,
    isLoading,
  })
);

export const shouldShowCustomerRefDetails = () => dispatch => (
  dispatch({
    type: SHOULD_SHOW_CUSTOMER_REF_DETAILS,
  })
);
