export const ELIGIBILITY_DETERMINED = 'ELIGIBILITY_DETERMINED';

export default (key, eligible = false) => async (dispatch) => {
  if (!key) {
    return null;
  }
  return dispatch({
    type: ELIGIBILITY_DETERMINED,
    payload: {
      [key]: eligible,
    },
  });
};
