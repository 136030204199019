import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import { TextInput } from '../components/TextInput';
import { InlineAlert } from '../components/Layout/ErrorMessage/index.styles';
import getDynamicString from './getDynamicString';

export default Component =>
  ({ input, ...rest }) => <Component {...rest} {...input} {...input.props} />; // eslint-disable-line

const Input = styled(TextInput)`
  flex: 1;

  width: 100%;
  max-width: 100%;
`;

Input.displayName = 'Input';

const StyledDiv = styled.div`
  flex-grow: 1;
  ${props =>
    props.theme.media(
      '20',
      `
    max-width: 500px;
  `
    )}
`;

const ErrorMessage = styled(InlineAlert)`
  ${({ stretchErrorFrom, theme }) =>
    stretchErrorFrom &&
    theme.media(
      stretchErrorFrom,
      `
    position: absolute;
  `
    )}
`;

// Separate implementation required for text Inputs because the generic 'Component' function
// causes re-rendering which means the input will lose focus as soon as the user starts typing
// This is not a problem for other input types such as Selects.
const textInput = ({
  applyError,
  automation,
  disabled,
  errorMessageProps,
  input,
  inputProps,
  meta: { touched, error },
}) => (
  <StyledDiv>
    <Input
      {...input}
      {...inputProps}
      {...setAutomationElement(automation)}
      disabled={disabled}
      hasError={(touched && error) || applyError}
    />
    {touched && error && (
      <ErrorMessage stretchErrorFrom={inputProps.stretchErrorFrom} {...setAutomationElement(`${automation}Validation`)}>
        {errorMessageProps && errorMessageProps[error]
          ? getDynamicString(`forms.${error}`, errorMessageProps[error])
          : error}
      </ErrorMessage>
    )}
    {applyError && (
      <ErrorMessage stretchErrorFrom={inputProps.stretchErrorFrom} {...setAutomationElement(`${automation}Error`)}>
        {applyError}
      </ErrorMessage>
    )}
  </StyledDiv>
);

textInput.propTypes = {
  applyError: PropTypes.string,
  automation: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessageProps: PropTypes.shape({}),
  input: PropTypes.shape({}),
  inputProps: PropTypes.shape({
    stretchErrorFrom: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

textInput.defaultProps = {
  applyError: null,
  automation: '',
  errorMessageProps: null,
  disabled: false,
  input: {},
  inputProps: {},
  meta: {
    touched: false,
    error: '',
  },
};

const hiddenInput = ({ input, inputName }) => <input type="hidden" name={inputName} {...input} />;

hiddenInput.propTypes = {
  input: PropTypes.shape({}),
  inputName: PropTypes.shape({}),
};

hiddenInput.defaultProps = {
  input: {},
  inputName: {},
};

export { Input, textInput, hiddenInput };
