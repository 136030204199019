import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './index.styles';
import { notificationTypes } from '../../constants';

const NotificationTile = ({
  content,
  notificationType,
}) => (
  <Container
    notificationType={notificationType}
  >
    {content}
  </Container>
);

NotificationTile.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  notificationType: PropTypes.oneOf(Object.values(notificationTypes)),
};

NotificationTile.defaultProps = {
  notificationType: notificationTypes.info,
};

NotificationTile.displayName = 'NotificationTile';

export default NotificationTile;
