import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import Cookies from 'js-cookie';

import rootReducer from './reducers/rootReducer';
import fetchMiddleware from './middleware/fetch';
import splitMiddleware from './middleware/split';
import appError from './middleware/appError';
import ecd from './middleware/serializeEcd';
import { getEnv } from './components/Context/env';
import isServer from './helpers/isServer';

export default (url = '/', csrf = '') => {
  const env = getEnv();
  // Create a history depending on the environment
  const history = isServer()
    ? createMemoryHistory({
      initialEntries: [url],
    })
    : createBrowserHistory();

  const enhancers = [];

  // Dev tools are helpful
  if (!isServer() && env.REACT_APP_ENV !== 'prod') {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const middleware = [
    thunkMiddleware,
    fetchMiddleware,
    splitMiddleware(env, isServer()),
    ecd(() => {
      if (isServer()) {
        return {};
      }

      return window;
    }),
    appError('/error?error=uncaughtError'),
    routerMiddleware(history),
  ];

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  // Do we have preloaded state available? Great, save it.
  let initialState = !isServer() ? window.INITIAL_STATE || {} : {};

  const csrfToken = !isServer() ? window.CSRF_TOKEN || '' : csrf;
  initialState = {
    ...initialState,
    auth: {
      ...initialState.auth,
      fetching: false,
      csrfToken,
    },
  };

  // Delete it once we have it stored in a variable
  if (!isServer()) {
    delete window.INITIAL_STATE;
    delete window.CSRF_TOKEN;
    initialState.brand = Cookies.get('brandIdentifier') || 'creditmatcher';
  }

  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancers,
  );

  return {
    store,
    history,
  };
};
