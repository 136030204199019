import styled from 'styled-components';

export const SectionText = styled.div`
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 0.5rem 0;

  ${({ theme: { media } }) => media('10', `
    width: 50%;
  `)}

`;

export const SectionTextBold = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 30px;
  margin: 0.5rem 0;

  ${({ theme: { media } }) => media('10', `
    width: 50%;
  `)}

`;
