import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { NeutralHeavyLozengeBadge } from '@nebula/badge';
import { HeadingMedium700 } from '@nebula/global-styles';
import ArrowIconSVG from './assets/arrowIcon.svg';

export const InstantAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 100%;
`;

export const StyledHeaderTwo = styled.h2`
  ${HeadingMedium700}
  color: ${props => props.theme.color.ui.blueGrey900};
`;
export const StyledLozenge = styled(NeutralHeavyLozengeBadge)``;

export const StyledAnchor = styled(Link)`
  font-size: 14px;
  cursor: pointer;
`;

export const Data = styled.div`
  color: ${props => props.theme.color.ui.blueGrey600};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.006rem;
  font-weight: 700;
`;

export const AlertDetailsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

export const AlertDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 24px 0;
  align-self: center;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin: 0 0 32px 0;
  }

  @media ${({ theme }) => theme.media.smallDesktopMin} {
    max-width: 1152px;
  }
`;

export const AlertDetailsHeaderTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  text-align: center;
  word-break: normal;
  overflow-wrap: anywhere;
`;

export const AlertDetailsSubheader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const AlertDetailsDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 0px;
  word-break: normal;
  overflow-wrap: anywhere;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin: 16px 0px 8px 0px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 21px 14px 59px;
  position: static;
  height: 100%;
  left: 0px;
  top: 333px;
  background: ${props => props.theme.color.ui.blueGrey50};
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  align-items: center;
`;

export const ContentHeader = styled.div`
  margin: ${rem(40)} 0 0 0;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    order: -1;
    width: 100%;
    margin: ${rem(56)} 0 0 0;
  }
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin: ${rem(56)} 0 0 0;
  }
`;

export const RecogniseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  flex-grow: 0;
  width: 100%;

  @media ${({ theme }) => theme.media.smallDesktopMin} {
    max-width: 1152px;
  }
`;

export const ArrowIcon = styled.div`
  content: url(${ArrowIconSVG});
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const FeedbackWrapper = styled.div`
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex: none;
  width: 100%;

  @media ${({ theme }) => theme.media.smallDesktopMin} {
    max-width: 1152px;
  }
`;

export const FeedbackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: none;
  flex-grow: 0;
  margin: ${rem(16)} 0 ${rem(56)} 0;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin: ${rem(16)} 0 ${rem(26)} 0;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
  }

  @media ${({ theme }) => theme.media.smallDesktopMin} {
    max-width: 1152px;
  }
`;
export const MainCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    flex-direction: row;
  }
`;

export const AlertDetailsDescriptionHeader = styled.div`
  display: flex;
  text-align: center;
`;

export const AlertDetailsIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  padding: 16px 14px 24px;
`;

export const ActionCardsWrapper = styled.div`
  margin: 8px 0 0 0;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ArrowIconWrapper = styled.div`
  margin-right: 15px;
`;

export const AlertFeedbackWrapper = styled.div`
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex: none;
  margin-top: ${rem(26)};
  width: 100%;

  @media ${({ theme }) => theme.media.smallDesktopMin} {
    max-width: 1152px;
  }
`;
