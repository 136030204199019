import fetch from '../fetch';
import initialize from '../ecd/initialize';

export const FETCH_BILLING = 'FETCH_BILLING';
export const BILLING_FETCHED = 'BILLING_FETCHED';
export const BILLING_FETCH_FAILED = 'BILLING_FETCH_FAILED';

export const endpoint = 'billingsubscriptions';
export const errorMessage = `Could not fetch ${endpoint}`;

export default () => async (dispatch) => {
  dispatch({ type: FETCH_BILLING });
  try {
    const billing = await dispatch(fetch(`/${endpoint}`,
      {},
      false,
      null,
      true));

    dispatch({
      type: BILLING_FETCHED,
      payload: { ...billing.data[0] },
    });

    return dispatch(initialize());
  } catch (error) {
    return dispatch({
      type: BILLING_FETCH_FAILED,
      payload: errorMessage,
    });
  }
};
