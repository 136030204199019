import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const LOOKUP_ADDRESS = 'LOOKUP_ADDRESS';
export const LOOKUP_ADDRESS_SUCCESSFUL = 'LOOKUP_ADDRESS_SUCCESSFUL';
export const LOOKUP_ADDRESS_UNSUCCESSFUL = 'LOOKUP_ADDRESS_UNSUCCESSFUL';

export default (postcode, house = '', count = 500) => async (dispatch) => {
  if (!postcode) {
    return Promise.reject(new Error('Postcode must be supplied'));
  }

  dispatch({ type: LOOKUP_ADDRESS });

  return dispatch(fetch(`/addresses?postcode=${postcode.replace(/ /g, '')}&house=${house.replace(/ /g, '')}&count=${count}`, {}, false, null, true))
    .then((response) => {
      const { data, message, status } = response;
      if (status && status >= 400) {
        throw new Error(message);
      }

      return dispatch({
        type: LOOKUP_ADDRESS_SUCCESSFUL,
        error: false,
        payload: data.filter(address => (address.id && address.text)),
      });
    })
    .catch(() => dispatch({
      type: LOOKUP_ADDRESS_UNSUCCESSFUL,
      error: true,
      payload: errorMessages.addresses.lookup,
    }));
};
