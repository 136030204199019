import {
  FETCH_PROFILE,
  PROFILE_FETCH_FAILED,
  PROFILE_FETCHED,
} from '../actions/communicationProfile/getProfile';
import {
  PROFILE_UPDATED,
  PROFILE_UPDATE_FAILED,
  UPDATE_PROFILE,
} from '../actions/communicationProfile/updateProfile';

const defaultState = {
  error: null,
  fetched: false,
  loading: false,
};

export const errorMessages = {
  get: 'We were unable to retrieve your preferences, please try again later.',
  update: 'We were unable to update your preferences, please try again later.',
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...state,
        fetched: false,
        loading: true,
      };
    case PROFILE_FETCH_FAILED:
      return {
        ...state,
        error: {
          blocking: true,
          message: errorMessages.get,
        },
        loading: false,
      };
    case PROFILE_FETCHED:
      return {
        ...state,
        fetched: true,
        loading: false,
        profile: action.payload.filter(current => current.category === 'Alerts').reduce((obj, current) => ({
          ...obj,
          [current.medium]: current.optedIn,
        }), {}),
      };
    case PROFILE_UPDATED:
      return {
        ...state,
        loading: false,
      };
    case PROFILE_UPDATE_FAILED:
      return {
        ...state,
        error: {
          blocking: false,
          message: errorMessages.update,
        },
        loading: false,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    default:
      return state;
  }
};
