import { products } from '../../../constants';
import isServer from '../../../helpers/isServer';

const privacyPolicy = privacyUrl => ({
  title: 'Privacy Policy',
  href: privacyUrl,
  automationKey: 'footerPrivacy',
});

const cookiesPolicy = cookiesUrl => ({
  title: 'Cookies & Online Marketing',
  href: cookiesUrl,
  onClick: (e) => {
    if (!isServer() && window.gateway) {
      e.preventDefault();
      window.gateway.openModal();
    }
  },
  automationKey: 'footerCookies',
});

const termsConditions = termsConditionsUrl => ({
  title: 'Terms & Conditions',
  href: termsConditionsUrl,
  automationKey: 'footerTerms',
});

const statementOfRights = statementOfRightsUrl => ({
  title: 'Statement of Rights',
  href: statementOfRightsUrl,
  automationKey: 'footerStatement',
});

const contactUs = contactUsUrl => ({
  title: 'Contact Us',
  href: contactUsUrl,
  automationKey: 'footerContactUs',
});

const marketingServices = experianUrl => ({
  title: 'Experian Marketing Services',
  href: `${experianUrl}/privacy/consumer-information-portal`,
  automationKey: 'footerMarketingServices',
});

export default (family, {
  contactUsUrl,
  cookiesUrl,
  experianUrl,
  privacyUrl,
  statementOfRightsUrl,
  termsConditionsUrl,
}) => {
  const exclusionMap = {
    [products.breach]: ['footerStatement'],
  };

  const linksToExclude = exclusionMap[family] || [];

  return [
    privacyPolicy(privacyUrl),
    cookiesPolicy(cookiesUrl),
    termsConditions(termsConditionsUrl),
    statementOfRights(statementOfRightsUrl),
    contactUs(contactUsUrl),
    marketingServices(experianUrl),
  ].filter(link => !linksToExclude.includes(link.automationKey));
};
