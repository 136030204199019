import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { setAutomationElement, SubNavLink } from '@experian-uk/corvetteuk-common-ui';

const IdentitySubNavLink = styled(SubNavLink.withComponent(NavLink))`
  text-decoration: none;
`;

const customSubNavRender = ({ activeLink, isExpanded, isFinalLink, link }) => (
  <IdentitySubNavLink
    isactive={link.id === activeLink ? 1 : 0}
    isexpanded={isExpanded ? 1 : 0}
    isfinallink={isFinalLink ? 1 : 0}
    key={link.id}
    title={link.title || link.label}
    to={link.href}
    {...setAutomationElement(link.automationKey)}
  >
    {link.label}
  </IdentitySubNavLink>
);

customSubNavRender.propTypes = {
  activeLink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isExpanded: PropTypes.bool,
  isFinalLink: PropTypes.bool,
  link: PropTypes.shape({
    automationKey: PropTypes.string,
    href: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
};

customSubNavRender.defaultProps = {
  activeLink: '',
  isExpanded: false,
  isFinalLink: false,
};

export default customSubNavRender;
