import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import Secure from './assets/secure.svg';

const iconMap = {
  secure: Secure,
};

export const StyledHeading = styled(Typography.Headings.Secondary)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;

  ${({ iconKey, theme }) => (iconKey && iconMap[iconKey]) && `
    &:before {
      content: url(${iconMap[iconKey]});
      width: 35px;
      height: 35px;

      ${theme.media('15', `
        width: 41px;
        height: 41px;
        display: inline-block;
      `)}
    }
  `}

  ${({ theme }) => theme.media('15', `
    flex-direction: row;
  `)}
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.baseBackgroundBlue};
  flex: 1 1 auto;
  margin-top: 1.25rem;
  padding: 1.875rem;
  text-align: center;

  ${({ theme: { media } }) => media('20', `
     margin-top: 2.5rem;
  `)}
`;
