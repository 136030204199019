import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Breadcrumbs,
  Header,
  Logo,
  setAutomationElement,
} from '@experian-uk/corvetteuk-common-ui';
import customSubNavRender from './IdentityNavLink';
import getCustomUtils from './CustomUtils';

import { Container, CustomerRefDetails } from '../../';

import getAlertCount from '../../../actions/alerts/getCount';
import { withEnv, envPropTypes } from '../../Context/env';
import getNavLinks from './NavLinks';
import { productIdentifiers, splitsList } from '../../../constants';
import getBreadcrumbs, { homeBreadcrumb } from './Breadcrumbs';
import { getSplitStatus } from '../../../reducers/split';
import getCppEligibility from '../../../actions/auth/getCppEligibility';

const StyledHeader = styled(Header)`
  > div, > nav {
    ${({ theme }) => theme.media('30', `
      max-width: ${theme.base.largeContainerWidth};
      margin: 0 auto;
    `)}
  }
`;

const BreadcrumbContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0;

  ${({ theme: { media } }) => media('15', `
    padding: 0;
  `)}
`;

const getCurrentNavState = (currentPath, subNav = []) => {
  const activeSubNavElement = subNav.find(navLink => navLink.href === currentPath);
  return {
    activeLinkCoreNav: '',
    activeLinkSubNav: activeSubNavElement ? activeSubNavElement.id : '',
    activeUrl: currentPath,
  };
};

const customLogo = () => (
  <NavLink to="/" {...setAutomationElement('headerLogoLink')} aria-label="Home">
    <Logo />
  </NavLink>
);

const headerSplits = [
  splitsList.scoreComponents,
  splitsList.scoreHistory,
  splitsList.boostOnline,
  splitsList.boostPlacement,
];

@withEnv
@connect(
  state => ({
    alerts: state.alerts,
    boostStatus: state.boost.data,
    breadcrumbs: getBreadcrumbs(state.app.currentPath),
    currentPath: state.app.currentPath,
    currentUser: state.auth.currentUser,
    productDetails: state.productDetails,
    showCustomerRefDetails: state.app.showCustomerRefDetails,
    splits: getSplitStatus(state.split, headerSplits),
    subscriptionDetails: state.subscriptions,
  }),
  dispatch => bindActionCreators({
    getAlertCount,
    getCppEligibility,
  }, dispatch),
)
export default class IdentityHeader extends React.Component {
  static propTypes = {
    alerts: PropTypes.shape({
      unread: PropTypes.number,
      error: PropTypes.bool.isRequired,
    }).isRequired,
    boostStatus: PropTypes.shape({
      hasProposition: PropTypes.bool,
    }),
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    })),
    currentPath: PropTypes.string,
    currentUser: PropTypes.shape({
      customerNumber: PropTypes.string,
      cppPolicyNumber: PropTypes.string,
    }),
    env: envPropTypes.isRequired,
    getAlertCount: PropTypes.func.isRequired,
    getCppEligibility: PropTypes.func.isRequired,
    productDetails: PropTypes.shape({
      isBreach: PropTypes.bool,
      isCpp: PropTypes.bool,
      isFreeTrial: PropTypes.bool,
      isPartner: PropTypes.bool,
      homepageUrl: PropTypes.string,
      productIdentifier: PropTypes.string,
    }).isRequired,
    showCustomerRefDetails: PropTypes.bool,
    splits: PropTypes.shape({}),
    subscriptionDetails: PropTypes.shape({}),
  };

  static defaultProps = {
    breadcrumbs: null,
    boostStatus: null,
    currentPath: '',
    currentUser: {},
    showCustomerRefDetails: false,
    splits: {},
    subscriptionDetails: {},
  };

  componentDidMount() {
    this.props.getAlertCount();
    if (this.props.productDetails.isCpp) {
      this.props.getCppEligibility();
    }
  }

  render() {
    const {
      alerts,
      boostStatus,
      breadcrumbs,
      productDetails,
      showCustomerRefDetails,
      splits,
      subscriptionDetails,
    } = this.props;

    const { customerNumber } = this.props.currentUser || { customerNumber: '' };
    const { cppPolicyNumber } = this.props.currentUser;
    const {
      REACT_APP_C2_URL,
      REACT_APP_ENV,
      REACT_APP_INS_URL,
    } = this.props.env;
    const {
      isBreach,
      isCpp,
      isFreeTrial,
      isPartner,
      homepageUrl,
      productIdentifier,
    } = productDetails;
    let subscription;

    // Type check required to differentiate between a 200 response indicating a user is not a partner and an error response from PI Service
    if (isPartner === false) {
      subscription = isFreeTrial ? productIdentifiers.credit_expert : productIdentifier;
    }
    const {
      auth,
      core,
      sub,
      userMenu,
    } = getNavLinks(this.props.env, subscription, splits, isPartner, isBreach, isCpp, subscriptionDetails);

    const currentNavState = getCurrentNavState(this.props.currentPath, sub);

    const { customUtils, skipUtils } = getCustomUtils(isCpp, alerts, this.props.currentPath, this.props.env);

    const shouldUseCustomHomeLinks = ![productIdentifiers.credit_expert].includes(subscription);

    return (
      <Fragment>
        <StyledHeader
          alerts={this.props.alerts}
          authLinkConfig={auth}
          c2Url={REACT_APP_C2_URL}
          contactUrl={`${REACT_APP_INS_URL}/contact`}
          currentNavState={currentNavState}
          customerNumber={isCpp ? cppPolicyNumber : customerNumber}
          customUtils={customUtils}
          env={REACT_APP_ENV}
          customerNumberLabel={isCpp ? 'Policy number' : undefined}
          hideHeaderMessages={false}
          isBoostEnrolled={splits[splitsList.boostOnline] === 'beta' && boostStatus && boostStatus.hasProposition}
          logoRender={shouldUseCustomHomeLinks ? customLogo : undefined}
          logoUrl={homepageUrl}
          secureMode={false}
          skipUtils={skipUtils}
          subNavLinks={sub}
          subNavRender={subscription ? undefined : customSubNavRender}
          coreNavLinks={core}
          userMenuLinks={userMenu}
        />
        {breadcrumbs && breadcrumbs.length > 0 && (
          <BreadcrumbContainer>
            <Breadcrumbs home={shouldUseCustomHomeLinks ? homeBreadcrumb : homepageUrl} steps={breadcrumbs} />
            {showCustomerRefDetails && (
              <CustomerRefDetails
                buttonConfig={{
                  automationKey: 'contactUs',
                  href: `${REACT_APP_INS_URL}/contact`,
                  label: 'Contact us',
                  type: 'primary',
                }}
                customerNumber={isCpp ? cppPolicyNumber : customerNumber}
              />
            )}
          </BreadcrumbContainer>
        )}
      </Fragment>
    );
  }
}
