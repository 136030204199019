import { v4 as uuidv4 } from 'uuid';
import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const WMDATA_ADD_FACT_LOADING = 'WMDATA_ADD_FACT_LOADING';
export const WMDATA_ADD_FACT_UNSUCCESSFUL = 'WMDATA_ADD_FACT_UNSUCCESSFUL';
export const WMDATA_ADD_FACT_SUCCESSFUL = 'WMDATA_ADD_FACT_SUCCESSFUL';

export default (fact, factKey, customerId, tenantId) => async dispatch => {
  if (!fact) {
    return Promise.reject(new Error('Fact must be supplied'));
  }

  dispatch({
    payload: factKey,
    type: WMDATA_ADD_FACT_LOADING,
  });

  return dispatch(
    fetch(
      `/wmdata/customer/${customerId}/data?tenantId=${tenantId}`,
      {
        method: 'POST',
        body: fact,
      },
      false,
      null,
      true
    )
  )
    .then(response => {
      const { data } = response;
      const { status, message } = data[0];
      if (status && status >= 400) {
        throw new Error(`Could not update web monitoring settings. ${message}`);
      }

      return dispatch({
        type: WMDATA_ADD_FACT_SUCCESSFUL,
        error: false,
        payload: {
          fact: {
            ...fact,
            id: uuidv4(),
          },
          factKey,
        },
      });
    })
    .catch(() =>
      dispatch({
        type: WMDATA_ADD_FACT_UNSUCCESSFUL,
        error: true,
        payload: errorMessages.updateFailed,
      })
    );
};
