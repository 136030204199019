import baseConfig from './baseConfig';
import getAuth from './getAuth';
import linkMap from './linkMap';
import marketplaceLinks from './marketplaceLinks';
import { getEnv } from '../../../Context/env';
import { fixedUrls, productIdentifiers } from '../../../../constants';
import cppLogout from '../../../../helpers/cppLogout';

export default (env = getEnv(), subscription = '', splits = {}, isPartner, isBreach, isCpp, subscriptionDetails = {}) => {
  let product = subscription;
  if (isBreach) {
    product = isPartner ? productIdentifiers.breach : productIdentifiers.breachPlus;
  }
  if (isCpp) {
    product = productIdentifiers.cpp;
  }
  const config = linkMap(env, splits)[product] || {};
  const defaultConfig = baseConfig(env);
  const userProfileMenu = config.userMenu || defaultConfig.userMenu;
  let coreLinks = config.core || defaultConfig.core;
  // Type check required to differentiate between a 200 response indicating a user is not a partner and an error response from PI Service
  if (isPartner === false) {
    coreLinks = [
      ...coreLinks,
      ...marketplaceLinks(env.REACT_APP_C2_URL),
    ];
  }
  const authConfig = getAuth(env.REACT_APP_C2_URL);
  if (isCpp) {
    authConfig.href = fixedUrls.cppAuth;
    authConfig.onClickAction = async (e) => {
      e.preventDefault();
      await cppLogout(fixedUrls.cppAuth);
    };
  }

  const subNavLinks = config.sub || defaultConfig.sub;

  return {
    auth: authConfig,
    core: coreLinks,
    sub: subNavLinks.filter(link => !link.showIf || link.showIf({ splits, subscriptionDetails })),
    userMenu: userProfileMenu.filter(link => !link.showTo || link.showTo.includes(product)),
  };
};
