import React from 'react';
import PropTypes from 'prop-types';
import { Button, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import SpaLink from '../SpaLink';

import {
  Container,
  Content,
  Heading,
} from './index.styles';

const buttonClick = (e, push) => {
  e.preventDefault();
  push('/monitoring/settings');
};

const UpdateInfo = ({ push, shouldShowAlertsSettingsText }) => (
  <Container {...setAutomationElement('updateInfo')}>
    <Heading>Is your information up to date?</Heading>
    <Content>
      It&rsquo;s important you keep all your personal details up to date so we&rsquo;re monitoring the right information online and in your credit report. Then we can better protect you from fraud and identity theft.
    </Content>
    {shouldShowAlertsSettingsText && (
      <Content fontWeight={500} {...setAutomationElement('alerts-settings-notification')}>
        If you wish to receive alerts by text message as well as email, please go to the&nbsp;
        <SpaLink to="/alerts/settings" {...setAutomationElement('alerts-settings-link')}>alerts settings</SpaLink>
        &nbsp;area and select text messaging as an option.
      </Content>
    )}
    <Button
      {...setAutomationElement('whatsMonitoredCTA')}
      isButton
      onClick={e => buttonClick(e, push)}
      label="Check what's being monitored"
      type="secondary"
    />
  </Container>
);

UpdateInfo.propTypes = {
  push: PropTypes.func.isRequired,
  shouldShowAlertsSettingsText: PropTypes.bool,
};

UpdateInfo.defaultProps = {
  shouldShowAlertsSettingsText: false,
};

export default UpdateInfo;
