import fetch from '../fetch';
import validFilters from './validFilters';
import alertsEndpoint from './baseEndpoint';

export const type = 'GET_ALL_ALERTS';

export const fetchType = 'FETCHING_ALERTS';

const allDefaultFilter = 'all';

export default (filter = allDefaultFilter, critical = false) => async (dispatch) => {
  dispatch({
    type: fetchType,
    payload: {
      fetching: true,
    },
  });

  const searchFilter = validFilters.includes(filter) ? filter : allDefaultFilter;

  try {
    const alertCountResponse = await dispatch(
      fetch(`${alertsEndpoint}?filter='search=${searchFilter}'`,
        {},
        false,
        null,
        !critical),
    );
    const payload = alertCountResponse.data;

    return dispatch({
      type,
      payload,
    });
  } catch (e) {
    return dispatch({
      type,
      payload: new Error('Error fetching alerts'),
      error: true,
      meta: { nonCritical: true },
    });
  }
};
