export default {
  activated_account: 'activated_account',
  cais: 'cais',
  cais_added: 'cais_added',
  cais_removed: 'cais_removed',
  caps: 'caps',
  ccno_asp: 'ccno_asp',
  defaulted_account: 'defaulted_account',
  driversl_asp: 'driversl_asp',
  email_asp: 'email_asp',
  email_soc: 'email_soc',
  emailxpostcode_ins: 'emailxpostcode_ins',
  instant_alert: 'instant_alert',
  missed_payment: 'missed_payment',
  namexdob_bmd: 'namexdob_bmd',
  namexpostcode_er: 'namexpostcode_er',
  namexpostcodexdob_ch: 'namexpostcodexdob_ch',
  passportxsurname_asp: 'passportxsurname_asp',
  payment_history_or_account_status_change: 'payment_history_or_account_status_change',
  payments_made_on_account: 'payments_made',
  phone_asp: 'phone_asp',
  phone_soc: 'phone_soc',
  phonexsurname_ins: 'phonexsurname_ins',
  postcode_asp: 'postcode_asp',
  postcodexdob_asp: 'postcodexdob_asp',
  postcodexphone_ins: 'postcodexphone_ins',
  public: 'public',
  public_added: 'public_added',
  public_removed: 'public_removed',
  surnamexbankac_asp: 'surnamexbankac_asp',
  surnamexpostcode_asp: 'surnamexpostcode_asp',
  three_missed_payments: 'three_missed_payments',
  two_missed_payments: 'two_missed_payments',
};
