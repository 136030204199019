import styled from 'styled-components';
import InlineLink from '../InlineLink';

export const ArticleContainer = styled.div`
  padding: 0;

  ${({ theme }) => theme.media('15', `
    padding-left: 0;
    padding-right: 1.875rem;

    &:last-of-type {
      padding-right: 0;
    }
  `)}
`;

export const Container = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.875rem 1.875rem;
  text-align: center;

  ${({ theme }) => theme.media('15', `
    align-items: flex-start;
    max-width: 90%;
    padding: 0;
    text-align: left;
  `)}
`;

export const Img = styled.img`
  margin-bottom: 1rem;
  width: 100%;

  ${({ theme }) => theme.media('15', `
    height: 170px;
  `)}

  ${({ theme }) => theme.media('20', `
    height: 200px;
  `)}

  ${({ theme }) => theme.media('30', `
    height: 240px;
  `)}
`;

export const ArticleLink = styled(InlineLink)`
  display: block;

  ${({ theme }) => theme.media('10', `
    display: inline;
  `)}
`;
