import Cookies from 'js-cookie';

export const STORED_DATA_FETCHED = 'STORED_DATA_FETCHED';

export default (dataStore = Cookies, keysToFetch = []) => dispatch => dispatch({
  type: STORED_DATA_FETCHED,
  payload: keysToFetch.reduce((newValues, key) => {
    const values = newValues;
    values[key] = dataStore.get(key) || 0;
    return values;
  }, {}),
});
