import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const FORMAT_ADDRESS = 'FORMAT_ADDRESS';
export const FORMAT_ADDRESS_SUCCESSFUL = 'FORMAT_ADDRESS_SUCCESSFUL';
export const FORMAT_ADDRESS_UNSUCCESSFUL = 'FORMAT_ADDRESS_UNSUCCESSFUL';

export default (id, errorMessage) => async (dispatch) => {
  if (!id) {
    return Promise.reject(new Error('Address ID must be supplied'));
  }

  dispatch({ type: FORMAT_ADDRESS });

  return dispatch(fetch(`/addresses/${id}`, {}, false, null, true))
    .then((response) => {
      const { data, message, status } = response;
      if (status && status >= 400) {
        throw new Error(message);
      }

      const address = data[0];

      const formattedAddress = {
        city: address.Town,
        country: address.Country,
        county: address.County || address['PNR county'],
        district: address['Dependent locality'],
        houseNumber: address['Building number'] || address['Sub-building number'],
        houseName: address['Building name'] || address.Organisation,
        postCode: address.Postcode,
        street: address.Thoroughfare,
        flat: address['Sub-building name'],
      };

      return dispatch({
        type: FORMAT_ADDRESS_SUCCESSFUL,
        error: false,
        payload: formattedAddress,
      });
    })
    .catch(() => dispatch({
      type: FORMAT_ADDRESS_UNSUCCESSFUL,
      error: true,
      payload: errorMessage || errorMessages.default,
    }));
};
