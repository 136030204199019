import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

export const Container = styled.div`
  text-align: center;
  padding: 1.875rem 0 0;

  ${({ theme: { media } }) => media('20', `
     flex: 1 1 auto;
     margin-left: 2.375rem;
     padding: 0.5rem 0;
     text-align: left;
  `)}
`;

export const Content = styled(Typography.Copy.SmallText)`
  display: block;
  font-weight: ${({ fontWeight }) => (fontWeight || 400)};
  line-height: 1.5;
  padding-bottom: 1rem;
`;

export const Heading = styled(Typography.Headings.Tertiary)`
  font-size: 1.5rem;
  font-weight: 300;
`;
