import { Link, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import React from 'react';

const alertsCapsContent = (applicationType, appTypeQuotation, appTypeIdentification) => {
  if (appTypeQuotation.includes(applicationType) || appTypeIdentification.includes(applicationType)) {
    return (
      <div>
        <b>What is it?</b>
        {appTypeQuotation.includes(applicationType) && (
          <p>When a company checks Experian to provide you with a quote for credit, e.g. a credit card or payment plan for insurance, a quotation search is done.<br />These types of searches can be recorded when using comparison services.</p>
        )}
        {appTypeIdentification.includes(applicationType) && (
          <p>Companies may need to verify your identity to make sure the details they have are correct. These types of searches can be used for a variety of reasons not just for credit. Some examples include transferring money abroad, changing pension plans, using investments and savings accounts.</p>
        )}
        <b>Can this affect my score?</b>
        <p>No. This is a &#39;soft&#39; search, it won&rsquo;t have any impact on your Experian Credit Score or any applications you make.</p>
        If you&rsquo;d like to know more, take a look at our article about searches on your Experian Report <Link.Anchor href="https://www.experian.co.uk/consumer/guides/searches-and-credit-checks.html" inline {...setAutomationElement('link-to-searches-page')}>here.</Link.Anchor>
      </div>
    );
  }
  return null;
};

export default alertsCapsContent;
