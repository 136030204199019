import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import defaultTheme from '@nebula/theme';
import GlobalStyles from '@nebula/global-styles';
import Button from '@nebula/button';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import TickIcon from './assets/TickIcon';
import CrossIcon from './assets/CrossIcon';
import getCount from '../../actions/alerts/getCount';

import { ButtonWrapper, Container } from './index.styles';

const AlertsCustomerFeedback = ({ alertType }) => {
  const [selected, setSelected] = useState(null);

  const sendAnalyticsEvent = () => {
    if (!selected) return;

    const labelMapGA4 = {
      yes: 'helpful',
      no: 'not_helpful',
    };

    Analytics.publish(
      InteractionInputEvent.fromObject({
        component: `id_alerts_customer_feedback_${labelMapGA4[selected]}`,
        action: 'click',
        status: 'success',
        data: { alertType },
      })
    );
  };

  const updateSelectedState = buttonValue => {
    setSelected(buttonValue);
  };

  useEffect(() => {
    getCount();
    sendAnalyticsEvent();
  }, [selected]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <Container>
        <ButtonWrapper>
          <Button
            label="Yes"
            buttonKind="secondaryBlue"
            icon={<TickIcon />}
            disabled={selected === 'no'}
            onClick={() => updateSelectedState('yes')}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            label="No"
            buttonKind="secondaryBlue"
            icon={<CrossIcon />}
            disabled={selected === 'yes'}
            onClick={() => updateSelectedState('no')}
          />
        </ButtonWrapper>
      </Container>
    </ThemeProvider>
  );
};

AlertsCustomerFeedback.propTypes = {
  alertType: PropTypes.string,
};

AlertsCustomerFeedback.defaultProps = {
  alertType: '',
};

export default AlertsCustomerFeedback;
