import React from 'react';
import PropTypes from 'prop-types';

import { AlertContainer, AlertHeading, AlertMessage } from './index.styles';

const Error = ({ heading, message }) => (
  <AlertContainer>
    <AlertHeading>{heading}</AlertHeading>
    <AlertMessage>{message}</AlertMessage>
  </AlertContainer>
);

Error.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string.isRequired,
};

Error.defaultProps = {
  heading: '',
};

export default Error;
