import styled from 'styled-components';
import { rem } from 'polished';
import { BaseCard } from '@nebula/cards';
import CreditLockSVG from '../InstantAlertDetails/assets/creditLock.svg';

export const CreditLockDetailWrapper = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
`;

export const CreditLockIcon = styled.div`
  content: url(${CreditLockSVG});
`;

export const CreditLockDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 16px 0px;
  align-self: stretch;
`;

export const CreditLockContentBox = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin-top: ${rem(16)};
`;
