import fetch from '../fetch';

export const PROFILE_FETCH_FAILED = 'PROFILE_FETCH_FAILED';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const PROFILE_FETCHED = 'PROFILE_FETCHED';

export const endpoint = 'communicationprofile';

export default () => async (dispatch, getState) => {
  dispatch({ type: FETCH_PROFILE });

  try {
    const profile = await dispatch(fetch(`/${endpoint}`, {}, false, null, true));
    const { brand } = getState();

    return dispatch({
      type: PROFILE_FETCHED,
      payload: profile.data.filter(a => a.brand === brand),
    });
  } catch (e) {
    return dispatch({
      type: PROFILE_FETCH_FAILED,
      payload: new Error(e),
    });
  }
};
