import styled, { css } from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

export const Copy = styled(Typography.Copy.Text)`
  color: ${({ theme }) => theme.colors.baseDarkGrey};
  font-weight: 500;

  ${({ theme }) => theme.media('15', css`
    display: inline;
    margin-right: 1.25rem;
  `)}
`;

export const Container = styled.section`
  margin: 1.8125rem 0;
`;
