import React from 'react';
import PropTypes from 'prop-types';
import {
  Copy,
  SecuritySectionInner,
  SecuritySectionOuter,
} from './index.styles';

import RiskPanel from '../risk';

const SecurityPanel = ({ selected }) => (
  <SecuritySectionOuter>
    <SecuritySectionInner>
      <Copy>What does it mean for my security?</Copy>
      <RiskPanel selected={selected} />
    </SecuritySectionInner>
  </SecuritySectionOuter>
);

SecurityPanel.propTypes = {
  selected: PropTypes.bool,
};

SecurityPanel.defaultProps = {
  selected: null,
};

export default SecurityPanel;
