import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { hasBenefit } from '../reducers/subscriptions';

import { benefits, products, descriptors } from '../constants';
import getCreditLockData, { CREDITLOCK_NO_BENEFIT } from '../actions/creditLock/getCreditLockStatus';
import getScore, { SCORE_NO_BENEFIT } from '../actions/scores/getScore';
import getAllOffers from '../actions/offers/getAll';
import getAllAlerts from '../actions/alerts/getAll';
import getSubscription from '../actions/subscriptions/getSubscription';
import getCustomer from '../actions/auth/getCustomer';
import creditLockStatuses from '../constants/creditLock';

const useEcd2Data = (auth, subscriptions, scores, creditLockData, alerts, offers, shouldFetchAlerts) => {
  const dispatch = useDispatch();
  const [ecd2Data, setEcd2Data] = useState(null);

  const hasCreditLockBenefit = hasBenefit(subscriptions, [benefits.creditLock]);
  const hasScoreBenefit =
    hasBenefit(subscriptions, [benefits.monthlyCreditScore]) || hasBenefit(subscriptions, [benefits.dailyCreditScore]);

  useEffect(() => {
    if (!auth.authenticated) return;
    // Check subscriptions service has loaded
    if (subscriptions.current) {
      // Handle scores service
      if (!scores.isReady) {
        if (!hasScoreBenefit) {
          dispatch({ type: SCORE_NO_BENEFIT });
        } else if (!scores.isFetching) {
          dispatch(getScore());
        }
      }

      // Handle Credit Lock service
      if (!creditLockData.isReady) {
        if (!hasCreditLockBenefit) {
          dispatch({ type: CREDITLOCK_NO_BENEFIT });
        } else if (!creditLockData.isFetching) {
          dispatch(getCreditLockData());
        }
      }
    }

    if (!alerts.fetched && !alerts.fetching && shouldFetchAlerts) {
      dispatch(getAllAlerts());
    }
    if (!offers.isLoaded && !offers.fetching) {
      dispatch(getAllOffers());
    }
    if (!subscriptions.current && !subscriptions.fetching) {
      dispatch(getSubscription());
    }
    if (!auth.currentUser?.customerNumber && !auth.fetching) {
      dispatch(getCustomer());
    }

    if (subscriptions.error) {
      dispatch({ type: SCORE_NO_BENEFIT });
      dispatch({ type: CREDITLOCK_NO_BENEFIT });
    }
  }, [subscriptions, auth]);

  if (!auth.authenticated) {
    return {
      customer: {
        authenticationStatus: false,
      },
    };
  }

  if (!ecd2Data) {
    // exit if still fetching to services
    if (
      auth.fetching ||
      (!subscriptions.current && !subscriptions.error) ||
      !creditLockData.isReady ||
      !scores.isReady ||
      !offers.isLoaded ||
      (alerts && !alerts.fetched)
    ) {
      return null;
    }

    const eligibleForTrial = Object.values(offers?.data).some(
      offer => offer.family === products.credit_expert && offer.descriptors?.includes(descriptors.freeTrial)
    );

    const ecdLockStatusMap = {
      [creditLockStatuses.on]: 'Locked',
      [creditLockStatuses.off]: 'Unlocked',
      [creditLockStatuses.unknown]: 'Error',
    };

    setEcd2Data({
      customer: {
        authenticationStatus: true,
        customerNumber: auth.currentUser?.customerNumber,
        eligibleForTrial,
        previousScore: scores?.previousScoreValue ?? null,
        score: scores?.scoreValue ?? null,
        startDate: auth.currentUser?.createDt ? dayjs(auth.currentUser?.createDt).format('MM/DD/YYYY') : null,
        isoStartDate: auth.currentUser?.createDt ? dayjs(auth.currentUser?.createDt).toISOString() : null,
        subscriptionType: subscriptions.current ? subscriptions.current.family : null,
        boostUplift: scores?.boostUplift ?? null,
        lockStatus: ecdLockStatusMap[creditLockData.status],
        alertCount: alerts?.allAlerts?.length,
      },
    });
  }

  return ecd2Data;
};

export default useEcd2Data;
