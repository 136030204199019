const textMap = {
  blocked: 'Stopped Application',
  notBlocked: {
    H: 'Hard Search',
    S: 'Soft Search',
  },
};

function getAlertType(isBlocked, searchType) {
  const searchTypeUpperCase = typeof searchType === 'string' ? searchType.toUpperCase() : searchType;

  if ((typeof isBlocked !== 'boolean') && (searchTypeUpperCase !== 'H' && searchTypeUpperCase !== 'S')) {
    return null;
  }

  return isBlocked ? textMap.blocked : textMap.notBlocked[searchTypeUpperCase];
}

export default ({ isBlocked, searchType }) => getAlertType(isBlocked, searchType);
