import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import { Container, StyledHeading } from './index.styles';

const Well = ({
  automationKey,
  body,
  heading,
  iconKey,
}) => (
  <Container {...setAutomationElement(automationKey)}>
    {heading && <StyledHeading iconKey={iconKey}>{heading}</StyledHeading>}
    {body}
  </Container>
);

Well.propTypes = {
  automationKey: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  heading: PropTypes.string,
  iconKey: PropTypes.string,
};

Well.defaultProps = {
  automationKey: 'well',
  body: null,
  heading: null,
  iconKey: null,
};

export default Well;
