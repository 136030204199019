import Cookies from 'js-cookie';
import { getEnv } from '../../components/Context/env';

export const STORED_DATA_UPDATED = 'STORED_DATA_UPDATED';

export default (itemToStore = { options: {} }, dataStore = Cookies) => (dispatch) => {
  const env = getEnv();
  const { key, value, options } = itemToStore;
  dataStore.set(key, value, { ...options, secure: env.REACT_APP_ENV !== 'local' });

  return dispatch({
    type: STORED_DATA_UPDATED,
    payload: {
      [key]: value,
    },
  });
};
