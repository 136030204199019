import headings from './headings';
import { sections } from './sections';
import specialInstructionsMap from './specialInstructionsMap';
import typeStatuses from './typeStatuses';

export const addressType = {
  current: 'C',
  previous: 'P',
  supplemental: 'S',
};

export default {
  headings,
  sections,
  specialInstructionsMap,
  typeStatuses,
};
