/* istanbul ignore file */
import PropTypes from 'prop-types';
import { addressType } from '../constants/report/index';

const address = PropTypes.shape({
  houseNumber: PropTypes.string,
  postCode: PropTypes.string,
  street: PropTypes.string,
  town: PropTypes.string,
});

const name = PropTypes.shape({
  lastName: PropTypes.string,
  title: PropTypes.string,
  firstName: PropTypes.string,
  initials: PropTypes.string,
});

export default {
  account: {
    address,
    creditCardLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dob: PropTypes.string,
    fileUpdateForPeriod: PropTypes.string,
    isDisplayPaymentTerms: PropTypes.bool,
    name,
    nocRef: PropTypes.string,
    payment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    repaymentPeriod: PropTypes.string,
    specialInstructionEndDate: PropTypes.string,
    specialInstructionIndicator: PropTypes.string,
    specialInstructionStartDate: PropTypes.string,
  },
  creditSearch: {
    address,
    addressType: PropTypes.oneOf([addressType.current, addressType.previous, addressType.supplemental]),
    applicationType: PropTypes.string,
    dateOfBirth: PropTypes.string,
    entryNumber: PropTypes.string,
    hasDataDispute: PropTypes.bool,
    hasNoticeOfCorrection: PropTypes.bool,
    isIncludedInScoreCalculation: PropTypes.bool,
    name,
    nocRef: PropTypes.string,
    searchedBy: PropTypes.string,
    searchedOn: PropTypes.string,
  },
  electoralRoll: {
    address,
    entryNumber: PropTypes.string,
    nocRef: PropTypes.string,
    hasDataDispute: PropTypes.bool,
    hasNoticeOfCorrection: PropTypes.bool,
    isRegisteredOnVotersRoll: PropTypes.bool,
    registrationDate: PropTypes.string,
    registrationDetails: PropTypes.arrayOf(
      PropTypes.shape({
        registeredAs: PropTypes.shape({
          lastName: PropTypes.string,
          firstName: PropTypes.string,
          initials: PropTypes.string,
        }),
        registeredWith: PropTypes.string,
        registrationDuration: PropTypes.shape({
          0: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    ),
  },
  linkedAddresses: {
    address: PropTypes.shape({
      houseNumber: PropTypes.string,
      postCode: PropTypes.string,
      street: PropTypes.string,
      town: PropTypes.string,
      district: PropTypes.string,
    }),
    name,
    linkId: PropTypes.string,
    linkNumber: PropTypes.string,
    linkedTo: PropTypes.shape({
      houseNumber: PropTypes.string,
      postCode: PropTypes.string,
      street: PropTypes.string,
      town: PropTypes.string,
      county: PropTypes.string,
    }),
    dateOfLink: PropTypes.string,
    entryNumber: PropTypes.string,
    hasDataDispute: PropTypes.bool,
    hasNoticeOfCorrection: PropTypes.bool,
    note: PropTypes.string,
    relSegNumber: PropTypes.string,
    source: PropTypes.string,
  },
  noticeOfCorrection: {
    hasDataDispute: PropTypes.bool,
    nocRef: PropTypes.string,
    noticeText: PropTypes.string,
  },
};
