import fetch from '../fetch';
import creditLockEndpoint from './baseEndpoint';
import { creditLock } from '../../constants';

export const CREDITLOCK_STATUS_FETCHED = 'CREDITLOCK_DATA_FETCHED';
export const FETCH_CREDITLOCK_STATUS = 'FETCH_CREDITLOCK_STATUS';
export const CREDITLOCK_NO_BENEFIT = 'CREDITLOCK_NO_BENEFIT';

export const getSimplifiedCreditLockStatus = (responseStatus) => {
  const onResponses = ['ON', 'ON_AND_UPDATING', 'ON_PINNING_PROBLEM'];
  const offResponses = ['OFF', 'OFF_AND_UPDATING', 'OFF_PINNING_PROBLEM'];

  if (responseStatus && onResponses.includes(responseStatus)) {
    return creditLock.on;
  }
  if (responseStatus && offResponses.includes(responseStatus)) {
    return creditLock.off;
  }

  return creditLock.unknown;
};

export default () => async (dispatch) => {
  dispatch({
    type: FETCH_CREDITLOCK_STATUS,
  });

  try {
    const creditLockDataResponse = await dispatch(
      fetch(`${creditLockEndpoint}`,
        {},
        false,
        null,
        true),
    );

    const creditLockStatus = getSimplifiedCreditLockStatus(creditLockDataResponse.lockStatus);

    return dispatch({
      type: CREDITLOCK_STATUS_FETCHED,
      payload: {
        status: creditLockStatus,
      },
    });
  } catch (e) {
    // User is not enrolled in CreditLock benefit
    if (e && e.status === 404) {
      return dispatch({
        type: CREDITLOCK_STATUS_FETCHED,
        payload: {
          status: creditLock.unknown,
        },
      });
    }

    // Unknown error - show UNKNOWN status
    return dispatch({
      type: CREDITLOCK_STATUS_FETCHED,
      payload: {
        status: creditLock.unknown,
      },
    });
  }
};
