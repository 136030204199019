import { PRODUCT_DETAILS_FETCHED } from '../actions/product/getDetails';
import { getHomeUrl } from '../helpers';

const defaultState = {
  fetched: false,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case PRODUCT_DETAILS_FETCHED:
      return {
        ...state,
        ...action.payload,
        fetched: true,
        homepageUrl: getHomeUrl(action.payload.productIdentifier, action.payload.isFreeTrial),
      };
    default:
      return state;
  }
};
