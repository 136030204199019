import React from 'react';
import PropTypes from 'prop-types';

import {
  BenefitContainer,
  Heading,
  Paragraph,
} from './index.styles';

const Benefit = ({
  copy,
  iconKey,
  title,
}) => (
  <BenefitContainer iconKey={iconKey}>
    <Heading>{title}</Heading>
    {copy.map(paragraph => (
      <Paragraph key={paragraph}>
        {paragraph}
      </Paragraph>
    ))}
  </BenefitContainer>
);

Benefit.propTypes = {
  copy: PropTypes.arrayOf(PropTypes.string),
  iconKey: PropTypes.string,
  title: PropTypes.string,
};

Benefit.defaultProps = {
  copy: [],
  iconKey: '',
  title: '',
};

export default Benefit;
