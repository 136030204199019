import React from 'react';
import PropTypes from 'prop-types';
import LoaderImage from '../../../images/Loader';
import ErrorMessage from '../ErrorMessage';
import { getEnv } from '../../Context/env';

const Loader = ({
  error,
  pastDelay,
}) => {
  const env = getEnv();
  if (error) {
    if (env.REACT_APP_ENV !== 'prod') {
      return <ErrorMessage heading={error.message} message={<pre>{error.stack}</pre>} />;
    }

    return null;
  }

  if (pastDelay) {
    return <LoaderImage />;
  }

  return null;
};

Loader.propTypes = {
  error: PropTypes.instanceOf(Error),
  pastDelay: PropTypes.bool,
};

Loader.defaultProps = {
  error: null,
  pastDelay: true,
};

export default Loader;
