import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1.5625rem;
`;

export const Inner = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 auto;
  max-width: 1000px;
  padding: 1.25rem 0.9375rem;
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.media('20', `
    font-size: 1rem;
    padding: 2.5rem 0.9375rem;
  `)}
`;
