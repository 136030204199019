import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const FETCH_ALL_OFFERS = 'FETCH_ALL_OFFERS';
export const ALL_OFFERS_FETCHED = 'ALL_OFFERS_FETCHED';

export default () => async dispatch => {
  dispatch({ type: FETCH_ALL_OFFERS });

  const fetched = {
    type: ALL_OFFERS_FETCHED,
  };

  try {
    const offers = await dispatch(fetch('/offers'));

    fetched.payload = { offers: offers.data };
  } catch (error) {
    const errorMessage = {
      messages: [errorMessages.default],
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }

  return dispatch(fetched);
};
