import styled from 'styled-components';
import { Link } from '@experian-uk/corvetteuk-common-ui';
import chevron from './assets/chevron.svg';

export const DecorativeToggleButton = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.shadeLightGrey};
  border-radius: 0.625rem;
  height: 1.375rem;
  position: relative;
  width: 2.5rem;

  &:before {
    background-color: ${({ isToggled, theme }) => (isToggled ? theme.colors.experianPink : theme.colors.experianLightBlue)};
    background-image: url(${chevron});
    background-position: center;
    background-repeat: no-repeat;
    background-size: .875rem;
    border-radius: 50%;
    box-shadow: 0 0 1px 2px ${({ theme }) => theme.colors.shadeLightGrey};
    content: '';
    height: ${({ size }) => size};
    position: absolute;
    top: -2px;
    transition: all 0.05s ease-out;
    width: ${({ size }) => size};
    ${({ isToggled }) => (isToggled ? 'right: 0; -webkit-transform: rotate(180deg); transform: rotate(180deg);' : 'left: 0')};
  }
`;

export const InnerToggle = styled.div`
  align-self: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;

  ${({ theme }) => theme.media('15', `
    align-self: flex-end;
  `)}

  ${({ setRowLayout, theme }) => setRowLayout && `
    ${theme.media('20', `
      align-self: flex-start;
    `)};
  `};
`;

export const ToggleButton = styled(Link.Button)`
  &:focus, &:hover {
    color: ${({ theme }) => theme.colors.experianDarkBlue};
    text-decoration: none;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  ${({ setRowLayout, theme }) => setRowLayout && `
    ${theme.media('20', `
      flex-direction: row-reverse;
      justify-content: space-between;
    `)};
  `};
`;
