import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import {
  Copy,
  Container,
  Actions,
  SelectionButton,
} from './index.styles';


const ButtonPanel = ({ onChange }) => (
  <Container>
    <Copy>Do you recognise this password?</Copy>
    <Actions>
      <SelectionButton
        label=""
        onClick={onChange(false)}
        {...setAutomationElement('password-not-recognised')}
      >
        No
      </SelectionButton>
      <SelectionButton
        isSelected
        label=""
        onClick={onChange(true)}
        {...setAutomationElement('password-recognised')}
      >
        Yes
      </SelectionButton>
    </Actions>
  </Container>
);

ButtonPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ButtonPanel;
