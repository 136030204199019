import fetch from '../fetch';

export const FETCH_REPORT = 'FETCH_REPORT';
export const REPORT_FETCHED = 'REPORT_FETCHED';

export default reportId => async (dispatch) => {
  dispatch({ type: FETCH_REPORT, payload: reportId });

  try {
    const report = await dispatch(fetch(`/reports/${reportId}`));

    return dispatch({
      type: REPORT_FETCHED,
      error: false,
      payload: report,
    });
  } catch (e) {
    return dispatch({
      type: REPORT_FETCHED,
      error: true,
      payload: new Error('Error in fetch from report service.'),
    });
  }
};
