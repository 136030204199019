import React, { Fragment } from 'react';
import SecurityPanel from '../security';
import KeepSafePanel from '../keepSafe';

const NoPasswordPage = () => (
  <Fragment>
    <SecurityPanel selected={null} />
    <KeepSafePanel hasCredentials={false} />
  </Fragment>
);

export default NoPasswordPage;
