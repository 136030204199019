import { alertValues } from '../../../constants';
import overrideMap from './overrideMap';
import valueMap from './valueMap';
import sortAlerts from './sortAlerts';

const evaluateAlertValue = (alertValue, alertType) => {
  if (!alertValue) {
    return {};
  }
  try {
    return JSON.parse(alertValue);
  } catch (e) {
    if (valueMap[alertType]) {
      return {
        [valueMap[alertType]]: alertValue,
      };
    }
    return {};
  }
};

const getAlertType = ({ alertCode }, keyMap = overrideMap) => {
  if (alertCode) {
    return keyMap[alertCode] && keyMap[alertCode].type ? keyMap[alertCode].type : alertValues.types.credit;
  }
  return alertValues.types.fraud;
};

const getDescriptionKey = ({ alertType = '', alertCode = '' }, keyMap = overrideMap) => {
  if (keyMap[alertCode] && keyMap[alertCode].descriptionKey) {
    return keyMap[alertCode].descriptionKey;
  }
  return alertType.toLowerCase();
};

const isCreditAlert = type => type === alertValues.types.credit;

const shouldShowReportLink = ({ code, type }, keyMap = overrideMap) => {
  if (code && keyMap[code] && (typeof keyMap[code].shouldShowReportLink === 'boolean')) {
    return keyMap[code].shouldShowReportLink;
  }
  return isCreditAlert(type);
};

export default {
  evaluateAlertValue,
  getAlertType,
  getDescriptionKey,
  shouldShowReportLink,
  sortAlerts,
};
