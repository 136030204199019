import React from 'react';
import { NavLink } from 'react-router-dom';
import { Alerts, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import CPPContactLink from './CPPContactLink';

const customAlerts = (alerts = {}, currentPath = '', url = '/alerts') => ({
  component: (
    <NavLink to={url} {...setAutomationElement('headerAlerts')}>
      <Alerts
        active={(currentPath.search('alerts') !== -1)}
        error={alerts.error}
        key="headerAlerts"
        messageCount={alerts.unread}
      />
    </NavLink>
  ),
  key: 'alerts',
});

const customContact = (isCpp, baseUrl) => (isCpp ? ({
  component: <CPPContactLink key="cpp-contact" baseUrl={baseUrl} />,
  key: 'contact',
}) : undefined);

const getUtilsToSkip = (conditions) => {
  const utils = {
    faq: conditions.isCpp,
  };

  return Object.keys(utils).filter(key => utils[key]);
};

export default (isCpp, alerts, currentPath, env = {}, customAlertsFunction = customAlerts) => {
  const utils = [
    customContact(isCpp, env.REACT_APP_CPP_URL),
    customAlertsFunction(alerts, currentPath),
  ].filter(util => util);

  const utilsToSkip = getUtilsToSkip({ isCpp });

  return {
    customUtils: utils.map(util => util.component),
    skipUtils: utils.map(util => util.key).concat(utilsToSkip),
  };
};
